import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ProgressBarService } from '@qaroni-core/services/app/progress-bar/progress-bar.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { ForgotPasswordDialogFormClass } from './forgot-password-dialog-form.class';

@Component({
  selector: 'qaroni-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss'],
})
export class ForgotPasswordDialogComponent
  extends ForgotPasswordDialogFormClass
  implements OnInit, OnDestroy
{
  public submitting: boolean = false;

  private subs: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private route: ActivatedRoute,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    public progressBar: ProgressBarService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.add(this.route.queryParamMap.subscribe(this.getQueryParamMap));
    this.subs.add(
      this.recaptchaV3Service.execute('login').subscribe(this.getToken)
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public onCancelClick(): void {
    this.dialogRef.close(false);
  }

  get email(): AbstractControl {
    return this.forgotPasswordForm.get('email');
  }

  get captcha(): AbstractControl {
    return this.forgotPasswordForm.get('captcha');
  }

  get validatedForm(): boolean {
    return this.dirtyValidForm && !this.submitting;
  }

  private getQueryParamMap = (queryParamMap: ParamMap): void => {
    if (queryParamMap.has('email')) {
      this.forgotPasswordForm.patchValue({ email: queryParamMap.get('email') });
    } else {
      this.router.navigate([`/auth/login`]);
    }
  };

  private getToken = (token: string): void => {
    if (token) {
      this.captcha.setValue(token);
    } else {
      this.captcha.setValue(null);
    }
  };

  public onSubmit(): void {
    if (this.validatedForm) {
      this.dialogRef.close({
        email: this.data,
        captcha: this.captcha.value,
      });
    }
  }
}
