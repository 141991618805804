import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProjectTaskStatusEnum } from '@qaroni-app/projects/types/project-task-status.enum';

@Component({
  selector: 'qaroni-task-preview-status',
  templateUrl: './task-preview-status.component.html',
  styleUrl: './task-preview-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskPreviewStatusComponent {
  @Input() status: ProjectTaskStatusEnum = ProjectTaskStatusEnum.PENDING;

  get bgColorClass(): string {
    switch (this.status) {
      case ProjectTaskStatusEnum.PENDING:
        return 'qaroni-bg-accent70';
      case ProjectTaskStatusEnum.COMPLETED:
        return 'qaroni-bg-primary60';
      default:
        return 'qaroni-bg-accent70';
    }
  }
}
