import { Pipe, PipeTransform } from '@angular/core';
import {
  EmployeeAbsenceTimeEnum,
  EmployeeAbsenceTimeInfo,
} from '@qaroni-app/employee/types/employee-absence-time.enum';

@Pipe({
  name: 'employeeAbsenceTime',
})
export class EmployeeAbsenceTimePipe implements PipeTransform {
  transform(value: EmployeeAbsenceTimeEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(EmployeeAbsenceTimeInfo, value)
    ) {
      return EmployeeAbsenceTimeInfo[value].name;
    }

    return value;
  }
}
