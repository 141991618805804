import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timezoneFilter',
})
export class TimezoneFilterPipe implements PipeTransform {
  transform(timezones: string[], timezone: string): string[] {
    if (!timezone) return timezones;

    return timezones?.filter(t =>
      t.toLowerCase().includes(timezone.toLowerCase())
    );
  }
}
