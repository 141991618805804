import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbsenceEmployeeStatusEnum } from '@qaroni-app/absences/types/absence-employee-status.enum';

@Component({
  selector: 'qaroni-absence-employee-status-preview',
  templateUrl: './absence-employee-status-preview.component.html',
  styleUrls: ['./absence-employee-status-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbsenceEmployeeStatusPreviewComponent {
  @Input() status = AbsenceEmployeeStatusEnum.PENDING;

  get bgColorClass(): string {
    switch (this.status) {
      case AbsenceEmployeeStatusEnum.APPROVED:
        return 'qaroni-bg-primary50';
      case AbsenceEmployeeStatusEnum.REJECTED:
        return 'qaroni-bg-danger-light';
      case AbsenceEmployeeStatusEnum.PENDING:
        return 'qaroni-bg-success';
      default:
        return 'qaroni-bg-success';
    }
  }
}
