import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  QaroniGaugeLabelsPlugin,
  QaroniGaugeNeedlePlugin,
} from '@qaroni-shared/qaroni-plugins-charts/qaroni-gauge-chart-plugin';
import { Chart, ChartOptions, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'qaroni-assessment-gauge-chart-preview',
  templateUrl: './assessment-gauge-chart-preview.component.html',
  styleUrls: ['./assessment-gauge-chart-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentGaugeChartPreviewComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild('gaugeChart') gaugeChart: ElementRef<HTMLCanvasElement>;
  @Input() needleValue: number;
  private chart: Chart<'doughnut'>;
  private gaugeChartCanvas: HTMLCanvasElement;

  constructor() {
    Chart.register(ChartDataLabels);
    Chart.register(...registerables);
  }

  ngAfterViewInit() {
    this.gaugeChartCanvas = this.gaugeChart.nativeElement;
    this.gaugeChartSetup();
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  private gaugeChartSetup() {
    if (this.chart) {
      this.chart.destroy();
    }

    const gaugeOptions: ChartOptions<'doughnut'> = {
      cutout: '80%',
      responsive: true,
      aspectRatio: 1.8,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          textAlign: 'center',
          color: '#000000',
          font: {
            size: 12,
          },
          formatter: (val, ctx) => ctx.chart.data.labels[ctx.dataIndex],
        },
      },
      layout: {
        autoPadding: true,
        padding: {
          top: 50,
          right: 50,
          bottom: 50,
          left: 50,
        },
      },
    };

    this.chart = new Chart(this.gaugeChartCanvas, {
      type: 'doughnut',
      data: {
        labels: ['50%', '80%', ''],
        datasets: [
          {
            data: [50, 30, 20],
            backgroundColor: ['#DF6F6F', '#FFBA66', '#5CB3F0'],
            borderColor: 'transparent',
            hoverOffset: 4,
            hidden: false,
            circumference: 180,
            rotation: 270,
          },
        ],
      },
      options: gaugeOptions,
      plugins: [
        new QaroniGaugeNeedlePlugin(this.needleValue),
        new QaroniGaugeLabelsPlugin(),
      ],
    });
  }
}
