import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum ProjectTaskStatusEnum {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export type ProjectTaskStatusType =
  | ProjectTaskStatusEnum.PENDING
  | ProjectTaskStatusEnum.COMPLETED;

export const ProjectTaskStatusArray = [
  ProjectTaskStatusEnum.PENDING,
  ProjectTaskStatusEnum.COMPLETED,
];

export const ProjectTaskStatusInfo: QaroniEnumInformation<ProjectTaskStatusEnum> =
  {
    [ProjectTaskStatusEnum.PENDING]: {
      name: $localize`Pendiente`,
    },
    [ProjectTaskStatusEnum.COMPLETED]: {
      name: $localize`Completado`,
    },
  };
