import { Pipe, PipeTransform } from '@angular/core';
import {
  UserRoleEnum,
  UserRoleInfo,
} from '@qaroni-app/workers/types/user-role.enum';

@Pipe({
  name: 'userRole',
})
export class UserRolePipe implements PipeTransform {
  transform(value: UserRoleEnum): string {
    if (value && Object.prototype.hasOwnProperty.call(UserRoleInfo, value)) {
      return UserRoleInfo[value].name;
    }

    return value;
  }
}
