import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { TagColorEnum } from '@qaroni-app/tags/types/tag-color.enum';

@Component({
  selector: 'qaroni-projects-tasks-tags',
  templateUrl: './projects-tasks-tags.component.html',
  styleUrl: './projects-tasks-tags.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsTasksTagsComponent {
  @Output() tagClick = new EventEmitter<string>();
  @Input({ required: true }) tagName = '';
  @Input({ required: true }) tagColor = '';
  @Input() redirectUrl = '';

  private router = inject(Router);

  get hasTagColor(): boolean {
    return this.tagColor !== '';
  }

  get canRedirect(): boolean {
    return this.redirectUrl !== '';
  }

  get defaultTagColor(): string {
    return this.hasTagColor ? this.tagColor : '#E5E5E5';
  }

  get defaultTagTextColor(): string {
    return this.hasTagColor ? '#FFFFFF' : '#000000';
  }

  get routerLinkUrl(): string | null {
    return this.canRedirect ? this.redirectUrl : null;
  }

  get ngClassRingColors() {
    return {
      'ring-green-800/10': this.tagColor === TagColorEnum.GREEN,
      'ring-orange-800/10': this.tagColor === TagColorEnum.ORANGE,
      'ring-red-800/10': this.tagColor === TagColorEnum.RED,
      'ring-blue-800/10': this.tagColor === TagColorEnum.BLUE,
      'ring-brown-800/10': this.tagColor === TagColorEnum.BROWN,
      'ring-gray-800/10': !this.tagColor,
    };
  }

  public onTagClick(): void {
    this.tagClick.emit(this.tagName);
  }
}
