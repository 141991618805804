import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiQWorkardsEnv } from '@qaroni-core/config/apis/api-qworkards/api-qworkards.config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkersHttpService {
  constructor(private http: HttpClient) {}

  public getUserRole$(
    companyID: number,
    userID: number
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/users/${userID}`;

    return this.http.get(url, { observe: 'response' });
  }
}
