<div
  class="grid items-center"
  [ngStyle]="{
    'grid-template-columns': showLineColor && color ? 'max-content 1fr' : '1fr',
  }">
  <ng-container *ngIf="showLineColor && color">
    <div
      class="project-line me-2"
      [style]="{ 'background-color': color }"></div>
  </ng-container>
  <div
    class="my-2 grid items-center"
    [style]="{ color: color }"
    [ngStyle]="{ 'grid-template-columns': icon ? 'max-content 1fr' : '1fr' }">
    <mat-icon class="me-1" *ngIf="icon">{{ icon }}</mat-icon> {{ name }}
  </div>
</div>
