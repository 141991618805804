import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

export class ForgotPasswordDialogFormClass {
  private fb: UntypedFormBuilder = new UntypedFormBuilder();

  private forgotPasswordSkeleton = {
    email: ['', [Validators.required, Validators.email]],
    captcha: ['', Validators.required],
  };

  public forgotPasswordForm: UntypedFormGroup = this.fb.group(
    this.forgotPasswordSkeleton
  );

  get email(): AbstractControl {
    return this.forgotPasswordForm.get('email');
  }

  get captcha(): AbstractControl {
    return this.forgotPasswordForm.get('captcha');
  }

  get dirtyValidForm(): boolean {
    return !this.forgotPasswordForm.dirty && this.forgotPasswordForm.valid;
  }
}
