export abstract class QaroniCache<T = any> {
  protected element: T;

  protected flying: boolean;

  abstract canReturn(...attributes: any): boolean;

  abstract canApi(...attributes: any): boolean;

  public startToFly(): void {
    this.flying = true;
  }

  public endFlying(): void {
    this.flying = false;
  }

  public get(): T {
    return { ...this.element };
  }

  public set(element: T): void {
    delete this.element;
    this.element = { ...element };
  }

  public empty(): void {
    delete this.element;
  }
}
