import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { qaroniEnterFadeIn } from './enter-fade-in';
import {
  marginBottomBigSmallConfig,
  marginBottomSmallBigConfig,
} from './margin-bottom-animation';
import {
  qaroniEnterFadeInConfig,
  qaroniLeaveFadeOutConfig,
} from './toggle-fade-animation';
import {
  qaroniToggleHeightEnterConfig,
  qaroniToggleHeightLeaveConfig,
} from './toggle-height-animation';
import {
  qaroniToggleWidthEnterConfig,
  qaroniToggleWidthLeaveConfig,
} from './toggle-width-animation';

export const qaroniAnimations = {
  enterFadeIn: (time: string = '200ms'): AnimationTriggerMetadata =>
    trigger('enterFadeIn', [
      transition(':enter', [
        useAnimation(qaroniEnterFadeIn, { params: { time } }),
      ]),
    ]),

  toggleFade: (time: string = '200ms'): AnimationTriggerMetadata =>
    trigger('toggleFade', [
      transition(':enter', [
        useAnimation(qaroniEnterFadeInConfig, { params: { time } }),
      ]),
      transition(':leave', [
        useAnimation(qaroniLeaveFadeOutConfig, { params: { time } }),
      ]),
    ]),

  toggleHeight: (time: string = '350ms'): AnimationTriggerMetadata =>
    trigger('toggleHeight', [
      transition(':enter', [
        useAnimation(qaroniToggleHeightEnterConfig, { params: { time } }),
      ]),
      transition(':leave', [
        useAnimation(qaroniToggleHeightLeaveConfig, { params: { time } }),
      ]),
    ]),

  toggleWidth: (time: string = '350ms'): AnimationTriggerMetadata =>
    trigger('toggleWidth', [
      transition(':enter', [
        useAnimation(qaroniToggleWidthEnterConfig, { params: { time } }),
      ]),
      transition(':leave', [
        useAnimation(qaroniToggleWidthLeaveConfig, { params: { time } }),
      ]),
    ]),

  marginBottom: (
    from: string,
    to: string,
    name: string = '',
    time: string = '350ms'
  ): AnimationTriggerMetadata =>
    trigger('marginBottom' + name, [
      transition('small => big', [
        useAnimation(marginBottomSmallBigConfig, {
          params: { from, to, time },
        }),
      ]),
      transition('big => small', [
        useAnimation(marginBottomBigSmallConfig, {
          params: { from, to, time },
        }),
      ]),
    ]),

  detailExpandRow: (): AnimationTriggerMetadata =>
    trigger('detailExpandRow', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
};
