import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum EmployeeDisableTypeEnum {
  NONE = 'NONE',
  VOLUNTARY = 'VOLUNTARY',
  FIRED = 'FIRED',
}

export type EmployeeDisableTypeType =
  | EmployeeDisableTypeEnum.NONE
  | EmployeeDisableTypeEnum.VOLUNTARY
  | EmployeeDisableTypeEnum.FIRED;

export const EmployeeDisableTypeArray = [
  EmployeeDisableTypeEnum.NONE,
  EmployeeDisableTypeEnum.VOLUNTARY,
  EmployeeDisableTypeEnum.FIRED,
];

export const EmployeeDisableTypeInfo: QaroniEnumInformation<EmployeeDisableTypeEnum> =
  {
    [EmployeeDisableTypeEnum.NONE]: {
      name: '',
    },
    [EmployeeDisableTypeEnum.VOLUNTARY]: {
      name: $localize`Voluntario`,
    },
    [EmployeeDisableTypeEnum.FIRED]: {
      name: $localize`Despedido`,
    },
  };
