import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { SchemeImputationDate } from '@qaroni-app/scheme/types/scheme-imputation-date';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'qaroni-imputation-hours-chart-preview',
  templateUrl: './imputation-hours-chart-preview.component.html',
  styleUrls: ['./imputation-hours-chart-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImputationHoursChartPreviewComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  @Input() imputationByDate: SchemeImputationDate[];
  @Input() chartColor: string;

  @ViewChild('hoursBalanceChart')
  hoursBalanceChart: ElementRef<HTMLCanvasElement>;

  private chart: Chart;
  private canvas: HTMLCanvasElement;
  private labels: string[];

  public hours: number[];

  constructor() {
    Chart.register(...registerables);
  }

  ngOnChanges(): void {
    if (!this.imputationByDate?.length) return;

    this.initialize();
    if (this.chart) this.setUpChart();
  }

  ngAfterViewInit(): void {
    if (!this.imputationByDate?.length) return;

    this.canvas = this.hoursBalanceChart.nativeElement;
    this.setUpChart();
  }

  ngOnDestroy(): void {
    if (!this.imputationByDate?.length) return;

    this.chart.destroy();
  }

  private initialize = (): void => {
    this.labels = this.imputationByDate.map(imputation => imputation.date);
    this.hours = this.imputationByDate.map(imputation =>
      parseFloat((imputation.totalMinutes / 60).toFixed(2))
    );
  };

  private setUpChart = (): void => {
    if (this.chart) this.chart.destroy();

    this.chart = new Chart(this.canvas, {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: [
          {
            label: $localize`Horas imputadas`,
            data: this.hours,
            borderColor: this.chartColor ?? '#82c4f8',
            pointBorderColor: this.chartColor ?? '#82c4f8',
            pointBackgroundColor: this.chartColor ?? '#82c4f8',
          },
        ],
      },
      options: {
        responsive: true,
        layout: {
          padding: {
            top: 10,
            bottom: 10,
          },
        },
        plugins: {
          legend: {
            position: 'top',
            display: false,
          },
          tooltip: {
            titleFont: {
              size: 10,
            },
            bodyFont: {
              size: 10,
            },
          },
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            stacked: false,
            display: false,
          },
          y: {
            stacked: false,
            display: false,
          },
        },
      },
    });
  };
}
