import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Workplace } from '@qaroni-app/workplace/types/workplace';
import { GoogleMapsStyles } from '@qaroni-core/types/google-maps/google-maps-styles';

@Component({
  selector: 'qaroni-workplaces-map',
  templateUrl: './workplaces-map.component.html',
  styleUrls: ['./workplaces-map.component.scss'],
})
export class WorkplacesMapComponent
  implements OnChanges, OnInit, AfterViewInit
{
  @Input() workplacesList: Workplace[];
  @Input() height: string;

  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;

  public center: google.maps.LatLngLiteral = {
    lat: 20.2768072,
    lng: -31.9451262,
  };

  width = '100%';

  public markerOptions: google.maps.MarkerOptions = { draggable: false };
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  public infoContent = '';

  public zoom = 2;

  public mapOptions: google.maps.MapOptions = {
    minZoom: 2,
    maxZoom: 17,
    styles: GoogleMapsStyles as google.maps.MapTypeStyle[],
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
  };

  private bounds: google.maps.LatLngBounds;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.workplacesList.firstChange) {
      this.bounds = new google.maps.LatLngBounds();
      this.fillMarkers();
      this.fit();
    }
  }

  ngOnInit(): void {
    this.bounds = new google.maps.LatLngBounds();
    this.fillMarkers();
  }

  ngAfterViewInit(): void {
    this.fit();
  }

  public hasLatLng(workplace: Workplace): boolean {
    return workplace &&
      workplace.address &&
      workplace.address.latitude &&
      workplace.address.longitude
      ? true
      : false;
  }

  public getLatLng(workplace: Workplace): google.maps.LatLngLiteral {
    if (this.hasLatLng(workplace)) {
      const latLng: google.maps.LatLngLiteral = {
        lat: +workplace.address.latitude,
        lng: +workplace.address.longitude,
      };
      return latLng;
    }
    return null;
  }

  public openInfoWindow(workplace: Workplace, marker: MapMarker): void {
    this.infoContent = workplace.name;
    this.infoWindow.open(marker);
  }

  private fit(): void {
    if (this.map && !this.bounds.isEmpty()) {
      this.map.fitBounds(this.bounds);
    }
  }

  private fillMarkers(): void {
    if (!this.workplacesList?.length) {
      return;
    }

    this.workplacesList?.forEach(workplace => {
      if (this.hasLatLng(workplace)) {
        const latLng: google.maps.LatLngLiteral = {
          lat: parseFloat(workplace?.address?.latitude),
          lng: parseFloat(workplace?.address?.longitude),
        };

        this.bounds.extend(latLng);
      }
    });
  }
}
