import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum EmployeeAbsenceStatusEnum {
  ACTIVE = 'ACTIVE',
  APPROVED = 'APPROVED',
  CREATED = 'CREATED',
  INACTIVE = 'INACTIVE',
}

export type EmployeeAbsenceStatusType =
  | EmployeeAbsenceStatusEnum.ACTIVE
  | EmployeeAbsenceStatusEnum.APPROVED
  | EmployeeAbsenceStatusEnum.CREATED
  | EmployeeAbsenceStatusEnum.INACTIVE;

export const EmployeeAbsenceStatusArray = [
  EmployeeAbsenceStatusEnum.ACTIVE,
  EmployeeAbsenceStatusEnum.APPROVED,
  EmployeeAbsenceStatusEnum.CREATED,
  EmployeeAbsenceStatusEnum.INACTIVE,
];

export const EmployeeAbsenceStatusInfo: QaroniEnumInformation<EmployeeAbsenceStatusEnum> =
  {
    [EmployeeAbsenceStatusEnum.ACTIVE]: {
      name: $localize`Activo`,
    },
    [EmployeeAbsenceStatusEnum.APPROVED]: {
      name: $localize`Aprobado`,
    },
    [EmployeeAbsenceStatusEnum.CREATED]: {
      name: $localize`Creado`,
    },
    [EmployeeAbsenceStatusEnum.INACTIVE]: {
      name: $localize`Inactivo`,
    },
  };
