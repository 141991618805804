export enum TagColorEnum {
  GREEN = '#66a257',
  ORANGE = '#ffa067',
  RED = '#ff5f71',
  BLUE = '#0c7dd6',
  BROWN = '#a18262',
}

export type TagColorType =
  | TagColorEnum.GREEN
  | TagColorEnum.ORANGE
  | TagColorEnum.RED
  | TagColorEnum.BLUE
  | TagColorEnum.BROWN;

export const TagColorArray = [
  TagColorEnum.GREEN,
  TagColorEnum.ORANGE,
  TagColorEnum.RED,
  TagColorEnum.BLUE,
  TagColorEnum.BROWN,
];
