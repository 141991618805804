import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ParamMap } from '@angular/router';

export class RangeDatepickerForm {
  private fb: UntypedFormBuilder = new UntypedFormBuilder();

  private dateRangeSkeleton = {
    dateFrom: [{ value: '', disabled: true }, Validators.required],
    dateTo: [{ value: '', disabled: true }, Validators.required],
  };

  public dateRangeForm: UntypedFormGroup = this.fb.group(
    this.dateRangeSkeleton
  );

  get dateFrom(): AbstractControl {
    return this.dateRangeForm.get('dateFrom');
  }

  get dateTo(): AbstractControl {
    return this.dateRangeForm.get('dateTo');
  }

  get hasDates(): boolean {
    return this.dateFrom.value || this.dateTo.value;
  }

  get dirtyValidForm(): boolean {
    return this.dateRangeForm.dirty && this.dateRangeForm.valid;
  }

  get dateFromValue(): string {
    return this.dateFrom.value.trim();
  }

  set dateFromValue(value: string) {
    value = value.trim();
    if (value && typeof value === 'string') {
      this.dateFrom.setValue(value);
    } else {
      this.dateFrom.setValue('');
    }
  }

  get dateToValue(): string {
    return this.dateTo.value.trim();
  }

  set dateToValue(value: string) {
    value = value.trim();
    if (value && typeof value === 'string') {
      this.dateTo.setValue(value);
    } else {
      this.dateTo.setValue('');
    }
  }

  public resetSearchByDates(): void {
    this.dateFromValue = '';
    this.dateToValue = '';
    this.dateRangeForm.reset();
  }

  public getQueryParamMap = (queryParamMap: ParamMap): void => {
    if (queryParamMap.has('dateFrom') && queryParamMap.has('dateTo')) {
      this.dateFromValue = queryParamMap.get('dateFrom');
      this.dateToValue = queryParamMap.get('dateTo');
    } else {
      this.resetSearchByDates();
    }
  };
}
