import { Pipe, PipeTransform } from '@angular/core';
import { hoursToMinutes, intervalToDuration } from 'date-fns';

@Pipe({
  name: 'totalHoursSingle',
})
export class TotalHoursSinglePipe implements PipeTransform {
  transform(formGroup): number {
    const startDate = formGroup?.startDate ?? formGroup?.startTime;
    const endDate = formGroup?.endDate ?? formGroup?.endTime;

    if (!startDate || !endDate) return 0;

    const startDateHour: number = parseInt(startDate.split(':')[0]);
    const startDateMinutes: number = parseInt(startDate.split(':')[1]);

    const endDateHour: number = parseInt(endDate.split(':')[0]);
    const endDateMinutes: number = parseInt(endDate.split(':')[1]);

    const duration: Duration = intervalToDuration({
      start: new Date(1997, 6, 15, startDateHour, startDateMinutes, 0),
      end: new Date(1997, 6, 15, endDateHour, endDateMinutes, 0),
    });

    return hoursToMinutes(duration.hours) + duration.minutes;
  }
}
