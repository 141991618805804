import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { QaroniCustomCanvasColorPlugin } from '@qaroni-shared/qaroni-plugins-charts/qaroni-custom-canva-color-chart-plugin';
import { QaroniRequiredProgressBarPlugin } from '@qaroni-shared/qaroni-plugins-charts/qaroni-required-progress-bar-chart-plugin';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'qaroni-assessment-progress-bar-preview',
  templateUrl: './assessment-progress-bar-preview.component.html',
  styleUrls: ['./assessment-progress-bar-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentProgressBarPreviewComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild('ProgressBar') progressBarChart: ElementRef<HTMLCanvasElement>;

  @Input() progressObtained: number = 0;
  @Input() progressRequired: number = 0;
  @Input() progressBarColor: 'blue' | 'red' = 'blue';

  private chart: Chart<'bar'>;
  private progressBarCanvas: HTMLCanvasElement;
  private colors = new Map<string, string>();

  constructor() {
    this.colors.set('blue', '#5CB3F0');
    this.colors.set('red', '#DF6F6F');

    Chart.register(ChartDataLabels);
    Chart.register(...registerables);
  }

  ngAfterViewInit() {
    this.progressBarCanvas = this.progressBarChart.nativeElement;
    this.chartProgressBarSetup();
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  private chartProgressBarSetup() {
    if (this.chart) {
      this.chart.destroy();
    }

    const chartOptions: ChartOptions<'bar'> = {
      responsive: true,
      indexAxis: 'y',
      scales: {
        x: {
          display: false,
          beginAtZero: true,
          max: 100,
        },
        y: {
          display: false,
          grid: {
            display: false,
            //drawBorder: false,
          },
          ticks: {
            display: false,
          },
        },
      },
      plugins: {
        datalabels: {
          formatter: (value: number) => {
            return '';
          },
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    };

    const chartData: ChartData<'bar'> = {
      labels: ['Progress'],
      datasets: [
        {
          label: 'Progress Obtained',
          data: [this.progressObtained],
          backgroundColor: this.colors.get(this.progressBarColor),
          borderRadius: 2,
          barPercentage: 0.5,
        },
      ],
    };

    this.chart = new Chart(this.progressBarCanvas, {
      type: 'bar',
      data: chartData,
      options: chartOptions,
      plugins: [
        new QaroniRequiredProgressBarPlugin(this.progressRequired),
        new QaroniCustomCanvasColorPlugin(),
      ],
    });
  }
}
