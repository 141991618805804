import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfileSnackbars } from '../types/profile-snackbars.config';

@Injectable({
  providedIn: 'root',
})
export class ProfileSnackbarsService {
  constructor(private snackbar: MatSnackBar) {}

  public successUpdatedProfile(): void {
    this.snackbar.open(
      ProfileSnackbars.successUpdatedProfile.message,
      ProfileSnackbars.successUpdatedProfile.closeBtn,
      ProfileSnackbars.successUpdatedProfile.config
    );
  }

  public successAddressUpdated(): void {
    this.snackbar.open(
      ProfileSnackbars.successAddressUpdated.message,
      ProfileSnackbars.successAddressUpdated.closeBtn,
      ProfileSnackbars.successAddressUpdated.config
    );
  }

  public successChangedPassword(): void {
    this.snackbar.open(
      ProfileSnackbars.successChangedPassword.message,
      ProfileSnackbars.successChangedPassword.closeBtn,
      ProfileSnackbars.successChangedPassword.config
    );
  }

  public successCopiedPin(): void {
    this.snackbar.open(
      ProfileSnackbars.successCopiedPin.message,
      ProfileSnackbars.successCopiedPin.closeBtn,
      ProfileSnackbars.successCopiedPin.config
    );
  }

  public failureValidationError(): void {
    this.snackbar.open(
      ProfileSnackbars.failureValidationError.message,
      ProfileSnackbars.failureValidationError.closeBtn,
      ProfileSnackbars.failureValidationError.config
    );
  }

  public failurePasswordDoesNotHaveTheCorrectPattern(): void {
    this.snackbar.open(
      ProfileSnackbars.failurePasswordDoesNotHaveTheCorrectPattern.message,
      ProfileSnackbars.failurePasswordDoesNotHaveTheCorrectPattern.closeBtn,
      ProfileSnackbars.failurePasswordDoesNotHaveTheCorrectPattern.config
    );
  }
}
