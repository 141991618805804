import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { format, isValid } from 'date-fns';
import { Subscription } from 'rxjs';
import { RangeDatepickerForm } from './range-datepicker-form';

@Component({
  selector: 'qaroni-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeDatepickerComponent
  extends RangeDatepickerForm
  implements OnInit, OnDestroy
{
  @Input() year: string;

  @Output() queryParamsChange: EventEmitter<Params> =
    new EventEmitter<Params>();

  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

  private subs: Subscription = new Subscription();

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.subs.add(this.route.queryParamMap.subscribe(this.getQueryParamMap));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get minDate(): Date {
    return new Date(+this.year, 0, 1);
  }

  get maxDate(): Date {
    return new Date(+this.year, 11, 31);
  }

  public closeDatepicker(): void {
    const queryParamsEmit: Params = { ...this.route.snapshot.queryParams };
    if (isValid(this.dateFrom?.value) && isValid(this.dateTo?.value)) {
      queryParamsEmit.dateFrom = format(this.dateFrom.value, 'yyyy-MM-dd');
      queryParamsEmit.dateTo = format(this.dateTo.value, 'yyyy-MM-dd');
      this.queryParamsChange.emit(queryParamsEmit);
    } else {
      delete queryParamsEmit.dateFrom;
      delete queryParamsEmit.dateTo;
    }
    this.queryParamsChange.emit(queryParamsEmit);
  }

  public onClear(): void {
    this.resetSearchByDates();
    this.formDirective.resetForm();

    const queryParams: Params = { ...this.route.snapshot.queryParams };
    delete queryParams.dateFrom;
    delete queryParams.dateTo;

    this.queryParamsChange.emit(queryParams);
  }
}
