import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Employee } from '@qaroni-app/employee/types/employee';
import { ImagesConfig } from '@qaroni-core/config/app/images.config';

@Component({
  selector: 'qaroni-shift-status-preview',
  templateUrl: './shift-status-preview.component.html',
  styleUrls: ['./shift-status-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftStatusPreviewComponent {
  @Input() employee: Employee;

  public ImagesConfig = ImagesConfig;
}
