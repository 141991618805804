import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CityFilterPipe } from './pipes/city-filter.pipe';
import { CountryFilterPipe } from './pipes/country-filter.pipe';
import { CountryNamePipe } from './pipes/country-name.pipe';
import { StateFilterPipe } from './pipes/state-filter.pipe';

const pipes: any[] = [
  CountryFilterPipe,
  CountryNamePipe,
  StateFilterPipe,
  CityFilterPipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: [pipes],
  exports: [pipes],
})
export class CountriesModule {}
