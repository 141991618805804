import { Pipe, PipeTransform } from '@angular/core';
import {
  EmployeeDisabilityDegreeEnum,
  EmployeeDisabilityDegreeInfo,
} from '@qaroni-app/employee/types/employee-disability.enum';

@Pipe({
  name: 'employeeDisabilityDegree',
})
export class EmployeeDisabilityDegreePipe implements PipeTransform {
  transform(value: EmployeeDisabilityDegreeEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(EmployeeDisabilityDegreeInfo, value)
    ) {
      return EmployeeDisabilityDegreeInfo[value].name;
    }

    return value;
  }
}
