import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorsHttpService } from '@qaroni-core/services/commons/errors-http/errors-http.service';

@Component({
  selector: 'qaroni-errors-http-dialog',
  templateUrl: './errors-http-dialog.component.html',
  styleUrls: ['./errors-http-dialog.component.scss'],
})
export class ErrorsHttpDialogComponent {
  public showErrorDetails: boolean = false;

  constructor(
    private errorsHttp: ErrorsHttpService,
    public dialogRef: MatDialogRef<ErrorsHttpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public error: HttpErrorResponse
  ) {}

  public onShowErrorDetails(): void {
    this.showErrorDetails = true;
  }

  public onCancelClick(): void {
    this.dialogRef.close(false);
  }

  get isControlledError(): boolean {
    return this.errorsHttp.isControlledError(this.error);
  }
}
