<mat-card
  @enterFadeIn
  class="qaroni-card-bg-background mat-elevation-z0 h-full pb-2"
  *ngIf="employeeAddress">
  <mat-card-content class="flex w-full flex-col">
    <div class="mt-1 w-full">
      <span class="qaroni-detail-title" i18n>Dirección</span>
    </div>

    <mat-divider inset class="my-3"></mat-divider>

    <div class="flex w-full items-center justify-between gap-2">
      <ng-container *ngIf="employeeAddress?.line1">
        <qaroni-label-text-preview
          i18n-label
          label="Dirección"
          [value]="employeeAddress?.line1"></qaroni-label-text-preview>
      </ng-container>
      <ng-container *ngIf="employeeAddress?.line2">
        <qaroni-label-text-preview
          i18n-label
          label="Dirección (opcional)"
          [value]="employeeAddress?.line2"></qaroni-label-text-preview>
      </ng-container>
    </div>

    <div class="flex w-full items-center justify-between gap-2">
      <ng-container *ngIf="employeeAddress?.postalCode">
        <qaroni-label-text-preview
          i18n-label
          label="Código postal"
          [value]="employeeAddress?.postalCode"></qaroni-label-text-preview>
      </ng-container>
      <ng-container *ngIf="employeeAddress?.city">
        <qaroni-label-text-preview
          i18n-label
          label="Ciudad"
          [value]="employeeAddress?.city"></qaroni-label-text-preview>
      </ng-container>
    </div>

    <div class="flex w-full items-center justify-between gap-2">
      <ng-container *ngIf="employeeAddress?.stateProvince">
        <qaroni-label-text-preview
          i18n-label
          label="Provincia"
          [value]="employeeAddress?.stateProvince"></qaroni-label-text-preview>
      </ng-container>
      <ng-container *ngIf="employeeAddress?.countryName">
        <qaroni-label-text-preview
          i18n-label
          label="País"
          [value]="employeeAddress?.countryName"></qaroni-label-text-preview>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
