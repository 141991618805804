<div
  *ngIf="hasNameDisplay; else avatarICon"
  class="text-container"
  [ngClass]="bgColorClass"
  [matTooltip]="displayName">
  <span [ngClass]="colorClass">{{ getInitials }}</span>
</div>

<ng-template #avatarICon>
  <div
    [matTooltip]="displayName | tasksUnassignedDescription"
    class="text-container border-avatar-preview bg-white">
    <span class="text-slate-200">- -</span>
  </div>
</ng-template>
