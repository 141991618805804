import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum AssessmentStatusEnum {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
}

export const AssessmentStatusArray = [
  AssessmentStatusEnum.ACTIVE,
  AssessmentStatusEnum.PENDING,
  AssessmentStatusEnum.FINISHED,
  AssessmentStatusEnum.CANCELLED,
];

export const AssessmentStatusInfo: QaroniEnumInformation<AssessmentStatusEnum> =
  {
    [AssessmentStatusEnum.PENDING]: {
      name: $localize`Pendiente`,
    },
    [AssessmentStatusEnum.ACTIVE]: {
      name: $localize`Activa`,
    },
    [AssessmentStatusEnum.FINISHED]: {
      name: $localize`Finalizada`,
    },
    [AssessmentStatusEnum.CANCELLED]: {
      name: $localize`Cancelada`,
    },
  };
