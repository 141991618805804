import { QaroniEnumInformation } from '../qaroni-enum-information/qaroni-enum-information';

export type DayISO = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export enum DaysEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export type DaysType =
  | DaysEnum.MONDAY
  | DaysEnum.TUESDAY
  | DaysEnum.WEDNESDAY
  | DaysEnum.THURSDAY
  | DaysEnum.FRIDAY
  | DaysEnum.SATURDAY
  | DaysEnum.SUNDAY;

export const DaysArray = [
  DaysEnum.MONDAY,
  DaysEnum.TUESDAY,
  DaysEnum.WEDNESDAY,
  DaysEnum.THURSDAY,
  DaysEnum.FRIDAY,
  DaysEnum.SATURDAY,
  DaysEnum.SUNDAY,
];

export const DaysInfo: QaroniEnumInformation<DaysEnum> = {
  [DaysEnum.MONDAY]: {
    name: $localize`Lunes`,
  },
  [DaysEnum.TUESDAY]: {
    name: $localize`Martes`,
  },
  [DaysEnum.WEDNESDAY]: {
    name: $localize`Miércoles`,
  },
  [DaysEnum.THURSDAY]: {
    name: $localize`Jueves`,
  },
  [DaysEnum.FRIDAY]: {
    name: $localize`Viernes`,
  },
  [DaysEnum.SATURDAY]: {
    name: $localize`Sábado`,
  },
  [DaysEnum.SUNDAY]: {
    name: $localize`Domingo`,
  },
};
