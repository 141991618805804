<button
  mat-button
  type="button"
  class="qaroni-close-icon"
  [mat-dialog-close]="false"
  tabindex="-1">
  <mat-icon>close</mat-icon>
</button>

<div mat-dialog-title class="mat-mdc-dialog-title">{{ title }}</div>

<mat-dialog-content *ngIf="message" class="mat-dialog-message">
  <p class="mb-0">{{ message }}</p>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <div class="flex w-full items-center justify-center gap-5">
    <button
      mat-flat-button
      type="button"
      class="qaroni-rounded-button m-1"
      [color]="cancelColorButton"
      [mat-dialog-close]="false">
      <mat-icon class="me-1" *ngIf="cancelMatIcon">
        {{ cancelMatIcon }}
      </mat-icon>
      {{ cancelText }}
    </button>

    <button
      mat-flat-button
      type="button"
      class="qaroni-rounded-button m-1"
      [color]="confirmColorButton"
      [mat-dialog-close]="true">
      <mat-icon class="me-1" *ngIf="confirmMatIcon">
        {{ confirmMatIcon }}
      </mat-icon>
      {{ confirmText }}
    </button>
  </div>
</mat-dialog-actions>
