export enum BackgroundColor {
  orange = 'orange',
  gray = 'gray',
  blue = 'blue',
}

const varTextBackground = 'qaroni-text-background';
const varTextOnBackground = 'qaroni-text-on-background';
const varBgOnBackground = 'qaroni-bg-on-background';
const varTextGray = 'qaroni-text-neutral35';
const varBgGray = 'qaroni-bg-neutral35';
const varTextBlue = 'qaroni-text-primary';

export interface AvatarColor {
  key: string;
  backgroundColor: string;
  color: string;
}

export const AVATAR_COLOR_LIST = [
  {
    key: 'black',
    backgroundColor: varBgOnBackground,
    color: varTextBackground,
  },
  {
    key: 'gray',
    backgroundColor: varBgGray,
    color: varTextBackground,
  },
  {
    key: 'neutral70',
    backgroundColor: 'qaroni-bg-neutral70',
    color: varTextGray,
  },
  {
    key: 'neutral95',
    backgroundColor: 'qaroni-bg-neutral95',
    color: varTextGray,
  },
  {
    key: 'blue',
    backgroundColor: 'qaroni-bg-blue',
    color: varTextBackground,
  },
  {
    key: 'blue-xdark',
    backgroundColor: 'qaroni-bg-blue-xdark',
    color: varTextBackground,
  },
  {
    key: 'primary30',
    backgroundColor: 'qaroni-bg-primary30',
    color: varTextBackground,
  },
  {
    key: 'blue-light',
    backgroundColor: 'qaroni-bg-blue-light',
    color: varTextBlue,
  },
  {
    key: 'green-xdark',
    backgroundColor: 'qaroni-bg-green-xdark',
    color: varTextBackground,
  },
  {
    key: 'green-dark',
    backgroundColor: 'qaroni-bg-green-dark',
    color: varTextBackground,
  },
  {
    key: 'secondary',
    backgroundColor: 'qaroni-bg-success',
    color: varTextBackground,
  },
  {
    key: 'navy',
    backgroundColor: 'qaroni-bg-navy',
    color: varTextBackground,
  },
  {
    key: 'navy-dark',
    backgroundColor: 'qaroni-bg-navy-dark',
    color: varTextBackground,
  },
  {
    key: 'orange-xdark',
    backgroundColor: 'qaroni-bg-orange-xdark',
    color: varTextBackground,
  },
  {
    key: 'orange-dark',
    backgroundColor: 'qaroni-bg-orange-dark',
    color: varTextBackground,
  },
  {
    key: 'red-xdark',
    backgroundColor: 'qaroni-bg-red-xdark',
    color: varTextBackground,
  },
  {
    key: 'red-dark',
    backgroundColor: 'qaroni-bg-red-dark',
    color: varTextBackground,
  },
  {
    key: 'red',
    backgroundColor: 'qaroni-bg-red',
    color: varTextBackground,
  },
  {
    key: 'primary50',
    backgroundColor: 'qaroni-bg-primary50',
    color: varTextBackground,
  },
  {
    key: 'mostly-pure-yellow',
    backgroundColor: 'qaroni-bg-mostly-pure-yellow',
    color: varTextOnBackground,
  },
  {
    key: 'strong-green',
    backgroundColor: 'qaroni-bg-strong-green',
    color: varTextBackground,
  },
  {
    key: 'moderate-violet-strong',
    backgroundColor: 'qaroni-bg-moderate-violet-strong',
    color: varTextBackground,
  },
  {
    key: 'pure-cyan',
    backgroundColor: 'qaroni-bg-pure-cyan',
    color: varTextBackground,
  },
  {
    key: 'bright-magenta',
    backgroundColor: 'qaroni-bg-bright-magenta',
    color: varTextBackground,
  },
  {
    key: 'vivid-pink',
    backgroundColor: 'qaroni-bg-vivid-pink',
    color: varTextBackground,
  },
  {
    key: 'moderate-violet',
    backgroundColor: 'qaroni-bg-moderate-violet',
    color: varTextBackground,
  },
  {
    key: 'strong-red',
    backgroundColor: 'qaroni-bg-strong-red',
    color: varTextBackground,
  },
  {
    key: 'strong-cyan-lime-green',
    backgroundColor: 'qaroni-bg-strong-cyan-lime-green',
    color: varTextBackground,
  },
  {
    key: 'strong-cyan',
    backgroundColor: 'qaroni-bg-strong-cyan',
    color: varTextBackground,
  },
  {
    key: 'bright-red',
    backgroundColor: 'qaroni-bg-bright-red',
    color: varTextBackground,
  },
  {
    key: 'vivid-orange',
    backgroundColor: 'qaroni-bg-vivid-orange',
    color: varTextBackground,
  },
  {
    key: 'pure-blue',
    backgroundColor: 'qaroni-bg-pure-blue',
    color: varTextBackground,
  },
  {
    key: 'accent',
    backgroundColor: 'qaroni-bg-accent',
    color: varTextBackground,
  },
  {
    key: 'pure-yellow',
    backgroundColor: 'qaroni-bg-pure-yellow',
    color: varTextOnBackground,
  },
] as AvatarColor[];
