import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalMinutesFormat',
})
export class TotalMinutesFormatPipe implements PipeTransform {
  transform(minutes: number, withSign?: boolean): string {
    if (!minutes) {
      return '0h 0m';
    }

    if (withSign) {
      const sign: string = minutes < 0 ? '-' : '+';
      minutes = minutes < 0 ? minutes * -1 : minutes;
      return `${sign}${Math.floor(minutes / 60)}h ${minutes % 60}m`;
    }

    if (minutes < 0) minutes *= -1;
    return `${Math.floor(minutes / 60)}h ${minutes % 60}m`;
  }
}
