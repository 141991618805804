import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum EmployeeAbsenceTimeEnum {
  DAYS = 'DAYS',
  HOURS = 'HOURS',
}

export type EmployeeAbsenceTimeType =
  | EmployeeAbsenceTimeEnum.DAYS
  | EmployeeAbsenceTimeEnum.HOURS;

export const EmployeeAbsenceTimeArray = [
  EmployeeAbsenceTimeEnum.DAYS,
  EmployeeAbsenceTimeEnum.HOURS,
];

export const EmployeeAbsenceTimeInfo: QaroniEnumInformation<EmployeeAbsenceTimeEnum> =
  {
    [EmployeeAbsenceTimeEnum.DAYS]: {
      name: $localize`Días`,
    },
    [EmployeeAbsenceTimeEnum.HOURS]: {
      name: $localize`Horas`,
    },
  };
