import {
  BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
  NumberInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  Renderer2,
} from '@angular/core';
import bodymovin from 'lottie-web/build/player/lottie.min';

@Component({
  selector: 'qaroni-animation',
  templateUrl: './qaroni-animation.component.html',
  styleUrls: ['./qaroni-animation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QaroniAnimationComponent implements OnChanges {
  _bodymovin = bodymovin;

  @Input()
  get loop(): boolean {
    return this._loop;
  }
  set loop(value: BooleanInput) {
    this._loop = coerceBooleanProperty(value);
  }
  private _loop: boolean;

  @Input()
  get autoplay(): boolean {
    return this._autoplay;
  }
  set autoplay(value: BooleanInput) {
    this._autoplay = coerceBooleanProperty(value);
  }
  private _autoplay: boolean;

  @Input() src: string;

  @Input()
  get width(): number {
    return this._width;
  }
  set width(value: NumberInput) {
    this._width = coerceNumberProperty(value, 0);
  }
  private _width: number;

  @Input()
  get height(): number {
    return this._height;
  }
  set height(value: NumberInput) {
    this._height = coerceNumberProperty(value, 0);
  }
  private _height: number;

  @Output() animationCreated = new EventEmitter<any[]>();

  constructor(
    private readonly _el: ElementRef,
    private readonly _renderer: Renderer2,
    private readonly _zone: NgZone
  ) {}

  ngOnChanges(): void {
    this._zone.runOutsideAngular(() => this.loadResource());
  }

  loadResource() {
    const options = {};
    if (this.loop === undefined || this.loop === null) {
      options['loop'] = true;
    }
    if (this.autoplay === undefined || this.autoplay === null) {
      options['autoplay'] = true;
    }

    const defaultOptions = {
      container: this._el.nativeElement,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: this.src,
    };
    const animOptions = Object.assign({}, defaultOptions, options);

    this._el.nativeElement.innerHTML = '';

    const anim = this._bodymovin.loadAnimation(animOptions);

    this.animationCreated.emit(anim);

    if (this.width) {
      this._renderer.setStyle(
        this._el.nativeElement,
        'width',
        `${this.width}px`
      );
    }

    if (this.height) {
      this._renderer.setStyle(
        this._el.nativeElement,
        'height',
        `${this.height}px`
      );
    }
  }
}
