import { Pipe, PipeTransform } from '@angular/core';
import { State } from '../types/state';

@Pipe({
  name: 'stateFilter',
})
export class StateFilterPipe implements PipeTransform {
  transform(states: State[], stateName: string): State[] {
    if (!stateName) return states;

    if (Number.isInteger(stateName)) {
      return states?.filter(s =>
        s.stateId.toString().includes(stateName.toString())
      );
    }

    return states?.filter(s =>
      s.name.toLowerCase().includes(stateName.toLowerCase())
    );
  }
}
