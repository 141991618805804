import { Injectable } from '@angular/core';
import { StorageUtilsService } from '@qaroni-core/services/app/storage-utils/storage-utils.service';
import { Observable, Subject } from 'rxjs';
import { Menu } from '../types/menu';

@Injectable({
  providedIn: 'root',
})
export class DashboardStorageService {
  private menu: Menu;
  protected readonly menuSubject = new Subject<Menu>();

  private lsKey = 'menu';

  constructor(private storageUtilsService: StorageUtilsService) {}

  public get(): Menu {
    if (this.storageUtilsService.validateInLocalStorage(this.lsKey)) {
      return JSON.parse(localStorage.getItem(this.lsKey));
    } else {
      return this.menu;
    }
  }

  public get$(): Observable<Menu> {
    return this.menuSubject.asObservable();
  }

  public set(menu: Menu): void {
    if (this.storageUtilsService.validateLocalStorage) {
      localStorage.setItem(this.lsKey, JSON.stringify(menu));
    } else {
      this.menu = menu;
    }
    this.menuSubject.next(menu);
  }

  public reset(): void {
    if (this.storageUtilsService.validateInLocalStorage(this.lsKey)) {
      localStorage.removeItem(this.lsKey);
    } else {
      this.menu = undefined;
    }
    this.menuSubject.next(null);
  }

  get hasMenu(): boolean {
    if (
      typeof this.get()?.isActiveEmployeeMenu === 'boolean' &&
      this.get().isActiveEmployeeMenu !== null
    ) {
      return true;
    }
    return false;
  }
}
