import { animate, animation, style } from '@angular/animations';

export const marginBottomSmallBigConfig = animation([
  style({ marginBottom: '{{from}}' }),
  animate(
    '{{time}} cubic-bezier(0.4, 0.0, 0.2, 1)',
    style({ marginBottom: '*' })
  ),
]);

export const marginBottomBigSmallConfig = animation([
  style({ marginBottom: '{{to}}' }),
  animate(
    '{{time}} cubic-bezier(0.4, 0.0, 0.2, 1)',
    style({ marginBottom: '*' })
  ),
]);
