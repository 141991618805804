import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogData } from '@qaroni-core/types/dialogs/confirmation-dialog-data';

@Component({
  selector: 'qaroni-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) private data: ConfirmationDialogData) {}

  get title(): string {
    return this.data?.title;
  }

  get message(): string {
    return this.data?.message;
  }

  get confirmColorButton(): string {
    return this.data.confirmColorButton ?? 'blue';
  }

  get confirmMatIcon(): string {
    return this.data.confirmMatIcon;
  }

  get confirmText(): string {
    return this.data.confirmText ?? $localize`Guardar`;
  }

  get cancelColorButton(): string {
    return this.data.cancelColorButton ?? 'danger';
  }

  get cancelMatIcon(): string {
    return this.data.cancelMatIcon;
  }

  get cancelText(): string {
    return this.data.cancelText ?? $localize`Cancelar`;
  }
}
