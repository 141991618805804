import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum TicketTypeEnum {
  CONFIG = 'CONFIG',
  SUGGESTION = 'SUGGESTION',
  BUG = 'BUG',
}

export const TicketTypeArray = [
  TicketTypeEnum.CONFIG,
  TicketTypeEnum.SUGGESTION,
  TicketTypeEnum.BUG,
];

export const TicketTypeInfo: QaroniEnumInformation<TicketTypeEnum> = {
  [TicketTypeEnum.CONFIG]: {
    name: $localize`Configuración`,
  },

  [TicketTypeEnum.SUGGESTION]: {
    name: $localize`Sugerencia`,
  },

  [TicketTypeEnum.BUG]: {
    name: $localize`Bug`,
  },
};
