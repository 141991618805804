import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum EmployeeContractEnum {
  DETERMINATE = 'DETERMINATE',
  INDEFINITE = 'INDEFINITE',
  SCHOLAR = 'SCHOLAR',
  TEMPORAL = 'TEMPORAL',
}

export type EmployeeContractType =
  | EmployeeContractEnum.DETERMINATE
  | EmployeeContractEnum.INDEFINITE
  | EmployeeContractEnum.SCHOLAR
  | EmployeeContractEnum.TEMPORAL;

export const EmployeeContractArray = [
  EmployeeContractEnum.DETERMINATE,
  EmployeeContractEnum.INDEFINITE,
  EmployeeContractEnum.SCHOLAR,
  EmployeeContractEnum.TEMPORAL,
];

export const EmployeeContractInfo: QaroniEnumInformation<EmployeeContractEnum> =
  {
    [EmployeeContractEnum.DETERMINATE]: {
      name: $localize`Determinado`,
    },
    [EmployeeContractEnum.INDEFINITE]: {
      name: $localize`Indefinido`,
    },
    [EmployeeContractEnum.SCHOLAR]: {
      name: $localize`Becario`,
    },
    [EmployeeContractEnum.TEMPORAL]: {
      name: $localize`Temporal`,
    },
  };
