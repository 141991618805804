import { Pipe, PipeTransform } from '@angular/core';
import {
  EmployeeJourneyScaleEnum,
  EmployeeJourneyScaleInfo,
} from '@qaroni-app/employee/types/employee-journey-scale.enum';

@Pipe({
  name: 'employeeJourneyScale',
})
export class EmployeeJourneyScalePipe implements PipeTransform {
  transform(value: EmployeeJourneyScaleEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(EmployeeJourneyScaleInfo, value)
    ) {
      return EmployeeJourneyScaleInfo[value].name;
    }

    return value;
  }
}
