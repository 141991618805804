import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Plan } from '@qaroni-app/payments/types/plan';

@Component({
  selector: 'qaroni-payments-card',
  templateUrl: './payments-card.component.html',
  styleUrls: ['./payments-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentsCardComponent {
  @Input()
  get isMonthly(): boolean {
    return this._isMonthly;
  }
  set isMonthly(value: BooleanInput) {
    this._isMonthly = coerceBooleanProperty(value);
  }
  private _isMonthly: boolean = true;

  @Input()
  get hideSubscribeButton(): boolean {
    return this._hideSubscribeButton;
  }
  set hideSubscribeButton(value: BooleanInput) {
    this._hideSubscribeButton = coerceBooleanProperty(value);
  }
  private _hideSubscribeButton: boolean = false;

  @Input() plans: Plan[];
}
