import { Pipe, PipeTransform } from '@angular/core';
import {
  ProjectStatusEnum,
  ProjectStatusInfo,
} from '../../../projects/types/project-status.enum';

@Pipe({
  name: 'projectsStatus',
})
export class ProjectsStatusPipe implements PipeTransform {
  transform(value: ProjectStatusEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(ProjectStatusInfo, value)
    ) {
      return ProjectStatusInfo[value].name;
    }

    return value;
  }
}
