import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params } from '@angular/router';
import { PriceConstantsEnum } from '@qaroni-app/payments/types/price-constants.enum';

@Component({
  selector: 'qaroni-payments-card-subscribe-button',
  templateUrl: './payments-card-subscribe-button.component.html',
  styleUrls: ['./payments-card-subscribe-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentsCardSubscribeButtonComponent {
  @Input()
  get isMonthly(): boolean {
    return this._isMonthly;
  }
  set isMonthly(value: BooleanInput) {
    this._isMonthly = coerceBooleanProperty(value);
  }
  private _isMonthly: boolean = true;

  get frequencyParam(): Params {
    return {
      frequency: this.isMonthly
        ? PriceConstantsEnum.MONTH
        : PriceConstantsEnum.YEAR,
    };
  }
}
