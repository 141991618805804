import { Pipe, PipeTransform } from '@angular/core';
import { CountryEnum, CountryInfo } from '../types/country.enum';

@Pipe({
  name: 'countryName',
})
export class CountryNamePipe implements PipeTransform {
  transform(value: CountryEnum): string {
    if (value && Object.prototype.hasOwnProperty.call(CountryInfo, value)) {
      return CountryInfo[value].name;
    }
    return value;
  }
}
