import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum TicketStatusEnum {
  SOLVED = 'SOLVED',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
}

export const TicketStatusArray = [
  TicketStatusEnum.SOLVED,
  TicketStatusEnum.PENDING,
  TicketStatusEnum.IN_PROGRESS,
];

export const TicketStatusInfo: QaroniEnumInformation<TicketStatusEnum> = {
  [TicketStatusEnum.SOLVED]: {
    name: $localize`Completado`,
  },

  [TicketStatusEnum.PENDING]: {
    name: $localize`Por iniciar`,
  },

  [TicketStatusEnum.IN_PROGRESS]: {
    name: $localize`En proceso`,
  },
};
