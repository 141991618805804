import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarConfig } from '@qaroni-core/config/snackbars/snackbar.config';

export const OAuthSnackbars = {
  successRegister: {
    message: $localize`Registro exitoso. Por favor revisa tu correo electrónico, te hemos enviado un link para validar tu cuenta`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureValidateAccountIsActive: {
    message: $localize`Su cuenta ya ha sido activada. Usted puede iniciar sesión`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureValidateAccountIsInactive: {
    message: $localize`No se puede completar su información ya que su cuenta se encuentra INACTIVA`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureValidateAccountIsCancelled: {
    message: $localize`No se puede completar su información ya que su cuenta se encuentra CANCELADA`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureRegisterEmailExists: {
    message: $localize`Ya existe una cuenta con el correo electrónico ingresado.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureRegisterEmailIsNotValid: {
    message: $localize`El correo electrónico no es válido`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureRegisterUserIsActive: {
    message: $localize`Ya existe una cuenta activa con el email ingresado. Por favor, continua con el proceso de inicio de sesión.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureRegisterUserIsCancelled: {
    message: $localize`La cuenta se encuentra cancelada. No se puede dar de alta.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureRegisterUserIsInactive: {
    message: $localize`La cuenta se encuentra inactiva. No se puede dar de alta.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureRegisterUserIsBlocked: {
    message: $localize`La cuenta se encuentra bloqueada. Reestablece la contraseña para activar tu cuenta.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureLoginTheAccountHasNotExists: {
    message: $localize`No existe una cuenta con el email ingresado`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureLoginAccountIsInactive: {
    message: $localize`No se puede iniciar sesión ya que su cuenta se encuentra INACTIVA`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureLoginAccountIsCancelled: {
    message: $localize`No se puede iniciar sesión ya que su cuenta se encuentra CANCELADA`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureLoginAccountIsBlocked: {
    message: $localize`No se puede iniciar sesión ya que su cuenta se encuentra BLOQUEADA. Recupera tu contraseña para ACTIVAR tu cuenta.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureLoginValidateEmailsButIsNotValidates: {
    message: $localize`No se puede iniciar sesión ya que usted no ha completado su información. Para completar su información ingrese al link que se le envió a su correo electrónico`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureLoginInvalidCredentials: {
    message: $localize`Credenciales inválidas`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  successForgot: {
    message: $localize`Se ha enviado a tu correo electrónico un código de validación`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failedForgotTheAccountHasNotExists: {
    message: $localize`No existe una cuenta con el email ingresado`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failedForgotTheAccountIsCreated: {
    message: $localize`No se puede recuperar contraseña ya que su cuenta aún no ha sido confirmada y/o validada`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failedForgotTheAccountIsInactive: {
    message: $localize`No se puede recuperar contraseña ya que su cuenta se encuentra INACTIVA`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failedForgotTheAccountIsCancelled: {
    message: $localize`No se puede recuperar contraseña ya que su cuenta se encuentra CANCELADA`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureResetPasswordAccountIsNotActive: {
    message: $localize`No se puede cambiar su contraseña ya que su cuenta no está activa.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureLoginTooManyFailedAttempts: {
    message: $localize`La cuenta ha sido bloqueada, por favor revisa el correo electrónico y sigue las instrucciones para recuperar la contraseña`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureEmailInvalid: {
    message: $localize`El correo electrónico ingresado es incorrecto. Por favor, inténtalo de nuevo.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureAccountNotValidated: {
    message: $localize`La cuenta no ha sido verificada. Se ha enviado un correo electrónico con un enlace de verificación.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureSendMagicLinkAccountIsCreated: {
    message: $localize`La cuenta no ha sido verificada. No puede iniciar sesión con link mágico.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureSendMagicLinkAccountIsInactive: {
    message: $localize`La cuenta esta inactiva. No puede iniciar sesión con link mágico.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureSendMagicLinkAccountIsBlocked: {
    message: $localize`La cuenta esta BLOQUEADA. No puede iniciar sesión con link mágico. Recupera tu contraseña para ACTIVAR tu cuenta.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureExpiredSession: {
    message: $localize`La sesión ha expirado. Por favor inicia sesión`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureLowRecaptchaScore: {
    message: $localize`¡Upss!, parece que el navegador te ha confundido con un robot y por seguridad no te permitimos continuar, por favor intenta nuevamente`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
};
