<ng-container *ngIf="fc?.errors?.required">
  <ng-container i18n>El campo es obligatorio</ng-container>.
  {{ stringHelper || aditionalString?.required }}
</ng-container>

<ng-container *ngIf="fc?.errors?.pattern">
  <ng-container i18n
    >Utiliza un formato que coincida con el solicitado</ng-container
  >.
  {{ stringHelper || aditionalString?.pattern }}
</ng-container>

<ng-container *ngIf="fc?.errors?.min">
  <ng-container i18n>El valor debe ser superior o igual a</ng-container>
  {{ fc?.errors?.min?.min }}.
  {{ stringHelper || aditionalString?.min }}
</ng-container>

<ng-container *ngIf="fc?.errors?.max">
  <ng-container i18n>El valor debe ser inferior o igual a</ng-container>
  {{ fc?.errors?.max?.max }}.
  {{ stringHelper || aditionalString?.max }}
</ng-container>

<ng-container *ngIf="fc?.errors?.email">
  <ng-container i18n
    >Debe ser una dirección de correo electrónico válida</ng-container
  >.
  {{ stringHelper || aditionalString?.email }}
</ng-container>

<ng-container *ngIf="fc?.errors?.minlength">
  <ng-container i18n>La longitud debe ser como mínimo</ng-container>
  {{ fc?.errors?.minlength?.requiredLength }}
  <ng-container i18n>caracteres</ng-container>.
  {{ stringHelper || aditionalString?.minlength }}
</ng-container>

<ng-container *ngIf="fc?.errors?.maxlength">
  <ng-container i18n>La longitud debe ser como máximo</ng-container>
  {{ fc?.errors?.maxlength?.requiredLength }}
  <ng-container i18n>caracteres</ng-container>.
  {{ stringHelper || aditionalString?.maxlength }}
</ng-container>

<ng-container *ngIf="fc?.errors?.matDatepickerMin">
  <ng-container i18n>Fecha no permitida, debe ser posterior</ng-container>.
  {{ stringHelper || aditionalString?.matDatepickerMin }}
</ng-container>

<ng-container *ngIf="fc?.errors?.matDatepickerMax">
  <ng-container i18n>Fecha no permitida, debe ser anterior</ng-container>.
  {{ stringHelper || aditionalString?.matDatepickerMax }}
</ng-container>

<ng-container *ngIf="fc?.errors?.matchPassword">
  <ng-container i18n>Las contraseñas no coinciden</ng-container>.
</ng-container>

<ng-container *ngIf="fc?.errors?.validateCheck">
  <ng-container i18n>La hora de fin debe ser mayor</ng-container>.
</ng-container>

<ng-container *ngIf="fc?.errors?.timezoneError">
  <ng-container i18n>La zona horaria es incorrecta</ng-container>.
</ng-container>

<ng-container *ngIf="fc?.errors?.countryError">
  <ng-container i18n>El país es incorrecto</ng-container>.
</ng-container>

<ng-container *ngIf="fc?.errors?.stateError">
  <ng-container i18n>La provincia es incorrecta</ng-container>.
</ng-container>

<ng-container *ngIf="fc?.errors?.cityError">
  <ng-container i18n>La ciudad es incorrecta</ng-container>.
</ng-container>

<ng-container *ngIf="fc?.errors?.invalidSumOfWeights">
  <ng-container i18n>La suma de los pesos debe ser igual a 100</ng-container>.
</ng-container>
