import { Pipe, PipeTransform } from '@angular/core';
import {
  EmployeeDisableTypeEnum,
  EmployeeDisableTypeInfo,
} from '@qaroni-app/employee/types/employee-disable-type.enum';

@Pipe({
  name: 'employeeDisableType',
})
export class EmployeeDisableTypePipe implements PipeTransform {
  transform(value: EmployeeDisableTypeEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(EmployeeDisableTypeInfo, value)
    ) {
      return EmployeeDisableTypeInfo[value].name;
    }

    return value;
  }
}
