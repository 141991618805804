<div
  @toggleHeight
  class="flex w-full flex-col gap-3 lg:flex-row"
  *ngIf="scoreboards">
  <div class="w-full px-2 lg:w-1/3">
    <div class="bg-scoreboard-working py-3">
      <div class="grid-container mx-5">
        <div class="w-full">
          <div class="display-6">
            {{ scoreboards?.openChecks }}
          </div>
          <div class="lead">Trabajando</div>
        </div>
        <div class="icon-qr-code ms-3">
          <mat-icon class="mt-1">qr_code_scanner</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full px-2 lg:w-1/3">
    <div class="bg-scoreboard-out py-3">
      <div class="grid-container mx-5">
        <div>
          <div class="display-6">
            {{ scoreboards?.closeChecks }}
          </div>
          <div class="lead">Fuera</div>
        </div>
        <div class="icon-snooze ms-3">
          <mat-icon class="mt-1">snooze</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full px-2 lg:w-1/3">
    <div class="bg-scoreboard-total py-3">
      <div class="grid-container mx-5">
        <div>
          <div class="display-6">
            {{ scoreboards?.totalEmployees }}
          </div>
          <div class="lead">Total</div>
        </div>
        <div class="icon-people ms-3">
          <mat-icon class="mt-1">people</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
