<mat-card
  @enterFadeIn
  class="qaroni-card-bg-background mat-elevation-z0 h-full pb-2"
  *ngIf="employeeContract">
  <mat-card-content class="flex w-full flex-col gap-2">
    <div class="mt-1 w-full">
      <span class="qaroni-detail-title" i18n>Contrato</span>
    </div>

    <mat-divider class="my-3"></mat-divider>

    <div class="grid w-full grid-cols-2 gap-3">
      <div
        class="col-span-2 md:col-span-1"
        *ngIf="employeeContract?.educationLevel">
        <qaroni-label-text-preview
          label="Nivel de educación"
          [value]="
            employeeContract?.educationLevel | employeeEducation
          "></qaroni-label-text-preview>
      </div>
      <div
        class="col-span-2 md:col-span-1"
        *ngIf="employeeContract?.grossSalary">
        <qaroni-label-text-preview
          label="Salario anual"
          [value]="
            '&euro; ' + employeeContract?.grossSalary
          "></qaroni-label-text-preview>
      </div>
      <div
        class="col-span-2 md:col-span-1"
        *ngIf="employeeContract?.contractCode">
        <qaroni-label-text-preview
          label="Código de contrato"
          [value]="employeeContract?.contractCode"></qaroni-label-text-preview>
      </div>
      <div class="col-span-2 md:col-span-1" *ngIf="employeeContract?.ccc">
        <qaroni-label-text-preview
          label="Código de cuenta de cotización"
          [value]="employeeContract?.ccc"></qaroni-label-text-preview>
      </div>
      <div class="col-span-2 md:col-span-1" *ngIf="employeeContract?.ssn">
        <qaroni-label-text-preview
          label="Número de seguridad social"
          [value]="employeeContract?.ssn"></qaroni-label-text-preview>
      </div>
      <div class="col-span-2 md:col-span-1" *ngIf="employeeContract?.iban">
        <qaroni-label-text-preview
          label="IBAN"
          [value]="employeeContract?.iban"></qaroni-label-text-preview>
      </div>
      <div
        class="col-span-2 md:col-span-1"
        *ngIf="employeeContract?.vacationDays">
        <qaroni-label-text-preview
          label="Días de vacaciones"
          [value]="employeeContract?.vacationDays"></qaroni-label-text-preview>
      </div>
      <div
        class="col-span-2 md:col-span-1"
        *ngIf="employeeContract?.jobPosition">
        <qaroni-label-text-preview
          label="Cargo"
          [value]="employeeContract?.jobPosition"></qaroni-label-text-preview>
      </div>
      <div class="col-span-2 md:col-span-1" *ngIf="employeeContract?.type">
        <qaroni-label-text-preview
          label="Tipo de contrato"
          [value]="
            employeeContract?.type | employeeContract
          "></qaroni-label-text-preview>
      </div>
      <div
        class="col-span-2 md:col-span-1"
        *ngIf="employeeContract?.journeyScale">
        <qaroni-label-text-preview
          label="Tipo de jornada"
          [value]="
            employeeContract?.journeyScale | employeeJourneyScale
          "></qaroni-label-text-preview>
      </div>
      <div
        class="col-span-2 md:col-span-1"
        *ngIf="employeeContract?.journeyHours">
        <qaroni-label-text-preview
          label="Horas de la jornada"
          [value]="employeeContract?.journeyHours"></qaroni-label-text-preview>
      </div>
      <div class="col-span-2 md:col-span-1" *ngIf="employeeContract?.start">
        <qaroni-label-text-preview
          label="Inicio de contrato"
          [value]="employeeContract?.start"></qaroni-label-text-preview>
      </div>
      <div class="col-span-2 md:col-span-1" *ngIf="employeeContract?.end">
        <qaroni-label-text-preview
          label="Fin de contrato"
          [value]="employeeContract?.end"></qaroni-label-text-preview>
      </div>
      <div class="col-span-2 md:col-span-1" *ngIf="employeeContract?.isOnTest">
        <qaroni-label-text-preview
          label="Se encuentra a prueba"
          [value]="
            employeeContract?.isOnTest | boolean
          "></qaroni-label-text-preview>
      </div>
      <div
        class="col-span-2 md:col-span-1"
        *ngIf="employeeContract?.testPeriodFinish">
        <qaroni-label-text-preview
          label="Fin de prueba"
          [value]="
            employeeContract?.testPeriodFinish
          "></qaroni-label-text-preview>
      </div>
    </div>
  </mat-card-content>
</mat-card>
