import { Pipe, PipeTransform } from '@angular/core';
import {
  TicketStatusEnum,
  TicketStatusInfo,
} from '@qaroni-app/ticket/types/ticket-status.enum';

@Pipe({
  name: 'ticketStatus',
})
export class TicketStatusPipe implements PipeTransform {
  transform(value: TicketStatusEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(TicketStatusInfo, value)
    ) {
      return TicketStatusInfo[value].name;
    }
    return value;
  }
}
