import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiQWorkardsEnv } from '@qaroni-core/config/apis/api-qworkards/api-qworkards.config';
import { Observable } from 'rxjs';
import {
  EmailCaptchaJson,
  LoginJson,
  ResetPasswordJson,
  SignUpJson,
  ValidateOtpJson,
  ValidateRegisterJson,
} from '../types/authentication';

@Injectable({
  providedIn: 'root',
})
export class OAuthHttpService {
  constructor(private http: HttpClient) {}

  public register$(signUpJson: SignUpJson): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/registers`;

    return this.http.post(url, signUpJson, { observe: 'response' });
  }

  public validate$(
    userID: number,
    validateRegisterJson: ValidateRegisterJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/users/${userID}/validates`;

    return this.http.patch(url, validateRegisterJson, { observe: 'response' });
  }

  public login$(loginJSON: LoginJson): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/logins`;

    return this.http.post(url, loginJSON, { observe: 'response' });
  }

  public loginWithGoogleAccount$(): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/socialites/google/auth`;

    return this.http.get(url, { observe: 'response' });
  }

  public signUpWithGoogleAccount$(): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/socialites/google/registers`;

    return this.http.get(url, { observe: 'response' });
  }

  public forgot$(
    emailCaptchaJson: EmailCaptchaJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/forgots`;

    return this.http.post(url, emailCaptchaJson, { observe: 'response' });
  }

  public resetPassword$(
    userID: number,
    resetPasswordJSON: ResetPasswordJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/users/${userID}/passwords/resets/temps`;

    return this.http.patch(url, resetPasswordJSON, { observe: 'response' });
  }

  public validateOTP$(
    userID: number,
    validateOtpJson: ValidateOtpJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/users/${userID}/otp/validates/temps`;

    return this.http.post(url, validateOtpJson, { observe: 'response' });
  }

  public getUser$(userId: number): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/users/${userId}`;

    return this.http.get(url, { observe: 'response' });
  }

  public validateEmail$(
    emailCaptchaJson: EmailCaptchaJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/validates/emails`;

    return this.http.post(url, emailCaptchaJson, { observe: 'response' });
  }

  public sendMagicLink$(email: string): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/magics/tokens`;

    return this.http.post(url, { email }, { observe: 'response' });
  }

  public validateMagicLink$(
    token: string,
    signature: string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/magics/tokens/${token}`;

    return this.http.get(url, {
      observe: 'response',
      params: { signature },
    });
  }

  public sendRegisterLink$(
    invitationId: number,
    expires: string,
    signature: string,
    appCode: string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/invitations/${invitationId}/validates`;

    return this.http.get(url, {
      observe: 'response',
      params: { expires, signature, 'App-Code': appCode },
    });
  }
}
