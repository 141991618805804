<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="typeEnum.HOLIDAYS">
    <div class="absence-type type-holidays-color" i18n>Vacaciones</div>
  </ng-container>

  <ng-container *ngSwitchCase="typeEnum.SICKNESS">
    <div class="absence-type type-sickness-color" i18n>Enfermedad</div>
  </ng-container>

  <ng-container *ngSwitchCase="typeEnum.OTHER">
    <div class="absence-type type-other-color" i18n>Otro</div>
  </ng-container>

  <ng-container *ngSwitchDefault></ng-container>
</ng-container>
