import { Component, Input } from '@angular/core';
import { CompanyAddress } from '@qaroni-app/company/types/company-address';
import { WorkplaceAddress } from '@qaroni-app/workplace/types/workplace-address';

@Component({
  selector: 'qaroni-address-string',
  templateUrl: './address-string.component.html',
  styleUrls: ['./address-string.component.scss'],
})
export class AddressStringComponent {
  @Input() address: CompanyAddress | WorkplaceAddress;
}
