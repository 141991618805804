import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum UserStatusEnum {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
}

export type UserStatusType = UserStatusEnum.CREATED | UserStatusEnum.ACTIVE;

export const UserStatusArray = [UserStatusEnum.CREATED, UserStatusEnum.ACTIVE];

export const UserStatusInfo: QaroniEnumInformation<UserStatusEnum> = {
  [UserStatusEnum.CREATED]: {
    name: $localize`Creado`,
  },
  [UserStatusEnum.ACTIVE]: {
    name: $localize`Activo`,
  },
  [UserStatusEnum.BLOCKED]: {
    name: $localize`Bloqueado`,
  },
};

export enum UserStatusSelectorEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const UserStatusSelectorArray = [
  UserStatusSelectorEnum.ACTIVE,
  UserStatusSelectorEnum.INACTIVE,
];

export const UserStatusSelectorInfo: QaroniEnumInformation<UserStatusSelectorEnum> =
  {
    [UserStatusSelectorEnum.ACTIVE]: {
      name: $localize`Usuarios activos`,
    },
    [UserStatusSelectorEnum.INACTIVE]: {
      name: $localize`Usuarios en baja`,
    },
  };
