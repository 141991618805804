import { Pipe, PipeTransform } from '@angular/core';
import {
  AssessmentStatusEnum,
  AssessmentStatusInfo,
} from '../../../assessment/types/assessment-status.enum';

@Pipe({
  name: 'assessmentStatus',
})
export class AssessmentStatusPipe implements PipeTransform {
  transform(value: AssessmentStatusEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(AssessmentStatusInfo, value)
    ) {
      return AssessmentStatusInfo[value].name;
    }

    return value;
  }
}
