import { NgModule } from '@angular/core';
import { AbsenceEmployeeStatusPipe } from './absence-employee-status/absence-employee-status.pipe';
import { AssessmentEmployeeStatusPipe } from './assessment-employee-status/assessment-employee-status.pipe';
import { AssessmentStatusPipe } from './assessment-status/assessment-status.pipe';
import { BooleanPipe } from './boolean/boolean.pipe';
import { DayInfoPipe } from './date/day-info.pipe';
import { TotalMinutesFormatPipe } from './date/total-minutes-format.pipe';
import { EmployeeAbsenceStatusPipe } from './employee/employee-absence-status.pipe';
import { EmployeeAbsenceTimePipe } from './employee/employee-absence-time.pipe';
import { EmployeeContractPipe } from './employee/employee-contract.pipe';
import { EmployeeDisabilityDegreePipe } from './employee/employee-disability-degree.pipe';
import { EmployeeDisableTypePipe } from './employee/employee-disable-type.pipe';
import { EmployeeDocumentTypePipe } from './employee/employee-document-type.pipe';
import { EmployeeEducationPipe } from './employee/employee-education.pipe';
import { EmployeeGenderPipe } from './employee/employee-gender.pipe';
import { EmployeeHistoryPipe } from './employee/employee-history.pipe';
import { EmployeeJourneyScalePipe } from './employee/employee-journey-scale.pipe';
import { EmployeeProfilePipe } from './employee/employee-profile.pipe';
import { EmployeeShiftStatusSelectorPipe } from './employee/employee-shift-status-selector.pipe';
import { EmployeeStatusSelectorPipe } from './employee/employee-status-selector.pipe';
import { EmployeeStatusPipe } from './employee/employee-status.pipe';
import { EmployeeTypeCheckPipe } from './employee/employee-type-check.pipe';
import { SelectorEmployeeStatusPipe } from './employee/selector-employee-status.pipe';
import { ShiftStatusPipe } from './employee/shift-status.pipe';
import { FileSizePipe } from './file-size/file-size.pipe';
import { PaymentMethodInfoPipe } from './payments/payment-method-info.pipe';
import { ProjectsStatusPipe } from './project-status/projects-status.pipe';
import { TasksStatusPipe } from './task-status/tasks-status.pipe';
import { TasksUnassignedPipe } from './tasks-unassigned/tasks-unassigned.pipe';
import { TicketPriorityPipe } from './ticket/ticket-priority.pipe';
import { TicketStatusPipe } from './ticket/ticket-status.pipe';
import { TicketTypePipe } from './ticket/ticket-type.pipe';
import { TypeFilesIconsPipe } from './ticket/type-files-icons.pipe';
import { TotalHoursPerDayPipe } from './time/total-hours-per-day.pipe';
import { TotalHoursPerWeekPipe } from './time/total-hours-per-week.pipe';
import { TotalHoursSinglePipe } from './time/total-hours-single.pipe';
import { TimezoneFilterPipe } from './timezone/timezone-filter.pipe';
import { UserRoleHintPipe } from './user/user-role-hint.pipe';
import { UserRolePipe } from './user/user-role.pipe';
import { UserStatusPipe } from './user/user-status.pipe';

const pipes: any[] = [
  AbsenceEmployeeStatusPipe,
  AssessmentEmployeeStatusPipe,
  AssessmentStatusPipe,
  BooleanPipe,
  DayInfoPipe,
  EmployeeAbsenceStatusPipe,
  EmployeeAbsenceTimePipe,
  EmployeeContractPipe,
  EmployeeDisabilityDegreePipe,
  EmployeeDisableTypePipe,
  EmployeeDocumentTypePipe,
  EmployeeEducationPipe,
  EmployeeGenderPipe,
  EmployeeHistoryPipe,
  EmployeeJourneyScalePipe,
  EmployeeProfilePipe,
  EmployeeShiftStatusSelectorPipe,
  EmployeeStatusPipe,
  EmployeeStatusSelectorPipe,
  EmployeeTypeCheckPipe,
  FileSizePipe,
  PaymentMethodInfoPipe,
  ProjectsStatusPipe,
  SelectorEmployeeStatusPipe,
  ShiftStatusPipe,
  TasksStatusPipe,
  TasksUnassignedPipe,
  TicketPriorityPipe,
  TicketStatusPipe,
  TicketTypePipe,
  TimezoneFilterPipe,
  TotalHoursPerDayPipe,
  TotalHoursPerWeekPipe,
  TotalHoursSinglePipe,
  TotalMinutesFormatPipe,
  TypeFilesIconsPipe,
  UserRoleHintPipe,
  UserRolePipe,
  UserStatusPipe,
];

@NgModule({
  declarations: [pipes],
  exports: [pipes],
})
export class PipesModule {}
