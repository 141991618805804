import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum EmployeeHistoryEnum {
  START = 'START',
  FINISH = 'FINISH',
}

export type EmployeeHistoryType =
  | EmployeeHistoryEnum.START
  | EmployeeHistoryEnum.FINISH;

export const EmployeeHistoryArray = [
  EmployeeHistoryEnum.START,
  EmployeeHistoryEnum.FINISH,
];

export const EmployeeHistoryInfo: QaroniEnumInformation<EmployeeHistoryEnum> = {
  [EmployeeHistoryEnum.START]: {
    name: $localize`Se ha dado de alta`,
  },
  [EmployeeHistoryEnum.FINISH]: {
    name: $localize`Se ha dado de baja`,
  },
};
