import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthStorageService } from '@qaroni-app/auth/services/o-auth-storage.service';
import { CommonsHttpService } from '@qaroni-core/services/commons/commons-http/commons-http.service';
import { UrlJson } from '@qaroni-core/types/url-json/url-json';
import { Observable, Subject } from 'rxjs';
import { AddSeatsJson } from '../types/add-seats-json';
import { ConstantJson } from '../types/constant-json';
import { PaymentValidate } from '../types/payment-validate';
import { StripeSubscription } from '../types/subscription';
import { PaymentsHttpService } from './payments-http.service';
import { PaymentsSnackbarsService } from './payments-snackbars.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  protected readonly stripeSubsSubject = new Subject<StripeSubscription>();
  protected readonly urlSubject = new Subject<UrlJson>();
  protected readonly messageSubject = new Subject<string>();

  constructor(
    private commonsHttp: CommonsHttpService,
    private oAuthStorage: OAuthStorageService,
    private paymentsHttpService: PaymentsHttpService,
    private paymentsSnackbarsService: PaymentsSnackbarsService
  ) {}

  // ==========================================================================================
  // Add Seats
  // ==========================================================================================

  public getStripeSubs$(): Observable<StripeSubscription> {
    return this.stripeSubsSubject.asObservable();
  }

  public addSeats(
    companyID: number,
    subscriptionID: number,
    addSeatsJson: AddSeatsJson
  ): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.paymentsHttpService
      .addSeats$(companyID, subscriptionID, addSeatsJson)
      .subscribe({
        next: this.nextAddSeats,
        error: this.errorAddSeats,
      });
  }

  private nextAddSeats = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const stripeSubscription: StripeSubscription = data.body.result[0];
      this.stripeSubsSubject.next(stripeSubscription);
    } else {
      this.stripeSubsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorAddSeats = (error: HttpErrorResponse): void => {
    this.stripeSubsSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0046')) {
      this.paymentsSnackbarsService.failureYouDoNotHaveAPaymentMethod();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0065')) {
      this.paymentsSnackbarsService.failureDeclinedCreditCard();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Remove Seats
  // ==========================================================================================

  public removeSeats(
    companyID: number,
    subscriptionID: number,
    addSeatsJson: AddSeatsJson
  ): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.paymentsHttpService
      .removeSeats$(companyID, subscriptionID, addSeatsJson)
      .subscribe({
        next: this.nextRemoveSeats,
        error: this.errorRemoveSeats,
      });
  }

  private nextRemoveSeats = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const stripeSubscription: StripeSubscription = data.body.result[0];
      this.stripeSubsSubject.next(stripeSubscription);
      this.paymentsSnackbarsService.successRemoveSeats();
    } else {
      this.stripeSubsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorRemoveSeats = (error: HttpErrorResponse): void => {
    this.stripeSubsSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0046')) {
      this.paymentsSnackbarsService.failureYouDoNotHaveAPaymentMethod();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0047')) {
      this.paymentsSnackbarsService.failureRemoveSeatsIsNotValid();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Update Payment Method
  // ==========================================================================================

  public updatePaymentMethod$(): Observable<UrlJson> {
    return this.urlSubject.asObservable();
  }

  public updatePaymentMethod(companyID: number): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.paymentsHttpService.updatePaymentMethod$(companyID).subscribe({
      next: this.nextUpdatePaymentMethod,
      error: this.errorUpdatePaymentMethod,
    });
  }

  private nextUpdatePaymentMethod = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const url: UrlJson = data.body.result[0];
      this.urlSubject.next(url);
    } else {
      this.urlSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorUpdatePaymentMethod = (error: HttpErrorResponse): void => {
    this.urlSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Switch Constant Billing
  // ==========================================================================================

  public switchConstantBilling(
    companyID: number,
    subscriptionID: number,
    constantJson: ConstantJson
  ): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.paymentsHttpService
      .switchConstantBilling$(companyID, subscriptionID, constantJson)
      .subscribe({
        next: this.nextSwitchConstantBilling,
        error: this.errorSwitchConstantBilling,
      });
  }

  private nextSwitchConstantBilling = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      this.messageSubject.next('OK switch constant billing');
      this.paymentsSnackbarsService.successSwitchConstantBilling();
    } else {
      this.messageSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorSwitchConstantBilling = (error: HttpErrorResponse): void => {
    this.messageSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0046')) {
      this.paymentsSnackbarsService.failureYouDoNotHaveAPaymentMethod();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Validate Payment Success
  // ==========================================================================================

  public message$(): Observable<string> {
    return this.messageSubject.asObservable();
  }

  public validatePaymentSuccess(
    companyID: number,
    subscriptionID: string,
    paymentValidate: PaymentValidate
  ): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.paymentsHttpService
      .validatePaymentSuccess$(companyID, subscriptionID, paymentValidate)
      .subscribe({
        next: this.nextValidatePaymentSuccess,
        error: this.errorValidatePaymentSuccess,
      });
  }

  private nextValidatePaymentSuccess = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      this.messageSubject.next('Payment OK');
    } else {
      this.messageSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorValidatePaymentSuccess = (error: HttpErrorResponse): void => {
    this.messageSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Validate Payment Cancel
  // ==========================================================================================

  public validatePaymentCancel(
    companyID: number,
    subscriptionID: string,
    paymentValidate: PaymentValidate
  ): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.paymentsHttpService
      .validatePaymentCancel$(companyID, subscriptionID, paymentValidate)
      .subscribe({
        next: this.nextValidatePaymentCancel,
        error: this.errorValidatePaymentCancel,
      });
  }

  private nextValidatePaymentCancel = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      this.messageSubject.next('Payment ERROR');
    } else {
      this.messageSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorValidatePaymentCancel = (error: HttpErrorResponse): void => {
    this.messageSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // We Will Contact You
  // ==========================================================================================

  public weWillContactYou(): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    this.paymentsHttpService.weWillContactYou$().subscribe({
      next: this.nextWeWillContactYou,
      error: this.errorWeWillContactYou,
    });
  }

  private nextWeWillContactYou = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      this.paymentsSnackbarsService.successWeWillContactYou();
    } else {
      this.paymentsSnackbarsService.failureWeWillContactYou();
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorWeWillContactYou = (error: HttpErrorResponse): void => {
    this.paymentsSnackbarsService.failureWeWillContactYou();
    this.commonsHttp.errorsHttp.communication(error);
  };
}
