import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthStorageService } from '@qaroni-app/auth/services/o-auth-storage.service';
import { CommonsHttpService } from '@qaroni-core/services/commons/commons-http/commons-http.service';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UserRole } from '../types/user-role';
import { UserRoleCache } from '../types/user-role-cache.class';
import { UserRoleEnum } from '../types/user-role.enum';
import { WorkersHttpService } from './workers-http.service';

@Injectable({
  providedIn: 'root',
})
export class WorkersService {
  protected readonly userRoleSubject = new Subject<UserRole>();
  private userRole: UserRoleCache = new UserRoleCache();

  constructor(
    private commonsHttp: CommonsHttpService,
    private workersHttpService: WorkersHttpService,
    private oAuthStorage: OAuthStorageService
  ) {}

  // ==========================================================================================
  // Check company for redirect
  // ==========================================================================================

  public checkCompanyForRedirect(
    companyID: number,
    role: UserRoleEnum,
    employeeID?: number
  ): string[] {
    const urlToDashboard = `/companies/${companyID}`;

    if (!employeeID) {
      return [`${urlToDashboard}/landing`];
    } else {
      if (
        role === UserRoleEnum.ADMIN ||
        role === UserRoleEnum.HR ||
        role === UserRoleEnum.MANAGER ||
        role === UserRoleEnum.OWNER
      ) {
        return [`${urlToDashboard}/landing`];
      } else {
        return [`${urlToDashboard}/panel-landing`];
      }
    }
  }

  // ==========================================================================================
  // Get User Role
  // ==========================================================================================

  public getUserRole$(): Observable<UserRole> {
    return this.userRoleSubject.asObservable();
  }

  public getUserRole(companyID: number, userID: number): void {
    if (!this.oAuthStorage.hasOAuth) {
      return;
    }

    if (this.userRole.canReturn(companyID, userID)) {
      this.userRoleSubject.next(this.userRole.get());
    } else if (this.userRole.canApi(companyID, userID)) {
      this.userRole.startToFly();
      this.workersHttpService
        .getUserRole$(companyID, userID)
        .pipe(finalize(() => this.userRole.endFlying()))
        .subscribe({
          next: this.nextGetUserRole,
          error: this.errorGetUserRole,
        });
    }
  }

  private nextGetUserRole = (data: HttpResponse<any>): void => {
    if (
      this.commonsHttp.validationsHttp.verifyStatus200(data) &&
      data?.body?.result?.length
    ) {
      this.userRole.set(data.body.result[0]);
      this.userRoleSubject.next(this.userRole.get());
    } else {
      this.userRoleSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorGetUserRole = (error: HttpErrorResponse): void => {
    this.userRoleSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };
}
