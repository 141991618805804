import { Pipe, PipeTransform } from '@angular/core';
import { ScheduleDate, Shift } from '@qaroni-app/shifts/types/schedule-date';
import { hoursToMinutes, intervalToDuration } from 'date-fns';

@Pipe({
  name: 'totalHoursPerWeek',
})
export class TotalHoursPerWeekPipe implements PipeTransform {
  transform(schdulesDate: ScheduleDate[]): number {
    if (!schdulesDate.length) return 0;
    let shifts: Shift[] = [];

    schdulesDate.forEach(schduleDate => {
      schduleDate.shifts.forEach(shift => {
        shifts.push(shift);
      });
    });

    const durations: Duration[] = shifts.map(shift => {
      if (shift.startTime && shift.endTime) {
        const startTimeHour: number = parseInt(shift.startTime.split(':')[0]);
        const startTimeMinutes: number = parseInt(
          shift.startTime.split(':')[1]
        );

        const endTimeHour: number = parseInt(shift.endTime.split(':')[0]);
        const endTimeMinutes: number = parseInt(shift.endTime.split(':')[1]);

        const duration: Duration = intervalToDuration({
          start: new Date(1997, 6, 15, startTimeHour, startTimeMinutes, 0),
          end: new Date(1997, 6, 15, endTimeHour, endTimeMinutes, 0),
        });
        return duration;
      }
      return null;
    });

    let totalMinutes: number = 0;
    durations.forEach(duration => {
      if (!duration) return;
      totalMinutes += hoursToMinutes(duration.hours) + duration.minutes;
    });

    return totalMinutes;
  }
}
