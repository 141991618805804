<div class="bg-gradients qaroni-text-primary100">
  <div
    class="flex w-full flex-col items-center justify-center gap-3 p-3 sm:flex-row">
    <span class="text-base font-bold">
      <ng-content></ng-content>
    </span>
    <button
      mat-flat-button
      color="ligth-blue"
      class="qaroni-rounded-button"
      routerLink="payments">
      <ng-container i18n>Ir a planes y facturación</ng-container>
    </button>
  </div>
</div>
