import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbsenceStatusEnum } from '@qaroni-app/absences/types/absence-status.enum';

@Component({
  selector: 'qaroni-absence-status-preview',
  templateUrl: './absence-status-preview.component.html',
  styleUrls: ['./absence-status-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbsenceStatusPreviewComponent {
  @Input() status: AbsenceStatusEnum;

  get statusEnum(): typeof AbsenceStatusEnum {
    return AbsenceStatusEnum;
  }
}
