import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum CountryEnum {
  AR = 'AR',
  CL = 'CL',
  CO = 'CO',
  EC = 'EC',
  ES = 'ES',
  PA = 'PA',
  PE = 'PE',
  US = 'US',
  UY = 'UY',
  VE = 'VE',
}

export type CountryType =
  | CountryEnum.AR
  | CountryEnum.CL
  | CountryEnum.CO
  | CountryEnum.EC
  | CountryEnum.ES
  | CountryEnum.PA
  | CountryEnum.PE
  | CountryEnum.US
  | CountryEnum.UY
  | CountryEnum.VE;

export const CountryArray = [
  CountryEnum.ES,
  CountryEnum.AR,
  CountryEnum.CL,
  CountryEnum.CO,
  CountryEnum.EC,
  CountryEnum.PA,
  CountryEnum.PE,
  CountryEnum.US,
  CountryEnum.UY,
  CountryEnum.VE,
];

export const CountryInfo: QaroniEnumInformation<CountryEnum> = {
  [CountryEnum.AR]: {
    name: $localize`Argentina`,
  },
  [CountryEnum.CL]: {
    name: $localize`Chile`,
  },
  [CountryEnum.CO]: {
    name: $localize`Colombia`,
  },
  [CountryEnum.EC]: {
    name: $localize`Ecuador`,
  },
  [CountryEnum.ES]: {
    name: $localize`España`,
  },
  [CountryEnum.PA]: {
    name: $localize`Panamá`,
  },
  [CountryEnum.PE]: {
    name: $localize`Perú`,
  },
  [CountryEnum.US]: {
    name: $localize`Estados Unidos`,
  },
  [CountryEnum.UY]: {
    name: $localize`Uruguay`,
  },
  [CountryEnum.VE]: {
    name: $localize`Venezuela`,
  },
};
