import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarConfig } from '@qaroni-core/config/snackbars/snackbar.config';

export const ProfileSnackbars = {
  successUpdatedProfile: {
    message: `Perfil actualizado correctamente`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  successAddressUpdated: {
    message: `Dirección actualizada`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  successChangedPassword: {
    message: `Contraseña actualizada correctamente`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  successCopiedPin: {
    message: `PIN en portapapeles`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureValidationError: {
    message: `Error de validación`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failurePasswordDoesNotHaveTheCorrectPattern: {
    message: `La contraseña debe contener al menos una letra mayúscula y una minúscula`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
};
