import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum DocumentTypeEnum {
  DNI = 'DNI',
  CIF = 'CIF',
  NIE = 'NIE',
  PASSPORT = 'PASSPORT',
}

export type DocumentTypeType =
  | DocumentTypeEnum.DNI
  | DocumentTypeEnum.CIF
  | DocumentTypeEnum.NIE
  | DocumentTypeEnum.PASSPORT;

export const DocumentTypeArray = [
  DocumentTypeEnum.DNI,
  DocumentTypeEnum.CIF,
  DocumentTypeEnum.NIE,
  DocumentTypeEnum.PASSPORT,
];

export const DocumentTypeInfo: QaroniEnumInformation<DocumentTypeEnum> = {
  [DocumentTypeEnum.DNI]: {
    name: `DNI`,
  },
  [DocumentTypeEnum.CIF]: {
    name: `CIF`,
  },
  [DocumentTypeEnum.NIE]: {
    name: `NIE`,
  },
  [DocumentTypeEnum.PASSPORT]: {
    name: $localize`Pasaporte`,
  },
};
