import { Pipe, PipeTransform } from '@angular/core';
import { DaysEnum, DaysInfo } from '@qaroni-core/types/date/days.enum';

@Pipe({
  name: 'dayInfo',
})
export class DayInfoPipe implements PipeTransform {
  transform(value: DaysEnum): string {
    if (value && Object.prototype.hasOwnProperty.call(DaysInfo, value)) {
      return DaysInfo[value].name;
    }

    return value;
  }
}
