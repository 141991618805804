import { Chart, Plugin } from 'chart.js';

export class QaroniRequiredProgressBarPlugin implements Plugin<'bar', any> {
  id: 'qaroniRequiredProgressBar';
  public progressRequired: number;

  constructor(progressRequired: number = 0) {
    this.progressRequired = progressRequired;
  }

  beforeDraw(chart: Chart<'bar'>, args, options) {
    if (this.progressRequired > 0) {
      const ctx = chart.ctx;
      ctx.save();
      var xAxis = chart.scales.x;
      var yAxis = chart.scales.y;
      var progressObtainedPosition = xAxis.getPixelForValue(
        this.progressRequired
      );
      var barHeight = yAxis.bottom - yAxis.top;

      ctx.fillStyle = 'rgb(222, 226, 230)';
      ctx.fillRect(
        xAxis.left,
        yAxis.top,
        progressObtainedPosition - xAxis.left,
        barHeight
      );
      ctx.restore();
    }
  }

  afterDraw(chart: Chart<'bar'>, args: any, options: any) {
    if (this.progressRequired > 0) {
      const ctx = chart.ctx;
      ctx.save();
      var xAxis = chart.scales.x;
      var yAxis = chart.scales.y;
      var progressObtainedPosition = xAxis.getPixelForValue(
        this.progressRequired
      );
      var barHeight = yAxis.bottom - yAxis.top;
      ctx.fillStyle = '#000000';
      ctx.fillRect(progressObtainedPosition - 2, yAxis.top, 5, barHeight);
      ctx.restore();
    }
  }
}
