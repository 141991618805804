import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum ProjectStatusEnum {
  PENDING = 'PENDING',
  PROGRESS = 'PROGRESS',
  INACTIVE = 'INACTIVE',
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
}

export type ProjectStatusType =
  | ProjectStatusEnum.PENDING
  | ProjectStatusEnum.PROGRESS
  | ProjectStatusEnum.INACTIVE
  | ProjectStatusEnum.CANCELLED
  | ProjectStatusEnum.FINISHED;

export const ProjectStatusArray = [
  ProjectStatusEnum.PENDING,
  ProjectStatusEnum.PROGRESS,
  ProjectStatusEnum.INACTIVE,
  ProjectStatusEnum.CANCELLED,
  ProjectStatusEnum.FINISHED,
];

export const ProjectStatusInfo: QaroniEnumInformation<ProjectStatusEnum> = {
  [ProjectStatusEnum.PENDING]: {
    name: $localize`Por iniciar`,
  },
  [ProjectStatusEnum.PROGRESS]: {
    name: $localize`En progreso`,
  },
  [ProjectStatusEnum.INACTIVE]: {
    name: $localize`En pausa`,
  },
  [ProjectStatusEnum.CANCELLED]: {
    name: $localize`Cancelado`,
  },

  [ProjectStatusEnum.FINISHED]: {
    name: $localize`Completado`,
  },
};
