import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DayCheck } from '@qaroni-app/check/types/month-check';
import { DaySchedule } from '@qaroni-app/check/types/month-schedule';

@Component({
  selector: 'qaroni-check-details-hours',
  templateUrl: './check-details-hours.component.html',
  styleUrls: ['./check-details-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckDetailsHoursComponent {
  @Input()
  get hours(): number {
    return this._hours;
  }
  set hours(value: NumberInput) {
    this._hours = coerceNumberProperty(value, 0);
  }
  private _hours: number;

  @Input()
  get scheduleTimeMonth(): number {
    return this._scheduleTimeMonth;
  }
  set scheduleTimeMonth(value: NumberInput) {
    this._scheduleTimeMonth = coerceNumberProperty(value, 0);
  }
  private _scheduleTimeMonth: number;

  @Input() dayChecks: DayCheck[];

  @Input() daySchedules: DaySchedule[];

  @Input() monthName: string;
}
