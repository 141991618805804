import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EmployeeContract } from '@qaroni-app/employee/types/employee-contract';
import { qaroniAnimations } from '@qaroni-shared/animations/qaroni-animations';

@Component({
  selector: 'qaroni-employee-profile-contract-preview',
  templateUrl: './employee-profile-contract-preview.component.html',
  styleUrls: ['./employee-profile-contract-preview.component.scss'],
  animations: [qaroniAnimations.enterFadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeProfileContractPreviewComponent {
  @Input() employeeContract: EmployeeContract;
}
