import { Pipe, PipeTransform } from '@angular/core';
import { Country } from '../types/country';

@Pipe({
  name: 'countryFilter',
})
export class CountryFilterPipe implements PipeTransform {
  transform(countries: Country[], countryName: string): Country[] {
    if (!countryName) return countries;

    return countries?.filter(c =>
      c.name.toLowerCase().includes(countryName.toLowerCase())
    );
  }
}
