import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum UnNameAvatarPreviewEnum {
  UNSUPERVISED = 'UNSUPERVISED',
  UNASSIGNED = 'UNASSIGNED',
}
export type UnNameAvtarPreviewType =
  | UnNameAvatarPreviewEnum.UNSUPERVISED
  | UnNameAvatarPreviewEnum.UNASSIGNED;

export const UnNameAvatarPreviewArray = [
  UnNameAvatarPreviewEnum.UNSUPERVISED,
  UnNameAvatarPreviewEnum.UNASSIGNED,
];

export const UnNameAvatarPreviewInfo: QaroniEnumInformation<UnNameAvatarPreviewEnum> =
  {
    [UnNameAvatarPreviewEnum.UNSUPERVISED]: {
      name: $localize`No Supervisado`,
    },
    [UnNameAvatarPreviewEnum.UNASSIGNED]: {
      name: $localize`No Asignado`,
    },
  };
