import { Pipe, PipeTransform } from '@angular/core';
import { CheckEnum, CheckInfo } from '@qaroni-app/check/types/check.enum';

@Pipe({
  name: 'employeeTypeCheck',
})
export class EmployeeTypeCheckPipe implements PipeTransform {
  transform(value: CheckEnum): string {
    if (value && Object.prototype.hasOwnProperty.call(CheckInfo, value)) {
      return CheckInfo[value].name;
    }

    return value;
  }
}
