import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum GenderEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export type GenderType = GenderEnum.FEMALE | GenderEnum.MALE;

export const GenderArray = [GenderEnum.FEMALE, GenderEnum.MALE];

export const GenderInfo: QaroniEnumInformation<GenderEnum> = {
  [GenderEnum.FEMALE]: {
    name: $localize`Mujer`,
  },
  [GenderEnum.MALE]: {
    name: $localize`Hombre`,
  },
};
