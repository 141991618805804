<button
  mat-button
  type="button"
  class="qaroni-close-icon"
  [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title i18n>Se ha producido un error en la comunicación.</div>
<mat-dialog-content>
  <p i18n>
    Es posible que el error sea algo temporal, por lo que puedes intentarlo de
    nuevo.
  </p>
  <p>
    <ng-container i18n
      >Si no se soluciona el problema contacta con nosotros.</ng-container
    >
    <mat-icon
      class="more-info-icon qaroni-va-middle ms-1"
      i18n-matTooltip
      matTooltip="Más información del error"
      *ngIf="!showErrorDetails"
      (click)="onShowErrorDetails()"
      >error_outline</mat-icon
    >
  </p>
  <mat-card
    *ngIf="showErrorDetails"
    class="mat-elevation-z0 qaroni-surface-border rounded px-3 py-2">
    <mat-card-header>
      <mat-card-title i18n>Información técnica del error</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p i18n>
        El siguiente código contiene información útil para nuestros técnicos a
        la hora de detectar y corregir el error.
      </p>
      <code>
        <div class="mb-2"><strong>Url:</strong> {{ error?.url }}</div>
        <div class="mb-2"><strong>Status:</strong> {{ error?.status }}</div>
        <div class="mb-2">
          <strong>StatusText:</strong> {{ error?.statusText }}
        </div>
        <div><strong>Message:</strong> {{ error?.message }}</div>
      </code>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="justify-content-end flex w-full items-center gap-5">
    <button
      mat-raised-button
      color="warn"
      type="button"
      class="m-1"
      [mat-dialog-close]="false"
      cdkFocusInitial>
      <mat-icon class="me-1">error</mat-icon>
      <ng-container i18n>Cerrar</ng-container>
    </button>
  </div>
</mat-dialog-actions>
