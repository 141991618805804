<div class="status-container relative inline-block">
  <div class="absolute bottom-0 end-0 block">
    <div
      class="container-circle flex items-center justify-center rounded-full p-1"
      [ngClass]="
        employee.shiftStatus
          ? 'container-circle-success'
          : 'container-circle-secondary'
      "
      [matTooltip]="employee?.shiftStatus ? 'Disponible' : 'Ausente'"
      matTooltipPosition="right">
      <div
        class="content-circle rounded-full"
        [ngClass]="
          employee.shiftStatus ? 'circle-success' : 'circle-white'
        "></div>
    </div>
  </div>
  <img
    class="icon-profile overflow-hidden"
    [src]="employee.imageUrl ?? ImagesConfig.male_avatar.url"
    [alt]="ImagesConfig.male_avatar.alt" />
</div>
