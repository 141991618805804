import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from '@qaroni-app/company/types/company';
import { ImagesConfig } from '@qaroni-core/config/app/images.config';

@Component({
  selector: 'qaroni-card-company',
  templateUrl: './card-company.component.html',
  styleUrls: ['./card-company.component.scss'],
})
export class CardCompanyComponent {
  @Input() company: Company;
  public ImagesConfig = ImagesConfig;

  constructor(private router: Router) {}

  hasLogoUrl(company: Company): boolean {
    return company?.logoUrl ? true : false;
  }

  goToDetailsCompany(company: Company) {
    if (company?.companyId) {
      this.router.navigate([`/companies/${company.companyId}/details`]);
    }
  }
}
