import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'qaroni-label-text-preview',
  templateUrl: './label-text-preview.component.html',
  styleUrls: ['./label-text-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelTextPreviewComponent {
  @Input() label: string;
  @Input() value: string | number;
}
