import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarConfig } from '@qaroni-core/config/snackbars/snackbar.config';

export const PaymentsSnackbars = {
  successReactiveCurrentPlan: {
    message: `Se ha reactivado tu subscripción correctamente.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  successCancelCurrentPlan: {
    message: `Tu suscripción actual ha sido cancelada correctamente.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  successAddSeats: {
    message: `Se añadieron asientos correctamente.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  successRemoveSeats: {
    message: `Se quitaron asientos correctamente.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  successSwitchConstantBilling: {
    message: `Se ha cambiado el período de facturación correctamente`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  successWeWillContactYou: {
    message: `Alguien del equipo de Workards se pondrá en contacto contigo en los siguientes días, por favor estar atento al correo electrónico`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureAddSeats: {
    message: `Ha ocurrido un error al agregar los asientos, por favor actualiza tu método de pago`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureWeWillContactYou: {
    message: `Ha ocurrido un error inesperado, por favor intenta nuevamente más tarde`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureYouDoNotHaveAPaymentMethod: {
    message: `No tienes configurado un método de pago, debes agregarlo para poder actualizar tu suscripción.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureDeclinedCreditCard: {
    message: `Hemos rechazado tu método de pago.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureSubscriptionIsActive: {
    message: `Tu suscripción está activa. No está permitido volver a pagar sin cambiar el número de asientos.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureRemoveSeatsIsNotValid: {
    message: `El número de asientos es menor al permitido.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureAddSeatsIsNotValid: {
    message: `El número de asientos debe ser mayor o igual a uno.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
};
