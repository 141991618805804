<mat-card class="qaroni-card-bg-background mat-elevation-z7 mt-4">
  <mat-card-content class="m-3 flex w-full flex-col gap-3">
    <div class="w-full">
      <h1 class="text-5xl font-bold" i18n>Plan workards</h1>
    </div>

    <div class="w-full">
      <qaroni-payments-level-container
        [plans]="plans"
        [isMonthly]="isMonthly"></qaroni-payments-level-container>
    </div>

    <div class="flex w-full flex-col gap-2">
      <h2 class="text-lg">Incluye:</h2>
      <ul>
        <li class="mb-2 text-lg font-light" i18n>
          Gestión de centros de trabajo
        </li>
        <li class="mb-2 text-lg font-light" i18n>Gestión de empleados</li>
        <li class="mb-2 text-lg font-light" i18n>Gestión de usuarios</li>
        <li class="mb-2 text-lg font-light" i18n>Gestión de proyectos</li>
        <li class="mb-2 text-lg font-light" i18n>Control de fichajes</li>
        <li class="mb-2 text-lg font-light" i18n>
          Control de turnos laborales
        </li>
        <li class="mb-2 text-lg font-light" i18n>Control de ausencias</li>
        <li class="mb-2 text-lg font-light" i18n>Gestor documental</li>
      </ul>
    </div>

    <ng-container *ngIf="!hideSubscribeButton">
      <div class="flex w-full justify-center text-center">
        <qaroni-payments-card-subscribe-button
          [isMonthly]="isMonthly"></qaroni-payments-card-subscribe-button>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
