import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'qaroni-input-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputLabelComponent {
  @Input() text: string;

  @Input()
  get center(): boolean {
    return this._center;
  }
  set center(value: BooleanInput) {
    this._center = coerceBooleanProperty(value);
  }
  private _center: boolean = false;
}
