import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Scoreboards } from '@qaroni-app/company/types/scoreboards';
import { qaroniAnimations } from '@qaroni-shared/animations/qaroni-animations';

@Component({
  selector: 'qaroni-scoreboard-detail-preview',
  templateUrl: './scoreboard-detail-preview.component.html',
  styleUrls: ['./scoreboard-detail-preview.component.scss'],
  animations: [qaroniAnimations.toggleHeight()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreboardDetailPreviewComponent {
  @Input() scoreboards: Scoreboards;
}
