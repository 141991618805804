import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Employee } from '@qaroni-app/employee/types/employee';

@Component({
  selector: 'qaroni-profile-status-progressbar',
  templateUrl: './profile-status-progressbar.component.html',
  styleUrls: ['./profile-status-progressbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileStatusProgressbarComponent {
  @Input() employee: Employee;
}
