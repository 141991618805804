import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PaymentMethod } from '@qaroni-app/payments/types/payment-method';

@Component({
  selector: 'qaroni-payment-method-preview',
  templateUrl: './payment-method-preview.component.html',
  styleUrls: ['./payment-method-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodPreviewComponent {
  @Input() paymentMethod: PaymentMethod;
}
