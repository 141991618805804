<ng-container *ngFor="let company of companies">
  <a
    mat-menu-item
    class="qaroni-pn-500-15"
    (click)="goToCompanyDetails(company)"
    (keypress)="goToCompanyDetails(company)"
    routerLinkActive="qaroni-text-primary">
    <mat-icon class="icon-menu">domain</mat-icon> {{ company?.name }}
  </a>
</ng-container>
