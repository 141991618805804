import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  QaroniDoughnutCenterTextChartPlugin,
  QaroniDoughnutChartTextConfig,
} from '@qaroni-shared/qaroni-plugins-charts/qaroni-doughnut-center-text-chart-plugin';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'qaroni-assessment-doughnut-chart-preview',
  templateUrl: './assessment-doughnut-chart-preview.component.html',
  styleUrls: ['./assessment-doughnut-chart-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentDoughnutChartPreviewComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild('doughnutChart') doughnutChart: ElementRef<HTMLCanvasElement>;
  @Input() data: ChartData<'doughnut'>;
  @Input() cutout = '80%';
  @Input() textSup: QaroniDoughnutChartTextConfig;
  @Input() textInf: QaroniDoughnutChartTextConfig;

  private chart: Chart<'doughnut'>;
  private doughnutCanvas: HTMLCanvasElement;

  constructor() {
    Chart.register(ChartDataLabels);
    Chart.register(...registerables);
  }

  ngAfterViewInit() {
    this.doughnutCanvas = this.doughnutChart.nativeElement;
    this.doughnutChartSetup();
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  private doughnutChartSetup() {
    if (this.chart) {
      this.chart.destroy();
    }

    if (!this.data) {
      const sampleData: ChartData<'doughnut'> = {
        labels: ['Sin iniciar', 'Iniciada', 'Enviado', 'Finalizado'],
        datasets: [
          {
            data: [25, 25, 25, 25],
            backgroundColor: ['#DEE2E6', '#FFBA66', '#5CB3F0', '#05998C'],
            borderColor: 'transparent',
          },
        ],
      };
      this.data = sampleData;
    }

    const options: ChartOptions<'doughnut'> = {
      cutout: this.cutout,

      layout: {
        autoPadding: true,
        padding: {
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        },
      },
      plugins: {
        datalabels: {
          formatter: (value: number) => {
            let percentage = '';
            if (value !== 0) {
              percentage = `${value.toPrecision(3)} %`;
            }
            return percentage;
          },
          textAlign: 'center',
          color: '#000000',
          anchor: 'end',
          align: 'end',
        },
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
            padding: 30,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
    };

    this.chart = new Chart(this.doughnutCanvas, {
      type: 'doughnut',
      data: this.data,
      options: options,
      plugins: [
        new QaroniDoughnutCenterTextChartPlugin(this.textSup, this.textInf),
      ],
    });
  }
}
