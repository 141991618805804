import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@qaroni-app/auth/types/user';
import { Company } from '@qaroni-app/company/types/company';
import { DashboardStorageService } from '@qaroni-app/dashboard/services/dashboard-storage.service';
import { WorkersService } from '@qaroni-app/workers/services/workers.service';

@Component({
  selector: 'qaroni-menu-company',
  templateUrl: './menu-company.component.html',
  styleUrls: ['./menu-company.component.scss'],
})
export class MenuCompanyComponent {
  @Input() user: User;
  @Input() companies: Company[];

  constructor(
    private router: Router,
    private workersService: WorkersService,
    private dasboardStorageService: DashboardStorageService
  ) {}

  goToCompanyDetails(company: Company) {
    this.dasboardStorageService.reset();
    const role = this.user?.roles?.find(r => r.companyId === company.companyId);

    this.router.navigate(
      this.workersService.checkCompanyForRedirect(
        company?.companyId,
        role?.role,
        role?.employeeId
      )
    );
  }
}
