import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'qaroni-empty-result-preview',
  templateUrl: './empty-result-preview.component.html',
  styleUrls: ['./empty-result-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyResultPreviewComponent {
  @Input() message: string;
}
