<div
  @enterFadeIn
  class="my-3"
  [ngClass]="{ 'mt-lg-0': first }"
  *ngIf="workplace">
  <div class="flex items-center gap-1">
    <div class="text-name">{{ workplace?.name }}</div>
    <div class="flex flex-1 flex-row items-center justify-end">
      <ng-container *ngTemplateOutlet="iconActions"></ng-container>
    </div>
  </div>

  <div class="text-address mt-1" *ngIf="workplace?.address">
    <span *ngIf="workplace?.address?.line1">{{
      workplace?.address?.line1
    }}</span
    ><span class="ms-1" *ngIf="workplace?.address?.line2">{{
      workplace?.address?.line2
    }}</span
    ><span class="ms-1" *ngIf="workplace?.address?.postalCode"
      >{{ workplace?.address?.postalCode }}.</span
    ><span class="ms-1" *ngIf="workplace?.address?.city"
      >{{ workplace?.address?.city }}.</span
    ><span class="ms-1" *ngIf="workplace?.address?.countryName"
      >{{ workplace?.address?.countryName }}.</span
    >
  </div>
</div>

<ng-template #iconActions>
  <mat-icon
    i18n-matTooltip
    matTooltipPosition="left"
    matTooltip="Editar centro de trabajo"
    class="qaroni-mat-icon-18 qaroni-link-on-surface mx-1"
    (click)="goToWorkplaceUpdate()"
    >edit</mat-icon
  >
</ng-template>
