import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum EmployeeJourneyScaleEnum {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export type EmployeeJourneyScaleType =
  | EmployeeJourneyScaleEnum.DAY
  | EmployeeJourneyScaleEnum.MONTH
  | EmployeeJourneyScaleEnum.WEEK;

export const EmployeeJourneyScaleArray = [
  EmployeeJourneyScaleEnum.DAY,
  EmployeeJourneyScaleEnum.MONTH,
  EmployeeJourneyScaleEnum.WEEK,
];

export const EmployeeJourneyScaleInfo: QaroniEnumInformation<EmployeeJourneyScaleEnum> =
  {
    [EmployeeJourneyScaleEnum.DAY]: {
      name: $localize`Día`,
    },
    [EmployeeJourneyScaleEnum.MONTH]: {
      name: $localize`Mes`,
    },
    [EmployeeJourneyScaleEnum.WEEK]: {
      name: $localize`Semanal`,
    },
  };
