import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { DataChart } from '@qaroni-core/types/chart/data-chart';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'qaroni-line-chart-preview',
  templateUrl: './line-chart-preview.component.html',
  styleUrls: ['./line-chart-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartPreviewComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  @Input() titleChart: string;
  @Input() dataChart: DataChart;

  @ViewChild('lineChart') lineChart: ElementRef<HTMLCanvasElement>;

  private chart: Chart;
  private canvas: HTMLCanvasElement;

  constructor() {
    Chart.register(...registerables);
  }

  ngOnChanges(): void {
    if (!this.dataChart?.datasets[0]?.data?.length) return;

    if (this.chart) this.setUpChart();
  }

  ngAfterViewInit(): void {
    if (!this.dataChart?.datasets[0]?.data?.length) return;

    this.canvas = this.lineChart.nativeElement;
    this.setUpChart();
  }

  ngOnDestroy(): void {
    if (!this.dataChart?.datasets[0]?.data?.length) return;

    this.chart.destroy();
  }

  get hasData(): boolean {
    return (
      this.dataChart?.datasets[0]?.data?.length > 0 &&
      this.dataChart?.labels?.length > 0
    );
  }

  private setUpChart = (): void => {
    if (this.chart) this.chart.destroy();

    this.chart = new Chart(this.canvas, {
      type: 'line',
      data: this.dataChart,
      options: {
        responsive: true,
        layout: {
          // padding: {
          //   top: 10,
          //   bottom: 10,
          // },
        },
        plugins: {
          legend: {
            position: 'top',
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            stacked: false,
            display: false,
          },
          y: {
            stacked: false,
            display: true,
            title: {
              display: true,
              text: $localize`Horas`,
            },
          },
        },
      },
    });
  };
}
