import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum AbsenceTypeEnum {
  HOLIDAYS = 'HOLIDAYS',
  SICKNESS = 'SICKNESS',
  OTHER = 'OTHER',
}

export type AbsenceTypeType =
  | AbsenceTypeEnum.HOLIDAYS
  | AbsenceTypeEnum.SICKNESS
  | AbsenceTypeEnum.OTHER;

export const AbsenceTypeArray = [
  AbsenceTypeEnum.HOLIDAYS,
  AbsenceTypeEnum.SICKNESS,
  AbsenceTypeEnum.OTHER,
];

export const AbsenceTypeInfo: QaroniEnumInformation<AbsenceTypeEnum> = {
  [AbsenceTypeEnum.HOLIDAYS]: {
    name: $localize`Vacaciones`,
  },

  [AbsenceTypeEnum.SICKNESS]: {
    name: $localize`Enfermedad`,
  },

  [AbsenceTypeEnum.OTHER]: {
    name: $localize`Otro`,
  },
};
