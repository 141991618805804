import { Pipe, PipeTransform } from '@angular/core';
import {
  EmployeeEducationEnum,
  EmployeeEducationInfo,
} from '@qaroni-app/employee/types/employee-education.enum';

@Pipe({
  name: 'employeeEducation',
})
export class EmployeeEducationPipe implements PipeTransform {
  transform(value: EmployeeEducationEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(EmployeeEducationInfo, value)
    ) {
      return EmployeeEducationInfo[value].name;
    }

    return value;
  }
}
