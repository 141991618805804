import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentsSnackbars } from '../types/payments-snackbars.config';

@Injectable({
  providedIn: 'root',
})
export class PaymentsSnackbarsService {
  constructor(private snackbar: MatSnackBar) {}

  public successReactiveCurrentPlan(): void {
    this.snackbar.open(
      PaymentsSnackbars.successReactiveCurrentPlan.message,
      PaymentsSnackbars.successReactiveCurrentPlan.closeBtn,
      PaymentsSnackbars.successReactiveCurrentPlan.config
    );
  }

  public successCancelCurrentPlan(): void {
    this.snackbar.open(
      PaymentsSnackbars.successCancelCurrentPlan.message,
      PaymentsSnackbars.successCancelCurrentPlan.closeBtn,
      PaymentsSnackbars.successCancelCurrentPlan.config
    );
  }

  public successAddSeats(): void {
    this.snackbar.open(
      PaymentsSnackbars.successAddSeats.message,
      PaymentsSnackbars.successAddSeats.closeBtn,
      PaymentsSnackbars.successAddSeats.config
    );
  }

  public successRemoveSeats(): void {
    this.snackbar.open(
      PaymentsSnackbars.successRemoveSeats.message,
      PaymentsSnackbars.successRemoveSeats.closeBtn,
      PaymentsSnackbars.successRemoveSeats.config
    );
  }

  public successSwitchConstantBilling(): void {
    this.snackbar.open(
      PaymentsSnackbars.successSwitchConstantBilling.message,
      PaymentsSnackbars.successSwitchConstantBilling.closeBtn,
      PaymentsSnackbars.successSwitchConstantBilling.config
    );
  }

  public successWeWillContactYou(): void {
    this.snackbar.open(
      PaymentsSnackbars.successWeWillContactYou.message,
      PaymentsSnackbars.successWeWillContactYou.closeBtn,
      PaymentsSnackbars.successWeWillContactYou.config
    );
  }

  public failureAddSeats(): void {
    this.snackbar.open(
      PaymentsSnackbars.failureAddSeats.message,
      PaymentsSnackbars.failureAddSeats.closeBtn,
      PaymentsSnackbars.failureAddSeats.config
    );
  }

  public failureWeWillContactYou(): void {
    this.snackbar.open(
      PaymentsSnackbars.failureWeWillContactYou.message,
      PaymentsSnackbars.failureWeWillContactYou.closeBtn,
      PaymentsSnackbars.failureWeWillContactYou.config
    );
  }

  public failureYouDoNotHaveAPaymentMethod(): void {
    this.snackbar.open(
      PaymentsSnackbars.failureYouDoNotHaveAPaymentMethod.message,
      PaymentsSnackbars.failureYouDoNotHaveAPaymentMethod.closeBtn,
      PaymentsSnackbars.failureYouDoNotHaveAPaymentMethod.config
    );
  }

  public failureDeclinedCreditCard(): void {
    this.snackbar.open(
      PaymentsSnackbars.failureDeclinedCreditCard.message,
      PaymentsSnackbars.failureDeclinedCreditCard.closeBtn,
      PaymentsSnackbars.failureDeclinedCreditCard.config
    );
  }

  public failureSubscriptionIsActive(): void {
    this.snackbar.open(
      PaymentsSnackbars.failureSubscriptionIsActive.message,
      PaymentsSnackbars.failureSubscriptionIsActive.closeBtn,
      PaymentsSnackbars.failureSubscriptionIsActive.config
    );
  }

  public failureRemoveSeatsIsNotValid(): void {
    this.snackbar.open(
      PaymentsSnackbars.failureRemoveSeatsIsNotValid.message,
      PaymentsSnackbars.failureRemoveSeatsIsNotValid.closeBtn,
      PaymentsSnackbars.failureRemoveSeatsIsNotValid.config
    );
  }

  public failureAddSeatsIsNotValid(): void {
    this.snackbar.open(
      PaymentsSnackbars.failureAddSeatsIsNotValid.message,
      PaymentsSnackbars.failureAddSeatsIsNotValid.closeBtn,
      PaymentsSnackbars.failureAddSeatsIsNotValid.config
    );
  }
}
