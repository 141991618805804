import { Component, Input } from '@angular/core';
import { User } from '@qaroni-app/auth/types/user';
import { Company } from '@qaroni-app/company/types/company';
import { ImagesConfig } from '@qaroni-core/config/app/images.config';

@Component({
  selector: 'qaroni-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() user: User;
  @Input() companies: Company[];

  get avatar() {
    return ImagesConfig.male_avatar;
  }

  public goToInvitations(): string[] {
    return [`/invitations`];
  }

  public goToUserProfile(): string[] {
    return [`/profile`];
  }

  public goToAddCompany(): string[] {
    return [`/companies/add`];
  }

  public goToLogout(): string[] {
    return [`/auth/logout`];
  }
}
