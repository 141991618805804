import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeFilesIcons',
})
export class TypeFilesIconsPipe implements PipeTransform {
  public typePdf = /(.pdf)$/i;
  public typeImage = /(.jpg|.jpeg|.png|.gif|.svg)$/i;
  public typeDoc = /(.docs|.xlsx|.pptx)$/i;

  transform(fileUrl: string): string {
    if (this.typeImage.exec(fileUrl)) {
      return 'photo_library';
    } else if (this.typePdf.exec(fileUrl)) {
      return 'picture_as_pdf';
    } else {
      return 'description';
    }
  }
}
