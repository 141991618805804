import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CountriesModule } from '@qaroni-app/countries/countries.module';
import { PipesModule } from '@qaroni-shared/pipes/pipes.module';
import { QaroniAnimationModule } from '@qaroni-shared/qaroni-animation/qaroni-animation.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AbsenceConfirmationPreviewComponent } from './absence-confirmation-preview/absence-confirmation-preview.component';
import { AbsenceEmployeeStatusPreviewComponent } from './absence-employee-status-preview/absence-employee-status-preview.component';
import { AbsenceStatusPreviewComponent } from './absence-status-preview/absence-status-preview.component';
import { AbsenceTypePreviewComponent } from './absence-type-preview/absence-type-preview.component';
import { AddressStringComponent } from './address-string/address-string.component';
import { AdvancedFiltersButtonComponent } from './advanced-filters-button/advanced-filters-button.component';
import { AdvancedFiltersContainerComponent } from './advanced-filters-container/advanced-filters-container.component';
import { AssessmentDoughnutChartPreviewComponent } from './assessment-doughnut-chart-preview/assessment-doughnut-chart-preview.component';
import { AssessmentGaugeChartPreviewComponent } from './assessment-gauge-chart-preview/assessment-gauge-chart-preview.component';
import { AssessmentProgressBarPreviewComponent } from './assessment-progress-bar-preview/assessment-progress-bar-preview.component';
import { BoardSectionAvatarPreviewComponent } from './board-section-avatar-preview/board-section-avatar-preview.component';
import { BoardSectionLoadingComponent } from './board-section-loading/board-section-loading.component';
import { CardCompanyComponent } from './card-company/card-company.component';
import { CardWorkplaceComponent } from './card-workplace/card-workplace.component';
import { CheckDetailsCalendarComponent } from './check-details-calendar/check-details-calendar.component';
import { CheckDetailsHoursComponent } from './check-details-hours/check-details-hours.component';
import { CheckDetailsLeadComponent } from './check-details-lead/check-details-lead.component';
import { CheckCommentDialogComponent } from './dialogs/check-comment-dialog/check-comment-dialog.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { ErrorsHttpDialogComponent } from './dialogs/errors-http-dialog/errors-http-dialog.component';
import { ForgotPasswordDialogComponent } from './dialogs/forgot-password-dialog/forgot-password-dialog.component';
import { SendInvitationDialogComponent } from './dialogs/send-invitation-dialog/send-invitation-dialog.component';
import { EmployeeDisabilitiesTableComponent } from './employee-disabilities-table/employee-disabilities-table.component';
import { EmployeeProfileAddressPreviewComponent } from './employee-profile-address-preview/employee-profile-address-preview.component';
import { EmployeeProfileContractPreviewComponent } from './employee-profile-contract-preview/employee-profile-contract-preview.component';
import { EmptyResultPreviewComponent } from './empty-result-preview/empty-result-preview.component';
import { GrecaptchaComponent } from './grecaptcha/grecaptcha.component';
import { HoursBalanceChartPreviewComponent } from './hours-balance-chart-preview/hours-balance-chart-preview.component';
import { ImputationHoursChartPreviewComponent } from './imputation-hours-chart-preview/imputation-hours-chart-preview.component';
import { InputDateComponent } from './input-date/input-date.component';
import { InputErrorMessagesComponent } from './input-error-messages/input-error-messages.component';
import { InputLabelComponent } from './input-label/input-label.component';
import { LabelAvatarPreviewComponent } from './label-avatar-preview/label-avatar-preview.component';
import { LabelEmailPreviewComponent } from './label-email-preview/label-email-preview.component';
import { LabelPhonePreviewComponent } from './label-phone-preview/label-phone-preview.component';
import { LabelProjectNamePreviewComponent } from './label-project-name-preview/label-project-name-preview.component';
import { LabelTextPreviewComponent } from './label-text-preview/label-text-preview.component';
import { LineChartPreviewComponent } from './line-chart-preview/line-chart-preview.component';
import { LoadingComponent } from './loading/loading.component';
import { MenuCompanyComponent } from './menu-company/menu-company.component';
import { MenuComponent } from './menu/menu.component';
import { PaymentMethodPreviewComponent } from './payment-method-preview/payment-method-preview.component';
import { PaymentsCardInfoComponent } from './payments-card-info/payments-card-info.component';
import { PaymentsCardSubscribeButtonComponent } from './payments-card-subscribe-button/payments-card-subscribe-button.component';
import { PaymentsCardComponent } from './payments-card/payments-card.component';
import { PaymentsLevelContainerComponent } from './payments-level-container/payments-level-container.component';
import { PaymentsLevelLineComponent } from './payments-level-line/payments-level-line.component';
import { PaymentsLevelComponent } from './payments-level/payments-level.component';
import { PieChartPreviewComponent } from './pie-chart-preview/pie-chart-preview.component';
import { ProfileStatusProgressbarComponent } from './profile-status-progressbar/profile-status-progressbar.component';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { ProjectPreviewStatusComponent } from './project-preview-status/project-preview-status.component';
import { ProjectsTasksTagsComponent } from './projects-tasks-tags/projects-tasks-tags.component';
import { RangeDatepickerComponent } from './range-datepicker/range-datepicker.component';
import { ScoreboardDetailPreviewComponent } from './scoreboard-detail-preview/scoreboard-detail-preview.component';
import { ShiftStatusPreviewComponent } from './shift-status-preview/shift-status-preview.component';
import { StatusPreviewComponent } from './status-preview/status-preview.component';
import { TaskPreviewStatusComponent } from './task-preview-status/task-preview-status.component';
import { TinymceTextareaComponent } from './tinymce-textarea/tinymce-textarea.component';
import { TitleButtonComponent } from './title-button/title-button.component';
import { TitleDashboardComponent } from './title-dashboard/title-dashboard.component';
import { TitleMdComponent } from './title-md/title-md.component';
import { TitleSubtitleComponent } from './title-subtitle/title-subtitle.component';
import { TitleComponent } from './title/title.component';
import { WorkplacesMapComponent } from './workplaces-map/workplaces-map.component';
import { YearDatepickerComponent } from './year-datepicker/year-datepicker.component';
import { YearMonthDatepickerComponent } from './year-month-datepicker/year-month-datepicker.component';

@NgModule({
  declarations: [
    AbsenceConfirmationPreviewComponent,
    AbsenceEmployeeStatusPreviewComponent,
    AbsenceStatusPreviewComponent,
    AbsenceTypePreviewComponent,
    AddressStringComponent,
    AdvancedFiltersButtonComponent,
    AdvancedFiltersContainerComponent,
    AssessmentDoughnutChartPreviewComponent,
    AssessmentGaugeChartPreviewComponent,
    AssessmentProgressBarPreviewComponent,
    BoardSectionAvatarPreviewComponent,
    BoardSectionLoadingComponent,
    CardCompanyComponent,
    CardWorkplaceComponent,
    CheckCommentDialogComponent,
    CheckDetailsCalendarComponent,
    CheckDetailsHoursComponent,
    CheckDetailsLeadComponent,
    ConfirmationDialogComponent,
    EmployeeDisabilitiesTableComponent,
    EmployeeProfileAddressPreviewComponent,
    EmployeeProfileContractPreviewComponent,
    EmptyResultPreviewComponent,
    ErrorsHttpDialogComponent,
    ForgotPasswordDialogComponent,
    GrecaptchaComponent,
    HoursBalanceChartPreviewComponent,
    ImputationHoursChartPreviewComponent,
    InputDateComponent,
    InputErrorMessagesComponent,
    InputLabelComponent,
    LabelAvatarPreviewComponent,
    LabelEmailPreviewComponent,
    LabelPhonePreviewComponent,
    LabelProjectNamePreviewComponent,
    LabelTextPreviewComponent,
    LineChartPreviewComponent,
    LoadingComponent,
    MenuCompanyComponent,
    MenuComponent,
    PaymentMethodPreviewComponent,
    PaymentsCardComponent,
    PaymentsCardInfoComponent,
    PaymentsCardSubscribeButtonComponent,
    PaymentsLevelComponent,
    PaymentsLevelContainerComponent,
    PaymentsLevelLineComponent,
    PieChartPreviewComponent,
    ProfileStatusProgressbarComponent,
    ProgressSpinnerComponent,
    ProjectPreviewStatusComponent,
    ProjectsTasksTagsComponent,
    RangeDatepickerComponent,
    ScoreboardDetailPreviewComponent,
    SendInvitationDialogComponent,
    ShiftStatusPreviewComponent,
    StatusPreviewComponent,
    TaskPreviewStatusComponent,
    TinymceTextareaComponent,
    TitleButtonComponent,
    TitleComponent,
    TitleDashboardComponent,
    TitleMdComponent,
    TitleSubtitleComponent,
    WorkplacesMapComponent,
    YearDatepickerComponent,
    YearMonthDatepickerComponent,
  ],
  imports: [
    CommonModule,
    CountriesModule,
    EditorModule,
    FormsModule,
    GoogleMapsModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    PipesModule,
    QaroniAnimationModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    AbsenceConfirmationPreviewComponent,
    AbsenceEmployeeStatusPreviewComponent,
    AbsenceStatusPreviewComponent,
    AbsenceTypePreviewComponent,
    AddressStringComponent,
    AdvancedFiltersButtonComponent,
    AdvancedFiltersContainerComponent,
    AssessmentDoughnutChartPreviewComponent,
    AssessmentGaugeChartPreviewComponent,
    AssessmentProgressBarPreviewComponent,
    BoardSectionAvatarPreviewComponent,
    BoardSectionLoadingComponent,
    CardCompanyComponent,
    CardWorkplaceComponent,
    CheckDetailsCalendarComponent,
    CheckDetailsHoursComponent,
    CheckDetailsLeadComponent,
    EmployeeDisabilitiesTableComponent,
    EmployeeProfileAddressPreviewComponent,
    EmployeeProfileContractPreviewComponent,
    EmptyResultPreviewComponent,
    ForgotPasswordDialogComponent,
    GrecaptchaComponent,
    ImputationHoursChartPreviewComponent,
    InputDateComponent,
    InputErrorMessagesComponent,
    InputLabelComponent,
    LabelAvatarPreviewComponent,
    LabelEmailPreviewComponent,
    LabelPhonePreviewComponent,
    LabelProjectNamePreviewComponent,
    LabelTextPreviewComponent,
    LineChartPreviewComponent,
    LoadingComponent,
    MenuCompanyComponent,
    MenuComponent,
    PaymentMethodPreviewComponent,
    PaymentsCardComponent,
    PaymentsCardInfoComponent,
    PaymentsLevelContainerComponent,
    PieChartPreviewComponent,
    ProfileStatusProgressbarComponent,
    ProgressSpinnerComponent,
    ProjectPreviewStatusComponent,
    ProjectsTasksTagsComponent,
    RangeDatepickerComponent,
    ScoreboardDetailPreviewComponent,
    SendInvitationDialogComponent,
    ShiftStatusPreviewComponent,
    StatusPreviewComponent,
    TaskPreviewStatusComponent,
    TinymceTextareaComponent,
    TitleButtonComponent,
    TitleComponent,
    TitleDashboardComponent,
    TitleMdComponent,
    TitleSubtitleComponent,
    WorkplacesMapComponent,
    YearDatepickerComponent,
    YearMonthDatepickerComponent,
  ],
})
export class ComponentsModule {}
