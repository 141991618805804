import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum TicketPriorityEnum {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  URGENT = 'URGENT',
}

export const TicketPriorityArray = [
  TicketPriorityEnum.LOW,
  TicketPriorityEnum.MEDIUM,
  TicketPriorityEnum.HIGH,
  TicketPriorityEnum.URGENT,
];

export const TicketPriorityInfo: QaroniEnumInformation<TicketPriorityEnum> = {
  [TicketPriorityEnum.LOW]: {
    name: $localize`Baja`,
  },

  [TicketPriorityEnum.MEDIUM]: {
    name: $localize`Media`,
  },

  [TicketPriorityEnum.HIGH]: {
    name: $localize`Alta`,
  },

  [TicketPriorityEnum.URGENT]: {
    name: $localize`Urgente`,
  },
};
