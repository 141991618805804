import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbsenceTypeEnum } from '@qaroni-app/absences/types/absence-type.enum';

@Component({
  selector: 'qaroni-absence-type-preview',
  templateUrl: './absence-type-preview.component.html',
  styleUrls: ['./absence-type-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbsenceTypePreviewComponent {
  @Input() type: AbsenceTypeEnum;

  get typeEnum(): typeof AbsenceTypeEnum {
    return AbsenceTypeEnum;
  }
}
