import { QaroniEnumInformation } from '../qaroni-enum-information/qaroni-enum-information';

export enum PaymentMethodEnum {
  EUR = 'EUR',
  USD = 'USD',
  CRYPTO = 'CRYPTO',
  PAYPAL = 'PAYPAL',
}

export type PaymentMethodType =
  | PaymentMethodEnum.EUR
  | PaymentMethodEnum.USD
  | PaymentMethodEnum.CRYPTO
  | PaymentMethodEnum.PAYPAL;

export const PaymentMethodArray = [
  PaymentMethodEnum.EUR,
  PaymentMethodEnum.USD,
  PaymentMethodEnum.CRYPTO,
  PaymentMethodEnum.PAYPAL,
];

export const PaymentMethodInfo: QaroniEnumInformation<PaymentMethodEnum> = {
  [PaymentMethodEnum.EUR]: {
    name: $localize`Euro`,
  },
  [PaymentMethodEnum.USD]: {
    name: $localize`Dólar`,
  },
  [PaymentMethodEnum.CRYPTO]: {
    name: $localize`Criptomoneda`,
  },
  [PaymentMethodEnum.PAYPAL]: {
    name: $localize`Paypal`,
  },
};
