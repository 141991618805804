<form [formGroup]="dateRangeForm">
  <mat-form-field appearance="outline" class="ff-no-bottom-space moz-suffix">
    <mat-label i18n>Filtrar por rango de fechas</mat-label>
    <mat-date-range-input
      [rangePicker]="picker"
      [min]="minDate"
      [max]="maxDate">
      <input
        matStartDate
        formControlName="dateFrom"
        placeholder="Fecha de inicio"
        i18n-placeholder />
      <input
        matEndDate
        formControlName="dateTo"
        placeholder="Fecha de fin"
        i18n-placeholder />
    </mat-date-range-input>
    <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
    <mat-hint align="start" class="truncate">DD/MM/YYYY - DD/MM/YYYY</mat-hint>
    <button
      mat-icon-button
      matSuffix
      type="button"
      i18n-aria-label
      aria-label="Limpiar"
      *ngIf="hasDates"
      (click)="onClear()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-date-range-picker
      #picker
      (closed)="closeDatepicker()"
      disabled="false"></mat-date-range-picker>
  </mat-form-field>
</form>
