import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ProgressBarService } from '@qaroni-core/services/app/progress-bar/progress-bar.service';
import { addMonths, parseISO } from 'date-fns';
import { Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { YearMonthDatepickerForm } from './year-month-datepicker-form';

@Component({
  selector: 'qaroni-year-month-datepicker',
  templateUrl: './year-month-datepicker.component.html',
  styleUrls: ['./year-month-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearMonthDatepickerComponent
  extends YearMonthDatepickerForm
  implements OnInit, OnDestroy
{
  @Input()
  get year(): number {
    return this._year;
  }
  set year(value: NumberInput) {
    this._year = coerceNumberProperty(value, 0);
  }
  private _year: number;

  @Input() monthName: string;

  @Output() date: EventEmitter<Date> = new EventEmitter<Date>();

  public isLoading$: Observable<boolean> = this.progressBar
    .getProgressBar$()
    .pipe(shareReplay(1));

  private queryParamMap$: Observable<ParamMap> = this.route.queryParamMap.pipe(
    shareReplay(1)
  );

  private subs: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private progressBar: ProgressBarService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.add(this.queryParamMap$.subscribe(this.getQueryParamMap));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get showDatepicker(): boolean {
    return this.monthName && this.year ? true : false;
  }

  setMonthAndYear(
    normalizedMonthAndYear: Date,
    datepicker: MatDatepicker<Date>
  ) {
    this.dateControl.setValue(normalizedMonthAndYear);
    this.date.emit(this.dateControl.value);
    datepicker.close();
  }

  addMonth() {
    this.dateControl.setValue(addMonths(parseISO(this.dateControl.value), 1));
    this.date.emit(this.dateControl.value);
  }

  subtractMonth() {
    this.dateControl.setValue(addMonths(parseISO(this.dateControl.value), -1));
    this.date.emit(this.dateControl.value);
  }
}
