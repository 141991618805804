import { Pipe, PipeTransform } from '@angular/core';
import {
  TicketPriorityEnum,
  TicketPriorityInfo,
} from '@qaroni-app/ticket/types/ticket-priority.enum';

@Pipe({
  name: 'ticketPriority',
})
export class TicketPriorityPipe implements PipeTransform {
  transform(value: TicketPriorityEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(TicketPriorityInfo, value)
    ) {
      return TicketPriorityInfo[value].name;
    }
    return value;
  }
}
