import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OAuthSnackbars } from '../types/o-auth-snackbars.config';

@Injectable({
  providedIn: 'root',
})
export class OAuthSnackbarsService {
  constructor(private snackbar: MatSnackBar) {}

  public failureRegisterEmailExists(): void {
    this.snackbar.open(
      OAuthSnackbars.failureRegisterEmailExists.message,
      OAuthSnackbars.failureRegisterEmailExists.closeBtn,
      OAuthSnackbars.failureRegisterEmailExists.config
    );
  }

  public failureRegisterEmailIsNotValid(): void {
    this.snackbar.open(
      OAuthSnackbars.failureRegisterEmailIsNotValid.message,
      OAuthSnackbars.failureRegisterEmailIsNotValid.closeBtn,
      OAuthSnackbars.failureRegisterEmailIsNotValid.config
    );
  }

  public failureRegisterUserIsActive(): void {
    this.snackbar.open(
      OAuthSnackbars.failureRegisterUserIsActive.message,
      OAuthSnackbars.failureRegisterUserIsActive.closeBtn,
      OAuthSnackbars.failureRegisterUserIsActive.config
    );
  }

  public failureRegisterUserIsCancelled(): void {
    this.snackbar.open(
      OAuthSnackbars.failureRegisterUserIsCancelled.message,
      OAuthSnackbars.failureRegisterUserIsCancelled.closeBtn,
      OAuthSnackbars.failureRegisterUserIsCancelled.config
    );
  }

  public failureRegisterUserIsInactive(): void {
    this.snackbar.open(
      OAuthSnackbars.failureRegisterUserIsInactive.message,
      OAuthSnackbars.failureRegisterUserIsInactive.closeBtn,
      OAuthSnackbars.failureRegisterUserIsInactive.config
    );
  }

  public failureRegisterUserIsBlocked(): void {
    this.snackbar.open(
      OAuthSnackbars.failureRegisterUserIsBlocked.message,
      OAuthSnackbars.failureRegisterUserIsBlocked.closeBtn,
      OAuthSnackbars.failureRegisterUserIsBlocked.config
    );
  }

  public failureValidateAccountIsCancelled(): void {
    this.snackbar.open(
      OAuthSnackbars.failureValidateAccountIsCancelled.message,
      OAuthSnackbars.failureValidateAccountIsCancelled.closeBtn,
      OAuthSnackbars.failureValidateAccountIsCancelled.config
    );
  }

  public failureValidateAccountIsInactive(): void {
    this.snackbar.open(
      OAuthSnackbars.failureValidateAccountIsInactive.message,
      OAuthSnackbars.failureValidateAccountIsInactive.closeBtn,
      OAuthSnackbars.failureValidateAccountIsInactive.config
    );
  }

  public failureValidateAccountIsActive(): void {
    this.snackbar.open(
      OAuthSnackbars.failureValidateAccountIsActive.message,
      OAuthSnackbars.failureValidateAccountIsActive.closeBtn,
      OAuthSnackbars.failureValidateAccountIsActive.config
    );
  }

  public failureLoginTheAccountHasNotExists(): void {
    this.snackbar.open(
      OAuthSnackbars.failureLoginTheAccountHasNotExists.message,
      OAuthSnackbars.failureLoginTheAccountHasNotExists.closeBtn,
      OAuthSnackbars.failureLoginTheAccountHasNotExists.config
    );
  }

  public failureLoginInvalidCredentials(): void {
    this.snackbar.open(
      OAuthSnackbars.failureLoginInvalidCredentials.message,
      OAuthSnackbars.failureLoginInvalidCredentials.closeBtn,
      OAuthSnackbars.failureLoginInvalidCredentials.config
    );
  }

  public failureLoginAccountIsCancelled(): void {
    this.snackbar.open(
      OAuthSnackbars.failureLoginAccountIsCancelled.message,
      OAuthSnackbars.failureLoginAccountIsCancelled.closeBtn,
      OAuthSnackbars.failureLoginAccountIsCancelled.config
    );
  }

  public failureLoginAccountIsInactive(): void {
    this.snackbar.open(
      OAuthSnackbars.failureLoginAccountIsInactive.message,
      OAuthSnackbars.failureLoginAccountIsInactive.closeBtn,
      OAuthSnackbars.failureLoginAccountIsInactive.config
    );
  }

  public failureLoginAccountIsBlocked(): void {
    this.snackbar.open(
      OAuthSnackbars.failureLoginAccountIsBlocked.message,
      OAuthSnackbars.failureLoginAccountIsBlocked.closeBtn,
      OAuthSnackbars.failureLoginAccountIsBlocked.config
    );
  }

  public failureLoginValidateEmailsButIsNotValidates(): void {
    this.snackbar.open(
      OAuthSnackbars.failureLoginValidateEmailsButIsNotValidates.message,
      OAuthSnackbars.failureLoginValidateEmailsButIsNotValidates.closeBtn,
      OAuthSnackbars.failureLoginValidateEmailsButIsNotValidates.config
    );
  }

  public failureLoginTooManyFailedAttempts(): void {
    this.snackbar.open(
      OAuthSnackbars.failureLoginTooManyFailedAttempts.message,
      OAuthSnackbars.failureLoginTooManyFailedAttempts.closeBtn,
      OAuthSnackbars.failureLoginTooManyFailedAttempts.config
    );
  }

  public failedForgotTheAccountHasNotExists(): void {
    this.snackbar.open(
      OAuthSnackbars.failedForgotTheAccountHasNotExists.message,
      OAuthSnackbars.failedForgotTheAccountHasNotExists.closeBtn,
      OAuthSnackbars.failedForgotTheAccountHasNotExists.config
    );
  }

  public failedForgotTheAccountIsCreated(): void {
    this.snackbar.open(
      OAuthSnackbars.failedForgotTheAccountIsCreated.message,
      OAuthSnackbars.failedForgotTheAccountIsCreated.closeBtn,
      OAuthSnackbars.failedForgotTheAccountIsCreated.config
    );
  }

  public failedForgotTheAccountIsInactive(): void {
    this.snackbar.open(
      OAuthSnackbars.failedForgotTheAccountIsInactive.message,
      OAuthSnackbars.failedForgotTheAccountIsInactive.closeBtn,
      OAuthSnackbars.failedForgotTheAccountIsInactive.config
    );
  }

  public failedForgotTheAccountIsCancelled(): void {
    this.snackbar.open(
      OAuthSnackbars.failedForgotTheAccountIsCancelled.message,
      OAuthSnackbars.failedForgotTheAccountIsCancelled.closeBtn,
      OAuthSnackbars.failedForgotTheAccountIsCancelled.config
    );
  }

  public failureResetPasswordAccountIsNotActive(): void {
    this.snackbar.open(
      OAuthSnackbars.failureResetPasswordAccountIsNotActive.message,
      OAuthSnackbars.failureResetPasswordAccountIsNotActive.closeBtn,
      OAuthSnackbars.failureResetPasswordAccountIsNotActive.config
    );
  }

  public failureEmailInvalid(): void {
    this.snackbar.open(
      OAuthSnackbars.failureEmailInvalid.message,
      OAuthSnackbars.failureEmailInvalid.closeBtn,
      OAuthSnackbars.failureEmailInvalid.config
    );
  }

  public failureSendMagicLinkAccountIsCreated(): void {
    this.snackbar.open(
      OAuthSnackbars.failureSendMagicLinkAccountIsCreated.message,
      OAuthSnackbars.failureSendMagicLinkAccountIsCreated.closeBtn,
      OAuthSnackbars.failureSendMagicLinkAccountIsCreated.config
    );
  }

  public failureSendMagicLinkAccountIsInactive(): void {
    this.snackbar.open(
      OAuthSnackbars.failureSendMagicLinkAccountIsInactive.message,
      OAuthSnackbars.failureSendMagicLinkAccountIsInactive.closeBtn,
      OAuthSnackbars.failureSendMagicLinkAccountIsInactive.config
    );
  }

  public failureSendMagicLinkAccountIsBlocked(): void {
    this.snackbar.open(
      OAuthSnackbars.failureSendMagicLinkAccountIsBlocked.message,
      OAuthSnackbars.failureSendMagicLinkAccountIsBlocked.closeBtn,
      OAuthSnackbars.failureSendMagicLinkAccountIsBlocked.config
    );
  }

  public failureExpiredSession(): void {
    this.snackbar.open(
      OAuthSnackbars.failureExpiredSession.message,
      OAuthSnackbars.failureExpiredSession.closeBtn,
      OAuthSnackbars.failureExpiredSession.config
    );
  }

  public failureLowRecaptchaScore(): void {
    this.snackbar.open(
      OAuthSnackbars.failureLowRecaptchaScore.message,
      OAuthSnackbars.failureLowRecaptchaScore.closeBtn,
      OAuthSnackbars.failureLowRecaptchaScore.config
    );
  }
}
