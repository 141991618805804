import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Employee } from '@qaroni-app/employee/types/employee';
import { EmployeeAddress } from '@qaroni-app/employee/types/employee-address';
import { ApiQWorkardsEnv } from '@qaroni-core/config/apis/api-qworkards/api-qworkards.config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PanelProfileHttpService {
  constructor(private http: HttpClient) {}

  public getEmployee$(employeeId: number): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/employees/${employeeId}`;

    return this.http.get(url, { observe: 'response' });
  }

  public updateProfile$(
    employeeId: number,
    employee: Employee
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/employees/${employeeId}`;

    return this.http.patch(url, employee, { observe: 'response' });
  }

  public updateEmployeeAddress$(
    employeeId: number,
    employeeAddress: EmployeeAddress
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/employees/${employeeId}/addresses`;

    return this.http.patch(url, employeeAddress, { observe: 'response' });
  }

  public getEmployeeDisabilities$(
    employeeId: number,
    params?: Params
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/employees/${employeeId}/disabilities`;

    return this.http.get(url, { observe: 'response', params });
  }

  public getEmployeeHistories$(
    employeeId: number,
    params?: Params
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/employees/${employeeId}/histories`;

    return this.http.get(url, { observe: 'response', params });
  }
}
