<ng-container [ngSwitch]="status">
  <ng-container *ngSwitchCase="statusEnum.CREATED">
    <div class="absence-status status-created-color" i18n>Creado</div>
  </ng-container>

  <ng-container *ngSwitchCase="statusEnum.INACTIVE">
    <div class="absence-status status-inactive-color" i18n>Inactivo</div>
  </ng-container>

  <ng-container *ngSwitchCase="statusEnum.ACTIVE">
    <div class="absence-status status-active-color" i18n>Activo</div>
  </ng-container>

  <ng-container *ngSwitchDefault></ng-container>
</ng-container>
