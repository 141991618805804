import { Pipe, PipeTransform } from '@angular/core';
import {
  EmployeeShiftStatusSelectorEnum,
  EmployeeShiftStatusSelectorInfo,
} from '@qaroni-app/employee/types/employee-shift-status.enum';

@Pipe({
  name: 'employeeShiftStatusSelector',
})
export class EmployeeShiftStatusSelectorPipe implements PipeTransform {
  transform(value: EmployeeShiftStatusSelectorEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(
        EmployeeShiftStatusSelectorInfo,
        value
      )
    ) {
      return EmployeeShiftStatusSelectorInfo[value].name;
    }

    return value;
  }
}
