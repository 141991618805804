import { Pipe, PipeTransform } from '@angular/core';
import {
  AssessmentEmployeeStatusEnum,
  AssessmentEmployeeStatusInfo,
} from '@qaroni-app/assessment/types/assessment-employee-status.enum';

@Pipe({
  name: 'assessmentEmployeeStatus',
})
export class AssessmentEmployeeStatusPipe implements PipeTransform {
  transform(value: AssessmentEmployeeStatusEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(AssessmentEmployeeStatusInfo, value)
    ) {
      return AssessmentEmployeeStatusInfo[value].name;
    }

    return value;
  }
}
