import { Component, Input, OnChanges } from '@angular/core';
import {
  AvatarColor,
  AVATAR_COLOR_LIST,
} from '@qaroni-core/types/colors/colors.enum';

@Component({
  selector: 'qaroni-label-avatar-preview',
  templateUrl: './label-avatar-preview.component.html',
  styleUrls: ['./label-avatar-preview.component.scss'],
})
export class LabelAvatarPreviewComponent implements OnChanges {
  @Input() displayName: string = '';
  @Input() bgColor: string = '';
  colors = AVATAR_COLOR_LIST;
  bgColorClass = 'qaroni-bg-primary50';
  colorClass = 'qaroni-text-background';

  validChars = 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ1234567890 ';

  ngOnChanges(): void {
    this.processData();
  }

  processData(): void {
    this.getColorAndBgColorClass();
  }

  get getInitials(): string {
    if (!this.displayName) {
      return '';
    }
    const re = new RegExp(`[^${this.validChars}]`);
    const name = this.displayName.replace(re, '');
    const words = name.split(' ').splice(0, 2);
    return words
      .map(word => word[0])
      .join('')
      .toUpperCase();
  }

  getColorAndBgColorClass(): void {
    if (this.colors?.length > 0) {
      if (this.bgColor) {
        const colorElement = this.colors.find(e => e.key === this.bgColor);
        this.getColorsFromColorElement(colorElement);
      } else {
        let top = this.colors.length - 1;
        let rand = Math.random() * top;
        rand = Math.floor(rand);
        const colorElement = this.colors[rand];
        this.getColorsFromColorElement(colorElement);
      }
    }
  }

  getColorsFromColorElement(colorElement: AvatarColor): void {
    if (colorElement?.backgroundColor && colorElement.color) {
      this.bgColorClass = colorElement?.backgroundColor;
      this.colorClass = colorElement.color;
    }
  }
}
