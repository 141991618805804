import { Pipe, PipeTransform } from '@angular/core';
import {
  UserStatusEnum,
  UserStatusInfo,
} from '@qaroni-app/auth/types/user-status.enum';

@Pipe({
  name: 'userStatus',
})
export class UserStatusPipe implements PipeTransform {
  transform(value: UserStatusEnum): string {
    if (value && Object.prototype.hasOwnProperty.call(UserStatusInfo, value)) {
      return UserStatusInfo[value].name;
    }

    return value;
  }
}
