<button
  [routerLink]="routerLinkUrl"
  class="max-w-24 truncate rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset hover:brightness-95"
  [ngStyle]="{
    'background-color': defaultTagColor,
    color: defaultTagTextColor,
  }"
  [ngClass]="ngClassRingColors"
  (click)="onTagClick(); $event.stopPropagation()">
  {{ tagName }}
</button>
