<mat-card class="card-company mb-4" (click)="goToDetailsCompany(company)">
  <mat-card-content>
    <div class="flex w-full items-center justify-between">
      <div>
        <img
          *ngIf="hasLogoUrl(company)"
          class="logo-company mx-auto block aspect-auto"
          [src]="company?.logoUrl"
          alt="Logo Company" />

        <img
          *ngIf="!hasLogoUrl(company)"
          class="logo-company mx-auto block aspect-auto"
          [src]="ImagesConfig?.company?.url"
          [alt]="ImagesConfig?.company?.alt" />
      </div>

      <div class="flex-1">
        <div class="company-name mb-2">
          {{ company?.name }}
        </div>

        <div class="company-address">
          Calle Argentina y Avenidas Las Américas 240209 Salinas. Santa Elena
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
