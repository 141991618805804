import { Pipe, PipeTransform } from '@angular/core';
import { GenderEnum, GenderInfo } from '@qaroni-app/employee/types/gender.enum';

@Pipe({
  name: 'employeeGender',
})
export class EmployeeGenderPipe implements PipeTransform {
  transform(value: GenderEnum): string {
    if (value && Object.prototype.hasOwnProperty.call(GenderInfo, value)) {
      return GenderInfo[value].name;
    }
    return value;
  }
}
