import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum EmployeeStatusEnum {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
}

export type EmployeeStatusType =
  | EmployeeStatusEnum.CREATED
  | EmployeeStatusEnum.ACTIVE
  | EmployeeStatusEnum.INACTIVE
  | EmployeeStatusEnum.PENDING
  | EmployeeStatusEnum.CANCELLED;

export const EmployeeStatusArray = [
  EmployeeStatusEnum.CREATED,
  EmployeeStatusEnum.ACTIVE,
  EmployeeStatusEnum.INACTIVE,
  EmployeeStatusEnum.PENDING,
  EmployeeStatusEnum.CANCELLED,
];

export const EmployeeStatusInfo: QaroniEnumInformation<EmployeeStatusEnum> = {
  [EmployeeStatusEnum.CREATED]: {
    name: $localize`Creado`,
  },
  [EmployeeStatusEnum.ACTIVE]: {
    name: $localize`Activo`,
  },
  [EmployeeStatusEnum.INACTIVE]: {
    name: $localize`Inactivo`,
  },
  [EmployeeStatusEnum.PENDING]: {
    name: $localize`Pendiente`,
  },
  [EmployeeStatusEnum.CANCELLED]: {
    name: $localize`Cancelado`,
  },
};

export enum EmployeeStatusSelectorEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const EmployeeStatusSelectorArray = [
  EmployeeStatusSelectorEnum.ACTIVE,
  EmployeeStatusSelectorEnum.INACTIVE,
];

export const EmployeeStatusSelectorInfo: QaroniEnumInformation<EmployeeStatusSelectorEnum> =
  {
    [EmployeeStatusSelectorEnum.ACTIVE]: {
      name: $localize`Empleados activos`,
    },
    [EmployeeStatusSelectorEnum.INACTIVE]: {
      name: $localize`Empleados en baja`,
    },
  };
