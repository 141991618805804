<div class="qaroni-table-responsive w-full">
  <table class="table w-full">
    <thead>
      <tr>
        <th class="border-b p-2 align-bottom" scope="col" i18n>Día</th>
        <th class="border-b p-2 align-bottom" scope="col"></th>
        <th class="border-b p-2 align-bottom" scope="col" i18n>
          Turnos trabajados
        </th>
        <th class="border-b p-2 text-center align-bottom" scope="col" i18n>
          Horas trabajadas
        </th>
        <th class="border-b p-2 text-center align-bottom" scope="col" i18n>
          Horas esperadas
        </th>
        <th class="border-b p-2 text-center align-bottom" scope="col" i18n>
          Diferencia
        </th>
        <th class="border-b p-2 text-center align-bottom" scope="col" i18n>
          Balance
        </th>
        <th class="border-b p-2 align-bottom" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <ng-content></ng-content>
    </tbody>
  </table>
</div>
