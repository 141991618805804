import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { QaroniBaseSubsDirective } from '@qaroni-core/directives/qaroni-base-subs/qaroni-base-subs.directive';
import { AdvancedFiltersService } from '@qaroni-core/services/app/advanced-filters/advanced-filters.service';
import { qaroniAnimations } from '@qaroni-shared/animations/qaroni-animations';

@Component({
  selector: 'qaroni-advanced-filters-container',
  templateUrl: './advanced-filters-container.component.html',
  styleUrl: './advanced-filters-container.component.scss',
  animations: [qaroniAnimations.toggleHeight()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedFiltersContainerComponent
  extends QaroniBaseSubsDirective
  implements OnInit
{
  public active = false;
  private advancedFiltersService = inject(AdvancedFiltersService);
  private _cdr = inject(ChangeDetectorRef);
  private advancedFilterStatus$ =
    this.advancedFiltersService.getAdvancedFiltersStatus$();

  ngOnInit(): void {
    this.subs.add(this.advancedFilterStatus$.subscribe(this.getStatus));
  }

  private getStatus = (status: boolean): void => {
    this.active = status;
    this._cdr.markForCheck();
  };
}
