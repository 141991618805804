import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum AssessmentEmployeeStatusEnum {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  FINISHED = 'FINISHED',
}

export const AssessmentEmployeeStatusArray = [
  AssessmentEmployeeStatusEnum.PENDING,
  AssessmentEmployeeStatusEnum.ACTIVE,
  AssessmentEmployeeStatusEnum.COMPLETED,
  AssessmentEmployeeStatusEnum.FINISHED,
];

export const AssessmentEmployeeStatusInfo: QaroniEnumInformation<AssessmentEmployeeStatusEnum> =
  {
    [AssessmentEmployeeStatusEnum.PENDING]: {
      name: $localize`Pendiente`,
    },
    [AssessmentEmployeeStatusEnum.ACTIVE]: {
      name: $localize`Activa`,
    },
    [AssessmentEmployeeStatusEnum.COMPLETED]: {
      name: $localize`Completada`,
    },
    [AssessmentEmployeeStatusEnum.FINISHED]: {
      name: $localize`Finalizada`,
    },
  };
