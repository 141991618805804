import { Pipe, PipeTransform } from '@angular/core';
import {
  EmployeeStatusEnum,
  EmployeeStatusInfo,
} from '@qaroni-app/employee/types/employee-status.enum';

@Pipe({
  name: 'employeeStatus',
})
export class EmployeeStatusPipe implements PipeTransform {
  transform(value: EmployeeStatusEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(EmployeeStatusInfo, value)
    ) {
      return EmployeeStatusInfo[value].name;
    }

    return value;
  }
}
