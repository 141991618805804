import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiQWorkardsEnv } from '@qaroni-core/config/apis/api-qworkards/api-qworkards.config';
import { Observable } from 'rxjs';
import { AddSeatsJson } from '../types/add-seats-json';
import { ConstantJson } from '../types/constant-json';
import { PaymentValidate } from '../types/payment-validate';

@Injectable({
  providedIn: 'root',
})
export class PaymentsHttpService {
  constructor(private http: HttpClient) {}

  public addSeats$(
    companyID: number,
    subscriptionID: number,
    addSeatsJson: AddSeatsJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/subscriptions/${subscriptionID}`;

    return this.http.patch(url, addSeatsJson, { observe: 'response' });
  }

  public removeSeats$(
    companyID: number,
    subscriptionID: number,
    addSeatsJson: AddSeatsJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/subscriptions/${subscriptionID}/decreases`;

    return this.http.patch(url, addSeatsJson, { observe: 'response' });
  }

  public updatePaymentMethod$(
    companyID: number
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/billings/portals`;

    return this.http.get(url, { observe: 'response' });
  }

  public switchConstantBilling$(
    companyID: number,
    subscriptionID: number,
    constantJson: ConstantJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/subscriptions/${subscriptionID}/billings/cycles`;

    return this.http.patch(url, constantJson, { observe: 'response' });
  }

  public validatePaymentSuccess$(
    companyID: number,
    subscriptionID: string,
    paymentValidate: PaymentValidate
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/subscriptions/${subscriptionID}/payments/successes`;
    const params: Params = { ...paymentValidate };

    return this.http.get(url, { observe: 'response', params });
  }

  public validatePaymentCancel$(
    companyID: number,
    subscriptionID: string,
    paymentValidate: PaymentValidate
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/${companyID}/subscriptions/${subscriptionID}/payments/cancel`;
    const params: Params = { ...paymentValidate };

    return this.http.get(url, { observe: 'response', params });
  }

  public weWillContactYou$(): Observable<HttpResponse<any>> {
    const url = `${ApiQWorkardsEnv.baseUrl}/companies/subscriptions/contacts`;

    return this.http.patch(url, {}, { observe: 'response' });
  }
}
