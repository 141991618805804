<div
  class="flex items-center justify-between overflow-hidden rounded-md border">
  <div class="min-h-[54px] min-w-[6px] bg-primary-40"></div>
  <button
    mat-flat-button
    class="min-h-[54px] w-full rounded-none"
    (click)="onClick()">
    <mat-icon class="mr-1" [fontIcon]="icon" />
    <ng-container i18n>Filtros avanzados</ng-container>
  </button>
</div>
