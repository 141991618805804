import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Plan } from '@qaroni-app/payments/types/plan';
import { PlanNameEnum } from '@qaroni-app/payments/types/plan-name.enum';

@Component({
  selector: 'qaroni-payments-level-container',
  templateUrl: './payments-level-container.component.html',
  styleUrls: ['./payments-level-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentsLevelContainerComponent {
  @Input()
  get isMonthly(): boolean {
    return this._isMonthly;
  }
  set isMonthly(value: BooleanInput) {
    this._isMonthly = coerceBooleanProperty(value);
  }
  private _isMonthly: boolean = true;

  @Input() plans: Plan[];

  get starterPlan(): Plan {
    return this.plans?.filter(plan => plan.name === PlanNameEnum.STARTER)[0];
  }

  get startupPlan(): Plan {
    return this.plans?.filter(plan => plan.name === PlanNameEnum.STARTUP)[0];
  }

  get businessPlan(): Plan {
    return this.plans?.filter(plan => plan.name === PlanNameEnum.BUSINESS)[0];
  }
}
