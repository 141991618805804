import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { EmployeeDisability } from '@qaroni-app/employee/types/employee-disability';
import { QaroniDataSource } from '@qaroni-core/types/data-sources/qaroni-data-source';
import { PaginationLinks } from '@qaroni-core/types/pagination-links/pagination-links';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { PanelProfileService } from '../services/panel-profile.service';

export class EmployeeDisabilitiesDataSource extends QaroniDataSource<EmployeeDisability> {
  private employeeDisabilities$: Observable<EmployeeDisability[]> =
    this.panelProfileService.getEmployeeDisabilities$().pipe(shareReplay(1));

  public paginationLinks$: Observable<PaginationLinks> =
    this.panelProfileService.getPaginationLinks$().pipe(shareReplay(1));

  constructor(
    route: ActivatedRoute,
    router: Router,
    private panelProfileService: PanelProfileService,
    private employeeID: number
  ) {
    super(route, router);
  }

  connect(): Observable<EmployeeDisability[]> {
    super.initialization();

    return this.employeeDisabilities$;
  }

  public getQueryParamMap = (queryParamMap: ParamMap): void => {
    super.queryParamMapActions(queryParamMap);

    if (!Number.isInteger(this.employeeID)) {
      return;
    }

    this.panelProfileService.getEmployeeDisabilities(
      this.employeeID,
      this.queryParams
    );
  };
}
