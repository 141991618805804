import { Pipe, PipeTransform } from '@angular/core';
import { UnNameAvatarPreviewInfo } from '@qaroni-app/tasks/types/unname-avatar-preview.enum';

@Pipe({
  name: 'tasksUnassignedDescription',
})
export class TasksUnassignedPipe implements PipeTransform {
  transform(value: string): string {
    if (
      !!value &&
      Object.prototype.hasOwnProperty.call(UnNameAvatarPreviewInfo, value)
    ) {
      return UnNameAvatarPreviewInfo[value].name;
    }

    return value;
  }
}
