import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbsenceEmployee } from '@qaroni-app/absences/types/absence-employee';

@Component({
  selector: 'qaroni-absence-confirmation-preview',
  templateUrl: './absence-confirmation-preview.component.html',
  styleUrls: ['./absence-confirmation-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbsenceConfirmationPreviewComponent {
  @Input() absense: AbsenceEmployee;
}
