import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdvancedFiltersService {
  private AdvancedFilterStatusSubject = new BehaviorSubject<boolean>(false);

  public getAdvancedFiltersStatus$() {
    return this.AdvancedFilterStatusSubject.asObservable().pipe(shareReplay(1));
  }

  public changeStatus(status: boolean) {
    this.AdvancedFilterStatusSubject.next(status);
  }
}
