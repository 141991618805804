import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum ShiftStatusEnum {
  ONLINE = 'ONLINE',
  BREAK = 'BREAK',
  OFFLINE = 'OFFLINE',
}

export type ShiftStatusType =
  | ShiftStatusEnum.ONLINE
  | ShiftStatusEnum.BREAK
  | ShiftStatusEnum.OFFLINE;

export const ShiftStatusArray = [
  ShiftStatusEnum.ONLINE,
  ShiftStatusEnum.BREAK,
  ShiftStatusEnum.OFFLINE,
];

export const ShiftStatusInfo: QaroniEnumInformation<ShiftStatusEnum> = {
  [ShiftStatusEnum.ONLINE]: {
    name: $localize`Trabajando`,
  },
  [ShiftStatusEnum.BREAK]: {
    name: $localize`Descanso`,
  },
  [ShiftStatusEnum.OFFLINE]: {
    name: $localize`Fuera`,
  },
};
