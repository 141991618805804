import { Pipe, PipeTransform } from '@angular/core';
import {
  EmployeeStatusSelectorEnum,
  EmployeeStatusSelectorInfo,
} from '@qaroni-app/employee/types/employee-status.enum';

@Pipe({
  name: 'selectorEmployeeStatus',
})
export class SelectorEmployeeStatusPipe implements PipeTransform {
  transform(value: EmployeeStatusSelectorEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(EmployeeStatusSelectorInfo, value)
    ) {
      return EmployeeStatusSelectorInfo[value].name;
    }

    return value;
  }
}
