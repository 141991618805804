import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum AbsenceStatusEnum {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type AbsenceStatusType =
  | AbsenceStatusEnum.CREATED
  | AbsenceStatusEnum.ACTIVE
  | AbsenceStatusEnum.INACTIVE;

export const AbsenceStatusArray = [
  AbsenceStatusEnum.CREATED,
  AbsenceStatusEnum.ACTIVE,
  AbsenceStatusEnum.INACTIVE,
];

export const AbsenceStatusInfo: QaroniEnumInformation<AbsenceStatusEnum> = {
  [AbsenceStatusEnum.CREATED]: {
    name: $localize`Creado`,
  },

  [AbsenceStatusEnum.ACTIVE]: {
    name: $localize`Activo`,
  },

  [AbsenceStatusEnum.INACTIVE]: {
    name: $localize`Inactivo`,
  },
};
