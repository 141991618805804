import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive()
export class QaroniBaseSubsDirective implements OnDestroy {
  protected subs = new Subscription();

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
