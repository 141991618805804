import { Injectable } from '@angular/core';
import { QaroniApiResponse } from '@qaroni-core/types/qaroni-json-api/qaroni-json-api';

@Injectable({
  providedIn: 'root',
})
export class ValidationsHttpService {
  private verifyAllResponses<T>(response: QaroniApiResponse<T>): boolean {
    return response?.status && response?.ok === true ? true : false;
  }

  private verifyResultResponses<T>(response: QaroniApiResponse<T>): boolean {
    return this.verifyAllResponses(response) &&
      response.body !== null &&
      response.body?.result !== null &&
      response.body?.result !== undefined
      ? true
      : false;
  }

  public verifyStatus200<T>(response: QaroniApiResponse<T>): boolean {
    return this.verifyResultResponses(response) && response.status === 200
      ? true
      : false;
  }

  public verifyStatus201<T>(response: QaroniApiResponse<T>): boolean {
    return this.verifyResultResponses(response) && response.status === 201
      ? true
      : false;
  }

  public verifyStatus204<T>(response: QaroniApiResponse<T>): boolean {
    return this.verifyAllResponses(response) && response.status === 204
      ? true
      : false;
  }
}
