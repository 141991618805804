<div
  class="qaroni-title-dashboard-container flex w-full flex-col items-center justify-between sm:flex-row">
  <div class="flex-1">
    <div class="flex flex-row items-center">
      <span class="qaroni-title-dashboard mb-0 me-2 w-full">
        <ng-content select="[title]"></ng-content>
      </span>
      <ng-content select="[action]"></ng-content>
    </div>
  </div>
  <div
    class="flex w-full flex-col items-end justify-end gap-1 sm:w-fit md:flex-row">
    <ng-content select="[button]"></ng-content>
    <ng-content select="[export]"></ng-content>
  </div>
</div>
