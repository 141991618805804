import { Pipe, PipeTransform } from '@angular/core';
import {
  DocumentTypeEnum,
  DocumentTypeInfo,
} from '@qaroni-app/employee/types/document-type.enum';

@Pipe({
  name: 'employeeDocumentType',
})
export class EmployeeDocumentTypePipe implements PipeTransform {
  transform(value: DocumentTypeEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(DocumentTypeInfo, value)
    ) {
      return DocumentTypeInfo[value].name;
    }

    return value;
  }
}
