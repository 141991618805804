<google-map
  [width]="width"
  [height]="height ?? '400px'"
  [center]="center"
  [zoom]="zoom"
  [options]="mapOptions">
  <ng-container *ngFor="let workplace of workplacesList">
    <map-marker
      *ngIf="hasLatLng(workplace)"
      #marker="mapMarker"
      [position]="getLatLng(workplace)"
      [options]="markerOptions"
      (mapClick)="openInfoWindow(workplace, marker)"></map-marker>
  </ng-container>
  <map-info-window>{{ infoContent }}</map-info-window>
</google-map>
