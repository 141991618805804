import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { DataChart } from '@qaroni-core/types/chart/data-chart';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'qaroni-pie-chart-preview',
  templateUrl: './pie-chart-preview.component.html',
  styleUrls: ['./pie-chart-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PieChartPreviewComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  @Input() titleChart: string;
  @Input() dataChart: DataChart;

  @ViewChild('pieChart') pieChart: ElementRef<HTMLCanvasElement>;

  private chart: Chart<'pie'>;
  private canvas: HTMLCanvasElement;

  constructor() {
    Chart.register(...registerables);
  }

  ngOnChanges(): void {
    if (!this.dataChart?.datasets[0].data?.length) return;

    if (this.chart) this.setUpChart();
  }

  ngAfterViewInit(): void {
    if (!this.dataChart?.datasets[0].data?.length) return;

    this.canvas = this.pieChart.nativeElement;
    this.setUpChart();
  }

  ngOnDestroy(): void {
    if (!this.dataChart?.datasets[0].data?.length) return;

    this.chart.destroy();
  }

  get hasData(): boolean {
    return (
      this.dataChart?.datasets[0]?.data?.length > 0 &&
      this.dataChart?.datasets[0]?.backgroundColor?.length > 0
    );
  }

  private setUpChart = (): void => {
    if (this.chart) this.chart.destroy();

    this.chart = new Chart(this.canvas, {
      type: 'pie',
      data: this.dataChart,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
            display: true,
          },
          title: {
            display: true,
            text: this.titleChart,
          },
        },
      },
    });
  };
}
