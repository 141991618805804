import { UntypedFormControl } from '@angular/forms';
import { ParamMap } from '@angular/router';
import { format, isValid, parseISO, subYears } from 'date-fns';

export class YearMonthDatepickerForm {
  public dateControl: UntypedFormControl = new UntypedFormControl();

  public currentDate: Date;

  public minDate = subYears(new Date(), 100);

  public maxDate = new Date();

  public getQueryParamMap = (queryParamMap: ParamMap): void => {
    if (queryParamMap.has('yearMonth')) {
      const date: Date = parseISO(queryParamMap.get('yearMonth'));
      this.currentDate = isValid(date) ? date : new Date();
      this.dateControl.setValue(format(this.currentDate, 'yyyy-MM'));
    }
  };
}
