import { Pipe, PipeTransform } from '@angular/core';
import {
  UserRoleHintEnum,
  UserRoleHintInfo,
} from '@qaroni-app/workers/types/user-role.enum';

@Pipe({
  name: 'userRoleHint',
})
export class UserRoleHintPipe implements PipeTransform {
  transform(value: UserRoleHintEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(UserRoleHintInfo, value)
    ) {
      return UserRoleHintInfo[value].name;
    }

    return value;
  }
}
