import { Plugin } from 'chart.js';

export class QaroniDoughnutCenterTextChartPlugin implements Plugin<'doughnut'> {
  public id: 'qaroniDoughnutCenterText';
  public textSup: QaroniDoughnutChartTextConfig;
  public textInf: QaroniDoughnutChartTextConfig;
  private textSeparation: number = 12;

  constructor(
    title?: QaroniDoughnutChartTextConfig,
    subtitle?: QaroniDoughnutChartTextConfig,
    textSeparation?: number
  ) {
    this.textSup = title ?? null;
    this.textInf = subtitle ?? null;
    this.textSeparation = textSeparation ?? this.textSeparation;
  }

  beforeDatasetDraw(chart, args, pluginOptions) {
    const ctx = chart.ctx;
    ctx.save();
    const x = chart.getDatasetMeta(0).data[0].x;
    const y = chart.getDatasetMeta(0).data[0].y;
    if (this.textSup) {
      ctx.font = `${this.textSup.fontWeight ?? 'normal'} ${
        this.textSup.fontSize ?? '14px'
      } ${this.textSup.fontFamily ?? 'Roboto'}`;
      ctx.fillStyle = this.textSup.color ?? '#000000';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(this.textSup.text, x, y - this.textSeparation);
    }
    if (this.textInf) {
      ctx.font = `${this.textInf.fontWeight ?? 'normal'} ${
        this.textInf.fontSize ?? '14px'
      } ${this.textInf.fontFamily ?? 'Roboto'}`;
      ctx.fillStyle = this.textInf.color ?? '#000000';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(this.textInf.text, x, y + this.textSeparation);
    }
    ctx.restore();
  }
}

export interface QaroniDoughnutChartTextConfig {
  fontSize?: string;
  fontFamily?: string;
  fontWeight?: 'normal' | 'bold' | 'bolder' | 'lighter';
  color?: string;
  text: string;
}
