<ng-container *ngIf="employeeDisabilities$ | async; else loading">
  <div class="py-5 text-center" *ngIf="!hasDisabilities">
    <div class="my-5">
      <span i18n class="my-5 uppercase" i18n
        >No hay discapacidades para mostrar</span
      >
    </div>
  </div>
</ng-container>

<div class="qaroni-table-responsive" [ngClass]="{ hidden: !hasDisabilities }">
  <table mat-table matSort class="full-width-table" aria-label="Disabilities">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
        Discapacidad
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row?.name }}
      </td>
    </ng-container>

    <!-- Degree Column -->
    <ng-container matColumnDef="degree">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Grado</th>
      <td mat-cell *matCellDef="let row">
        {{ row?.degree | employeeDisabilityDegree }}
      </td>
    </ng-container>

    <!-- isPermanent Column -->
    <ng-container matColumnDef="isPermanent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
        ¿Es permanente?
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row?.isPermanent }}
      </td>
    </ng-container>

    <!-- expirationDate Column -->
    <ng-container matColumnDef="expirationDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
        Fecha de expiración
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row?.expirationDate }}
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="text-center">
        <button
          mat-mini-fab
          color="none"
          class="mat-elevation-z0"
          type="button"
          i18n-matTooltip
          matTooltip="Ver detalles"
          matTooltipPosition="before">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div [ngClass]="{ hidden: !showPaginator || !hasDisabilities }">
  <mat-paginator
    #paginator
    [pageSizeOptions]="[25, 50, 100, 250]"></mat-paginator>
</div>

<ng-template #loading>
  <qaroni-loading [height]="212"></qaroni-loading>
</ng-template>
