<div class="content-header" [ngClass]="{ invisible: !(monthName && year) }">
  <button
    mat-icon-button
    i18n-matTooltip
    matTooltip="Mes anterior"
    (click)="subtractMonth()"
    [disabled]="isLoading$ | async">
    <mat-icon>navigate_before</mat-icon>
  </button>
  <div class="flex flex-col">
    <button
      mat-button
      i18n-matTooltip
      matTooltip="¿Cambiar fecha?"
      (click)="dp.open()">
      {{ monthName }} - {{ year }}
    </button>
    <input
      matInput
      [matDatepicker]="dp"
      [formControl]="dateControl"
      [min]="minDate"
      [max]="maxDate"
      class="invisible"
      style="width: 0; height: 0" />
    <mat-datepicker
      #dp
      startView="multi-year"
      (monthSelected)="setMonthAndYear($event, dp)"
      panelClass="month-picker">
    </mat-datepicker>
  </div>
  <button
    mat-icon-button
    i18n-matTooltip
    matTooltip="Mes siguiente"
    (click)="addMonth()"
    [disabled]="isLoading$ | async">
    <mat-icon>navigate_next</mat-icon>
  </button>
</div>
