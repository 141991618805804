import { Pipe, PipeTransform } from '@angular/core';
import { Employee } from '@qaroni-app/employee/types/employee';

@Pipe({
  name: 'employeeProfile',
})
export class EmployeeProfilePipe implements PipeTransform {
  transform(employee: Employee): number {
    let basicPercentage: number = 0;
    let addressPercentage: number = 0;
    let contractPercentage: number = 0;

    basicPercentage += employee?.firstName ? 100 / 7 : 0;
    basicPercentage += employee?.lastName ? 100 / 7 : 0;
    basicPercentage += employee?.email ? 100 / 7 : 0;
    basicPercentage += employee?.phone ? 100 / 7 : 0;
    basicPercentage += employee?.dni ? 100 / 7 : 0;
    basicPercentage += employee?.gender ? 100 / 7 : 0;
    basicPercentage += employee?.birthdate ? 100 / 7 : 0;

    basicPercentage = basicPercentage * 0.2;

    addressPercentage += employee?.address?.line1 ? 100 / 6 : 0;
    addressPercentage += employee?.address?.line2 ? 100 / 6 : 0;
    addressPercentage += employee?.address?.postalCode ? 100 / 6 : 0;
    addressPercentage += employee?.address?.city ? 100 / 6 : 0;
    addressPercentage += employee?.address?.stateProvince ? 100 / 6 : 0;
    addressPercentage += employee?.address?.country ? 100 / 6 : 0;

    addressPercentage = addressPercentage * 0.4;

    contractPercentage += employee?.contract?.educationLevel ? 100 / 8 : 0;
    contractPercentage += employee?.contract?.grossSalary ? 100 / 8 : 0;
    contractPercentage += employee?.contract?.contractCode ? 100 / 8 : 0;
    contractPercentage += employee?.contract?.ccc ? 100 / 8 : 0;
    contractPercentage += employee?.contract?.ssn ? 100 / 8 : 0;
    contractPercentage += employee?.contract?.iban ? 100 / 8 : 0;
    contractPercentage += employee?.contract?.vacationDays ? 100 / 8 : 0;
    contractPercentage += employee?.contract?.jobPosition ? 100 / 8 : 0;

    contractPercentage = contractPercentage * 0.4;

    return Math.trunc(basicPercentage + addressPercentage + contractPercentage);
  }
}
