<div class="test grid w-full grid-cols-12 items-center gap-3">
  <div class="col-span-4 text-center lg:col-span-2">
    <p class="my-2 text-base">{{ hours | totalMinutesFormat }}</p>
    <p class="mb-0 font-light uppercase" i18n>Horas trabajadas</p>
  </div>
  <div class="col-span-4 text-center lg:col-span-2">
    <p class="my-2 text-base">{{ scheduleTimeMonth | totalMinutesFormat }}</p>
    <p class="mb-0 font-light uppercase" i18n>Horas esperadas</p>
  </div>
  <div class="col-span-4 text-center lg:col-span-2">
    <p class="my-2 text-base">
      {{ hours - (scheduleTimeMonth ?? 0) | totalMinutesFormat: true }}
    </p>
    <p class="mb-0 font-light uppercase" i18n>Balance de horas</p>
  </div>
  <div class="col-span-12 mb-3 text-center lg:col-span-6">
    <qaroni-hours-balance-chart-preview
      [dayChecks]="dayChecks"
      [daySchedules]="daySchedules"
      [monthName]="monthName"></qaroni-hours-balance-chart-preview>
  </div>
</div>
