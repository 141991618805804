import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum EmployeeShiftStatusSelectorEnum {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export const EmployeeShiftStatusSelectorArray = [
  EmployeeShiftStatusSelectorEnum.ONLINE,
  EmployeeShiftStatusSelectorEnum.OFFLINE,
];

export const EmployeeShiftStatusSelectorInfo: QaroniEnumInformation<EmployeeShiftStatusSelectorEnum> =
  {
    [EmployeeShiftStatusSelectorEnum.ONLINE]: {
      name: $localize`Empleados trabajando`,
    },
    [EmployeeShiftStatusSelectorEnum.OFFLINE]: {
      name: $localize`Empleados fuera`,
    },
  };
