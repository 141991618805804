import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'qaroni-label-phone-preview',
  templateUrl: './label-phone-preview.component.html',
  styleUrls: ['./label-phone-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelPhonePreviewComponent {
  @Input() phone: string;
  @Input()
  get label(): boolean {
    return this._label;
  }
  set label(value: BooleanInput) {
    this._label = coerceBooleanProperty(value);
  }
  private _label: boolean;
}
