import { animate, animation, style } from '@angular/animations';

export const qaroniToggleWidthEnterConfig = animation([
  style({
    width: '0px',
    minWidth: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    borderLeft: '0px',
    borderRight: '0px',
    marginLeft: '0px',
    marginRight: '0px',
    overflow: 'hidden',
  }),
  animate(
    '{{time}} cubic-bezier(0.4, 0.0, 0.2, 1)',
    style({
      width: '*',
      minWidth: '*',
      paddingLeft: '*',
      paddingRight: '*',
      borderLeft: '*',
      borderRight: '*',
      marginLeft: '*',
      marginRight: '*',
    })
  ),
]);

export const qaroniToggleWidthLeaveConfig = animation([
  style({
    width: '*',
    minWidth: '*',
    paddingLeft: '*',
    paddingRight: '*',
    borderLeft: '*',
    borderRight: '*',
    marginLeft: '*',
    marginRight: '*',
    overflow: 'hidden',
  }),
  animate(
    '{{time}} cubic-bezier(0.4, 0.0, 0.2, 1)',
    style({
      width: '0px',
      minWidth: '0px',
      paddingLeft: '0px',
      paddingRight: '0px',
      borderLeft: '0px',
      borderRight: '0px',
      marginLeft: '0px',
      marginRight: '0px',
    })
  ),
]);
