import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  InvitationRoleSelectorArray,
  UserRoleEnum,
} from '@qaroni-app/workers/types/user-role.enum';
import { InputValidation } from '@qaroni-core/utils/validations/input-validation';

export class SendInvitationDialogForm {
  public InputValidation = InputValidation;
  public sendInvitationForm: UntypedFormGroup;
  public roles: UserRoleEnum[];

  private fb: UntypedFormBuilder = new UntypedFormBuilder();
  private sendInvitationSkeleton;

  constructor() {
    this.roles = InvitationRoleSelectorArray;
    this.sendInvitationSkeleton = {
      email: ['', [Validators.required, Validators.email]],
      role: [UserRoleEnum.ADMIN, Validators.required],
    };
    this.sendInvitationForm = this.fb.group(this.sendInvitationSkeleton);
  }

  get email(): AbstractControl {
    return this.sendInvitationForm.get('email');
  }

  get role(): AbstractControl {
    return this.sendInvitationForm.get('role');
  }

  get dirtyValidForm(): boolean {
    return this.sendInvitationForm.dirty && this.sendInvitationForm.valid;
  }
}
