import { Pipe, PipeTransform } from '@angular/core';
import {
  TicketTypeEnum,
  TicketTypeInfo,
} from '@qaroni-app/ticket/types/ticket-type.enum';

@Pipe({
  name: 'ticketType',
})
export class TicketTypePipe implements PipeTransform {
  transform(value: TicketTypeEnum): string {
    if (value && Object.prototype.hasOwnProperty.call(TicketTypeInfo, value)) {
      return TicketTypeInfo[value].name;
    }
    return value;
  }
}
