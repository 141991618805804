import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardStorageService } from '@qaroni-app/dashboard/services/dashboard-storage.service';
import { CommonsHttpService } from '@qaroni-core/services/commons/commons-http/commons-http.service';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  EmailCaptchaJson,
  LoginJson,
  ResetPasswordJson,
  SignUpJson,
  ValidateOtpJson,
  ValidateRegisterJson,
} from '../types/authentication';
import { OAuth } from '../types/o-auth';
import { User } from '../types/user';
import { UserCache } from '../types/user-cache.class';
import { UserStatusEnum } from '../types/user-status.enum';
import { OAuthHttpService } from './o-auth-http.service';
import { OAuthSnackbarsService } from './o-auth-snackbars.service';
import { OAuthStorageService } from './o-auth-storage.service';

@Injectable({
  providedIn: 'root',
})
export class OAuthService {
  private user: UserCache = new UserCache();

  protected readonly emailSubject = new Subject<string>();
  protected readonly linkSubject = new Subject<string>();
  protected readonly messageSubject = new Subject<string>();
  protected readonly oAuthSubject = new Subject<OAuth>();
  protected readonly userSubject = new Subject<User>();

  constructor(
    private router: Router,
    private oAuthHttp: OAuthHttpService,
    private commonsHttp: CommonsHttpService,
    private snackbars: OAuthSnackbarsService,
    private dashboardStorage: DashboardStorageService,
    public storage: OAuthStorageService
  ) {}

  public loginWithTokenAndUserId(oAuth: OAuth) {
    if (oAuth) {
      this.storage.set(oAuth);
      this.oAuthSubject.next(oAuth);
    } else {
      this.oAuthSubject.next(null);
    }
  }

  get hasOAuth(): boolean {
    return this.storage.hasOAuth;
  }

  public getMessage$(): Observable<string> {
    return this.messageSubject.asObservable();
  }

  public getUser$(): Observable<User> {
    return this.userSubject.asObservable();
  }

  public nextUser$(user: User): void {
    this.user.set(user);
    this.userSubject.next(this.user.get());
  }

  // ==========================================================================================
  // Register User
  // ==========================================================================================

  public register(signUpJson: SignUpJson): void {
    this.oAuthHttp
      .register$(signUpJson)
      .subscribe({ next: this.nextRegister, error: this.errorRegister });
  }

  private nextRegister = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus201(data)) {
      this.messageSubject.next('Registered OK');
    } else {
      this.messageSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorRegister = (error: HttpErrorResponse): void => {
    this.messageSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.snackbars.failureRegisterEmailExists();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.snackbars.failureRegisterEmailIsNotValid();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0018')) {
      this.messageSubject.next('La cuenta esta activa.');
      this.snackbars.failureRegisterUserIsActive();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0013')) {
      this.messageSubject.next('La cuenta esta cancelada.');
      this.snackbars.failureRegisterUserIsCancelled();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0014')) {
      this.messageSubject.next('La cuenta esta inactiva.');
      this.snackbars.failureRegisterUserIsInactive();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0025')) {
      this.messageSubject.next('La cuenta esta bloqueada.');
      this.snackbars.failureRegisterUserIsBlocked();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Validate User
  // ==========================================================================================

  public validate(
    userID: number,
    validateRegisterJson: ValidateRegisterJson
  ): void {
    this.oAuthHttp
      .validate$(userID, validateRegisterJson)
      .subscribe({ next: this.nextValidate, error: this.errorValidate });
  }

  private nextValidate = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const oAuth: OAuth = data.body.result[0];
      this.storage.set(oAuth);
      this.oAuthSubject.next(oAuth);
    } else {
      this.oAuthSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorValidate = (error: HttpErrorResponse): void => {
    this.oAuthSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0013')) {
      this.snackbars.failureValidateAccountIsCancelled();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0014')) {
      this.snackbars.failureValidateAccountIsInactive();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0018')) {
      this.messageSubject.next('Su cuenta ya ha sido activada.');
      this.snackbars.failureValidateAccountIsActive();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Login
  // ==========================================================================================

  public getOAuth$(): Observable<OAuth> {
    return this.oAuthSubject.asObservable();
  }

  public login(loginJSON: LoginJson): void {
    this.oAuthHttp
      .login$(loginJSON)
      .subscribe({ next: this.nextLogin, error: this.errorLogin });
  }

  private nextLogin = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const oAuth: OAuth = data.body.result[0];
      this.storage.set(oAuth);
      this.oAuthSubject.next(oAuth);
    } else {
      this.oAuthSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorLogin = (error: HttpErrorResponse): void => {
    this.oAuthSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0007')) {
      this.snackbars.failureLoginTheAccountHasNotExists();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0011')) {
      this.snackbars.failureLoginInvalidCredentials();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0013')) {
      this.snackbars.failureLoginAccountIsCancelled();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0014')) {
      this.messageSubject.next('The resource is INACTIVE.');
      this.snackbars.failureLoginAccountIsInactive();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0017')) {
      this.snackbars.failureLoginValidateEmailsButIsNotValidates();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0021')) {
      this.messageSubject.next('The resource is BLOCKED.');
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0025')) {
      this.messageSubject.next('The resource is BLOCKED.');
      this.snackbars.failureLoginAccountIsBlocked();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0061')) {
      this.snackbars.failureLowRecaptchaScore();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Login with Google Account
  // ==========================================================================================

  public getLink$(): Observable<string> {
    return this.linkSubject.asObservable();
  }

  public loginWithGoogleAccount(): void {
    this.oAuthHttp.loginWithGoogleAccount$().subscribe({
      next: this.nextLoginWithGoogleAccount,
      error: this.errorLoginWithGoogleAccount,
    });
  }

  private nextLoginWithGoogleAccount = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const url: string = data?.body?.result?.url;
      this.linkSubject.next(url);
    } else {
      this.linkSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorLoginWithGoogleAccount = (error: HttpErrorResponse): void => {
    this.linkSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0006')) {
      this.messageSubject.next('Error: E0006');
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0011')) {
      this.messageSubject.next('Error: E0011');
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Sign-Up with Google Account
  // ==========================================================================================

  public signUpWithGoogleAccount(): void {
    this.oAuthHttp.signUpWithGoogleAccount$().subscribe({
      next: this.nextSignUpWithGoogleAccount,
      error: this.errorSignUpWithGoogleAccount,
    });
  }

  private nextSignUpWithGoogleAccount = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const url: string = data?.body?.result?.url;
      this.linkSubject.next(url);
    } else {
      this.linkSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorSignUpWithGoogleAccount = (error: HttpErrorResponse): void => {
    this.linkSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0006')) {
      this.messageSubject.next('Error: E0006');
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0011')) {
      this.messageSubject.next('Error: E0011');
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Forgot Password
  // ==========================================================================================

  public forgot(emailCaptchaJson: EmailCaptchaJson): void {
    this.oAuthHttp
      .forgot$(emailCaptchaJson)
      .subscribe({ next: this.nextForgot, error: this.errorForgot });
  }

  private nextForgot = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const user: User = data.body.result;
      this.userSubject.next(user);
    } else {
      this.userSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorForgot = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0007')) {
      this.snackbars.failedForgotTheAccountHasNotExists();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0013')) {
      this.messageSubject.next('The resource is CANCELLED.');
      this.snackbars.failedForgotTheAccountIsCancelled();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0014')) {
      this.messageSubject.next('The resource is INACTIVE.');
      this.snackbars.failedForgotTheAccountIsInactive();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Reset Password
  // ==========================================================================================

  public resetPassword(
    userID: number,
    resetPasswordJSON: ResetPasswordJson
  ): void {
    this.oAuthHttp.resetPassword$(userID, resetPasswordJSON).subscribe({
      next: this.nextResetPassword,
      error: this.errorResetPassword,
    });
  }

  private nextResetPassword = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const oAuth: OAuth = data.body.result[0];
      this.storage.set(oAuth);
      this.oAuthSubject.next(oAuth);
    } else {
      this.oAuthSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorResetPassword = (error: HttpErrorResponse): void => {
    this.oAuthSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0009')) {
      this.snackbars.failureResetPasswordAccountIsNotActive();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0013')) {
      this.snackbars.failureResetPasswordAccountIsNotActive();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0014')) {
      this.snackbars.failureResetPasswordAccountIsNotActive();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Validate OTP
  // ==========================================================================================

  public getEmail$(): Observable<string> {
    return this.emailSubject.asObservable();
  }

  public validateOTP(userID: number, validateOtpJson: ValidateOtpJson): void {
    this.oAuthHttp.validateOTP$(userID, validateOtpJson).subscribe({
      next: this.nextValidateOTP,
      error: this.errorValidateOTP,
    });
  }

  private nextValidateOTP = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const email: string = data.body.result[0].email;
      this.emailSubject.next(email);
    } else {
      this.emailSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorValidateOTP = (error: HttpErrorResponse): void => {
    this.emailSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Get User
  // ==========================================================================================

  public getUser(userID: number): void {
    if (!this.hasOAuth) return;

    if (this.user.canReturn(userID)) {
      this.userSubject.next(this.user.get());
    } else if (this.user.canApi(userID)) {
      this.user.startToFly();
      this.oAuthHttp
        .getUser$(userID)
        .pipe(finalize(() => this.user.endFlying()))
        .subscribe({
          next: this.nextGetUser,
          error: this.errorGetUser,
        });
    }
  }

  private nextGetUser = (data: HttpResponse<any>): void => {
    if (
      this.commonsHttp.validationsHttp.verifyStatus200(data) &&
      data?.body?.result?.length
    ) {
      this.user.set(data.body.result[0]);
      this.userSubject.next(this.user.get());

      if (this.user.get().status === UserStatusEnum.BLOCKED) {
        this.storage.reset();
        this.dashboardStorage.reset();
        this.router.navigate([`auth/forgot/blocked`]);
      }
    } else {
      this.userSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorGetUser = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0002')) {
      this.snackbars.failureExpiredSession();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  public getFirstName(user: User): string {
    let firstName = '';
    if (user && user.fullName) {
      const splited = user.fullName.split(' ');
      if (splited && splited.length > 0) {
        for (const iterator of splited) {
          if (iterator && iterator.length > 0) {
            firstName = iterator;
            break;
          }
        }
      }
    }
    return firstName;
  }

  // ==========================================================================================
  // Validate email
  // ==========================================================================================

  public validateEmail(emailCaptchaJson: EmailCaptchaJson): void {
    this.oAuthHttp.validateEmail$(emailCaptchaJson).subscribe({
      next: this.nextValidateEmail,
      error: this.errorValidateEmail,
    });
  }

  private nextValidateEmail = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      this.messageSubject.next('OK validate email.');
    } else {
      this.messageSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorValidateEmail = (error: HttpErrorResponse): void => {
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.messageSubject.next('Invalid email.');
      this.snackbars.failureEmailInvalid();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0007')) {
      this.messageSubject.next('Invalid email.');
      this.snackbars.failureEmailInvalid();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0012')) {
      this.messageSubject.next('Account not validated.');
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0013')) {
      this.messageSubject.next('Account inactive.');
      this.snackbars.failureEmailInvalid();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0014')) {
      this.messageSubject.next('Account cancelled.');
      this.snackbars.failureEmailInvalid();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0021')) {
      this.messageSubject.next('The resource is BLOCKED.');
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0025')) {
      this.messageSubject.next('The resource is BLOCKED.');
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Magic Link
  // ==========================================================================================

  public sendMagicLink(email: string): void {
    this.oAuthHttp.sendMagicLink$(email).subscribe({
      next: this.nextSendMagicLink,
      error: this.errorSendMagicLink,
    });
  }

  private nextSendMagicLink = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      this.messageSubject.next('Sent magic link.');
    } else {
      this.messageSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorSendMagicLink = (error: HttpErrorResponse): void => {
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0026')) {
      this.messageSubject.next('The resource is CREATED.');
      this.snackbars.failureSendMagicLinkAccountIsCreated();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0014')) {
      this.messageSubject.next('The resource is INACTIVE.');
      this.snackbars.failureSendMagicLinkAccountIsInactive();
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0021')) {
      this.messageSubject.next('Error: E0021');
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0022')) {
      this.messageSubject.next('Error: E0022');
    } else if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0025')) {
      this.messageSubject.next('The resource is BLOCKED.');
      this.snackbars.failureSendMagicLinkAccountIsBlocked();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Validate Magic Link
  // ==========================================================================================

  public validateMagicLink(token: string, signature: string): void {
    this.oAuthHttp.validateMagicLink$(token, signature).subscribe({
      next: this.nextValidateMagicLink,
      error: this.errorValidateMagicLink,
    });
  }

  private nextValidateMagicLink = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const oAuth: OAuth = data.body.result[0];
      this.storage.set(oAuth);
      this.oAuthSubject.next(oAuth);
    } else {
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorValidateMagicLink = (error: HttpErrorResponse): void => {
    this.messageSubject.next('Error magic link.');
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0002')) {
      this.messageSubject.next('Error: E0002');
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Send Register Link
  // ==========================================================================================

  public sendRegisterLink(
    invitationId: number,
    expires: string,
    signature: string,
    appCode: string
  ): void {
    this.oAuthHttp
      .sendRegisterLink$(invitationId, expires, signature, appCode)
      .subscribe({
        next: this.nextSendRegisterLink,
        error: this.errorSendRegisterLink,
      });
  }

  private nextSendRegisterLink = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      this.messageSubject.next('Registered OK');
    } else {
      this.messageSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorSendRegisterLink = (error: HttpErrorResponse): void => {
    this.messageSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };
}
