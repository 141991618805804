import { Pipe, PipeTransform } from '@angular/core';
import {
  EmployeeAbsenceStatusEnum,
  EmployeeAbsenceStatusInfo,
} from '@qaroni-app/employee/types/employee-absence-status.enum';

@Pipe({
  name: 'employeeAbsenceStatus',
})
export class EmployeeAbsenceStatusPipe implements PipeTransform {
  transform(value: EmployeeAbsenceStatusEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(EmployeeAbsenceStatusInfo, value)
    ) {
      return EmployeeAbsenceStatusInfo[value].name;
    }

    return value;
  }
}
