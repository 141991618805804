import { Pipe, PipeTransform } from '@angular/core';
import {
  ProjectTaskStatusEnum,
  ProjectTaskStatusInfo,
} from '../../../projects/types/project-task-status.enum';

@Pipe({
  name: 'tasksStatus',
})
export class TasksStatusPipe implements PipeTransform {
  transform(value: ProjectTaskStatusEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(ProjectTaskStatusInfo, value)
    ) {
      return ProjectTaskStatusInfo[value].name;
    }

    return value;
  }
}
