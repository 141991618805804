import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Workplace } from '@qaroni-app/workplace/types/workplace';
import { qaroniAnimations } from '@qaroni-shared/animations/qaroni-animations';

@Component({
  selector: 'qaroni-card-workplace',
  templateUrl: './card-workplace.component.html',
  styleUrls: ['./card-workplace.component.scss'],
  animations: [qaroniAnimations.enterFadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardWorkplaceComponent {
  @Input() workplace: Workplace;

  @Input()
  get first(): boolean {
    return this._first;
  }
  set first(value: BooleanInput) {
    this._first = coerceBooleanProperty(value);
  }
  private _first: boolean = false;

  constructor(private router: Router) {}

  goToWorkplaceDetails(): void {
    if (this.workplace?.companyId && this.workplace?.workplaceId) {
      this.router.navigate([
        `/companies/${this.workplace.companyId}/workplaces/${this.workplace.workplaceId}/details`,
      ]);
    }
  }

  goToWorkplaceUpdate(): void {
    if (this.workplace?.companyId && this.workplace?.workplaceId) {
      this.router.navigate([
        `/companies/${this.workplace.companyId}/workplaces/${this.workplace.workplaceId}/update`,
      ]);
    }
  }
}
