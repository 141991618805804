import { Pipe, PipeTransform } from '@angular/core';
import {
  EmployeeContractEnum,
  EmployeeContractInfo,
} from '@qaroni-app/employee/types/employee-contract.enum';

@Pipe({
  name: 'employeeContract',
})
export class EmployeeContractPipe implements PipeTransform {
  transform(value: EmployeeContractEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(EmployeeContractInfo, value)
    ) {
      return EmployeeContractInfo[value].name;
    }

    return value;
  }
}
