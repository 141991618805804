import { Plugin } from 'chart.js';

export class QaroniGaugeNeedlePlugin implements Plugin<'doughnut'> {
  id: 'qaroniGaugeNeedle';
  private needleValue;
  public needleColor = '#004B71';

  constructor(needleValue: number = 0) {
    this.needleValue = needleValue;
  }

  afterDatasetDraw(chart, args: any, options: any) {
    const { ctx, data } = chart;
    ctx.save();

    const xAxisCenter = chart.getDatasetMeta(0).data[0].x;
    const yAxisCenter = chart.getDatasetMeta(0).data[0].y;
    const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
    const needleWidth = 9;
    const angle = Math.PI;

    let circumferenceValue =
      (chart.getDatasetMeta(0).data[0].circumference /
        angle /
        data.datasets[0].data[0]) *
      this.needleValue;
    const needleAngle = circumferenceValue + 1.5;
    ctx.translate(xAxisCenter, yAxisCenter);
    ctx.rotate(angle * needleAngle);

    ctx.beginPath();
    ctx.strokeStyle = this.needleColor;
    ctx.fillStyle = this.needleColor;
    ctx.lineCap = 'round';
    ctx.lineWidth = needleWidth;
    ctx.moveTo(0, 0);
    ctx.lineTo(0, -innerRadius + 10);
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(0, 0, needleWidth, angle * 0, angle * 2, false);
    ctx.fill();
    ctx.restore();
  }
}

export class QaroniGaugeLabelsPlugin implements Plugin<'doughnut'> {
  id: 'qaroniGaugeLabels';
  public beforeDatasetDraw(chart, args, options) {
    const {
      ctx,
      chartArea: { left, right },
    } = chart;
    ctx.save();
    this.printLabels(chart, '0%', 'left', left);
    this.printLabels(chart, '100%', 'right', right);
    ctx.restore();
  }

  private printLabels(chart, text, alignment, position) {
    const { ctx } = chart;
    ctx.font = 'normal 12px Roboto';
    ctx.fillStyle = '#000000';
    ctx.textBaseline = 'top';
    ctx.textAlign = alignment;
    ctx.fillText(text, position, chart.getDatasetMeta(0).data[0].y + 2);
  }
}
