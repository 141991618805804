import {
  BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
  NumberInput,
} from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeDisability } from '@qaroni-app/employee/types/employee-disability';
import { PanelProfileService } from '@qaroni-app/panel-profile/services/panel-profile.service';
import { EmployeeDisabilitiesDataSource } from '@qaroni-app/panel-profile/types/employee-disabilities-datasource';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

//TODO: Todvía no cambiar el nombre del selector por el correcto
@Component({
  selector: 'qaroni-employee-disabilities-tables',
  templateUrl: './employee-disabilities-table.component.html',
  styleUrls: ['./employee-disabilities-table.component.scss'],
})
export class EmployeeDisabilitiesTableComponent
  implements OnInit, AfterViewInit
{
  @Input()
  get employeeID(): number {
    return this._employeeID;
  }
  set employeeID(value: NumberInput) {
    this._employeeID = coerceNumberProperty(value, 0);
  }
  private _employeeID: number;

  @Input()
  get showPaginator(): boolean {
    return this._showPaginator;
  }
  set showPaginator(value: BooleanInput) {
    this._showPaginator = coerceBooleanProperty(value);
  }
  private _showPaginator: boolean = true;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable) table!: MatTable<EmployeeDisability>;

  public dataSource: EmployeeDisabilitiesDataSource;

  public displayedColumns: string[] = [
    'name',
    'degree',
    'isPermanent',
    'expirationDate',
    'actions',
  ];

  public employeeDisabilities$: Observable<EmployeeDisability[]> =
    this.panelProfileService.getEmployeeDisabilities$().pipe(shareReplay(1));

  constructor(
    private panelProfileService: PanelProfileService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (Number.isInteger(this.employeeID)) {
      this.dataSource = new EmployeeDisabilitiesDataSource(
        this.route,
        this.router,
        this.panelProfileService,
        this.employeeID
      );
    }
  }

  ngAfterViewInit(): void {
    if (!this.dataSource) {
      return;
    }
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  get hasDisabilities(): boolean {
    return this.paginator?.length ? true : false;
  }
}
