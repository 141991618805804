import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Employee } from '@qaroni-app/employee/types/employee';
import { EmployeeAddress } from '@qaroni-app/employee/types/employee-address';
import { EmployeeDisability } from '@qaroni-app/employee/types/employee-disability';
import { EmployeeHistory } from '@qaroni-app/employee/types/employee-history';
import { ProfileSnackbarsService } from '@qaroni-app/profile/services/profile-snackbars.service';
import { CommonsHttpService } from '@qaroni-core/services/commons/commons-http/commons-http.service';
import { PaginationLinks } from '@qaroni-core/types/pagination-links/pagination-links';
import { Observable, Subject } from 'rxjs';
import { PanelProfileHttpService } from './panel-profile-http.service';

@Injectable({
  providedIn: 'root',
})
export class PanelProfileService {
  protected readonly employeeSubject = new Subject<Employee>();
  protected readonly employeeDisabilitiesSubject = new Subject<
    EmployeeDisability[]
  >();
  protected readonly employeeHistoriesSubject = new Subject<
    EmployeeHistory[]
  >();
  protected readonly paginationLinksSubject = new Subject<PaginationLinks>();

  constructor(
    private panelProfileHttp: PanelProfileHttpService,
    private commonsHttp: CommonsHttpService,
    public snackbars: ProfileSnackbarsService
  ) {}

  // ==========================================================================================
  // Get Employee
  // ==========================================================================================

  public getEmployee$(): Observable<Employee> {
    return this.employeeSubject.asObservable();
  }

  public getEmployee(employeeId: number): void {
    this.panelProfileHttp.getEmployee$(employeeId).subscribe({
      next: this.nextGetEmployee,
      error: this.errorGetEmployee,
    });
  }

  private nextGetEmployee = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const employee: Employee = data.body.result[0];
      this.employeeSubject.next(employee);
    } else {
      this.employeeSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorGetEmployee = (error: HttpErrorResponse): void => {
    this.employeeSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Update Profile
  // ==========================================================================================

  public updateProfile(employeeId: number, employee: Employee): void {
    this.panelProfileHttp.updateProfile$(employeeId, employee).subscribe({
      next: this.nextUpdateProfile,
      error: this.errorUpdateProfile,
    });
  }

  private nextUpdateProfile = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const employee: Employee = data.body.result[0];
      this.employeeSubject.next(employee);
    } else {
      this.employeeSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorUpdateProfile = (error: HttpErrorResponse): void => {
    this.employeeSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.snackbars.failureValidationError();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Update Employee Address
  // ==========================================================================================

  public updateEmployeeAddress(
    employeeId: number,
    employeeAddress: EmployeeAddress
  ): void {
    this.panelProfileHttp
      .updateEmployeeAddress$(employeeId, employeeAddress)
      .subscribe({
        next: this.nextUpdateEmployeeAddress,
        error: this.errorUpdateEmployeeAddress,
      });
  }

  private nextUpdateEmployeeAddress = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const employee: Employee = data.body.result[0];
      this.employeeSubject.next(employee);
    } else {
      this.employeeSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorUpdateEmployeeAddress = (error: HttpErrorResponse): void => {
    this.employeeSubject.next(null);
    if (this.commonsHttp.errorsHttp.isControlledError(error, 'E0004')) {
      this.snackbars.failureValidationError();
    }
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Get Employee Disabilities
  // ==========================================================================================

  public getEmployeeDisabilities$(): Observable<EmployeeDisability[]> {
    return this.employeeDisabilitiesSubject.asObservable();
  }

  public getEmployeeDisabilities(employeeId: number, params: Params): void {
    this.panelProfileHttp
      .getEmployeeDisabilities$(employeeId, params)
      .subscribe({
        next: this.nextGetEmployeeDisabilities,
        error: this.errorGetEmployeeDisabilities,
      });
  }

  private nextGetEmployeeDisabilities = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const employeeDisability: EmployeeDisability[] = data.body.result;
      this.employeeDisabilitiesSubject.next(employeeDisability);
      const paginationLinks: PaginationLinks = data.body.links;
      this.paginationLinksSubject.next(paginationLinks);
    } else {
      this.employeeDisabilitiesSubject.next(null);
      this.paginationLinksSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorGetEmployeeDisabilities = (error: HttpErrorResponse): void => {
    this.employeeDisabilitiesSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Get Employee Disabilities
  // ==========================================================================================

  public getEmployeeHistories$(): Observable<EmployeeHistory[]> {
    return this.employeeHistoriesSubject.asObservable();
  }

  public getEmployeeHistories(employeeId: number, params: Params): void {
    this.panelProfileHttp.getEmployeeHistories$(employeeId, params).subscribe({
      next: this.nextGetEmployeeHistories,
      error: this.errorGetEmployeeHistories,
    });
  }

  private nextGetEmployeeHistories = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const employeeHistory: EmployeeHistory[] = data.body.result;
      this.employeeHistoriesSubject.next(employeeHistory);
      const paginationLinks: PaginationLinks = data.body.links;
      this.paginationLinksSubject.next(paginationLinks);
    } else {
      this.employeeHistoriesSubject.next(null);
      this.paginationLinksSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  };

  private errorGetEmployeeHistories = (error: HttpErrorResponse): void => {
    this.employeeHistoriesSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  };

  // ==========================================================================================
  // Pagination Links
  // ==========================================================================================

  public getPaginationLinks$(): Observable<PaginationLinks> {
    return this.paginationLinksSubject.asObservable();
  }
}
