<table>
  <tr
    qaroni-payments-level
    class="payment-level"
    [plan]="starterPlan"
    [isMonthly]="isMonthly"></tr>
  <tr qaroni-payments-level-line></tr>
  <tr
    qaroni-payments-level
    class="payment-level"
    [plan]="startupPlan"
    [isMonthly]="isMonthly"></tr>
  <tr qaroni-payments-level-line></tr>
  <tr
    qaroni-payments-level
    class="payment-level"
    [plan]="businessPlan"
    [isMonthly]="isMonthly"></tr>
</table>
