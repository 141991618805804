<div class="flex w-full flex-col items-center justify-center gap-3 pt-5">
  <img
    class="aspect-auto h-60"
    src="assets/img/empty-result.svg"
    alt="Empty result" />

  <div class="mb-5 w-full text-center text-lg">
    {{ message ?? 'No se han encontrado resultados' }}
  </div>
</div>
