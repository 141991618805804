<form [formGroup]="sendInvitationForm" (ngSubmit)="onSubmit()">
  <button
    mat-button
    type="button"
    class="qaroni-close-icon"
    [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
  <div class="mat-mdc-dialog-title">{{ data }}</div>
  <mat-dialog-content class="mx-1 pb-3">
    <div class="flex items-center justify-between gap-3">
      <div class="w-60">
        <mat-form-field appearance="outline">
          <mat-label i18n>Rol</mat-label>
          <mat-select formControlName="role">
            <mat-option *ngFor="let rol of roles" [value]="rol">
              {{ rol | userRole }}
            </mat-option>
          </mat-select>
          <mat-hint>{{ role.value | userRoleHint }}</mat-hint>
        </mat-form-field>
      </div>
      <div class="flex-1">
        <mat-form-field appearance="outline">
          <mat-label i18n>Correo electrónico</mat-label>
          <input
            matInput
            formControlName="email"
            autocomplete="off"
            type="email" />
          <mat-error
            align="start"
            *ngIf="InputValidation.checkInputErrors(email)">
            <qaroni-input-error-messages
              [fc]="email"></qaroni-input-error-messages>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <div class="flex w-full items-center justify-center gap-3">
      <button
        mat-flat-button
        color="light-blue"
        class="qaroni-rounded-button m-1"
        type="button"
        [mat-dialog-close]="false"
        i18n>
        Cancelar
      </button>

      <button
        mat-flat-button
        color="blue"
        class="qaroni-rounded-button m-1"
        type="submit"
        [disabled]="!validatedForm">
        <mat-icon class="me-1">email</mat-icon>
        <ng-container i18n>Enviar</ng-container>
      </button>
    </div>
  </mat-dialog-actions>
</form>
