import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'qaroni-status-preview',
  templateUrl: './status-preview.component.html',
  styleUrl: './status-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusPreviewComponent {
  @Input({ required: true }) status = '';
  @Input() bgColorClass = 'bg-gray-300';
}
