import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AnimationsConfig } from '@qaroni-core/config/app/animations.config';

@Component({
  selector: 'qaroni-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  @Input()
  get height(): number {
    return this._height;
  }
  set height(value: NumberInput) {
    this._height = coerceNumberProperty(value, 0);
  }
  private _height: number = 400;

  public src: string;

  constructor() {
    this.src = AnimationsConfig.bouncingBallLoader;
  }
}
