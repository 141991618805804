import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { UnNameAvatarPreviewEnum } from '@qaroni-app/tasks/types/unname-avatar-preview.enum';
import {
  AVATAR_COLOR_LIST,
  AvatarColor,
} from '@qaroni-core/types/colors/colors.enum';

@Component({
  selector: 'qaroni-board-section-avatar-preview',
  templateUrl: './board-section-avatar-preview.component.html',
  styleUrls: ['./board-section-avatar-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardSectionAvatarPreviewComponent implements OnChanges {
  @Input() displayName: string | UnNameAvatarPreviewEnum;
  @Input() bgColor: string = '';
  public colors = AVATAR_COLOR_LIST;
  public bgColorClass = 'qaroni-bg-primary50';
  public colorClass = 'qaroni-text-background';

  public validChars = 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ1234567890 ';

  ngOnChanges(): void {
    this.processData();
  }

  processData(): void {
    this.getColorAndBgColorClass();
  }

  get hasNameDisplay() {
    if (
      this.displayName === UnNameAvatarPreviewEnum.UNASSIGNED ||
      this.displayName === UnNameAvatarPreviewEnum.UNSUPERVISED
    ) {
      return false;
    }
    return true;
  }

  get getInitials(): string {
    if (!this.displayName) {
      return '';
    }

    const re = new RegExp(`[^${this.validChars}]`);
    const name = this.displayName.replace(re, '');
    const words = name.split(' ').splice(0, 2);
    return words
      .map(word => word[0])
      .join('')
      .toUpperCase();
  }

  getColorAndBgColorClass(): void {
    if (this.colors?.length > 0) {
      if (this.bgColor) {
        const colorElement = this.colors.find(e => e.key === this.bgColor);
        this.getColorsFromColorElement(colorElement);
      } else {
        let top = this.colors.length - 1;
        let rand = Math.random() * top;
        rand = Math.floor(rand);
        const colorElement = this.colors[rand];
        this.getColorsFromColorElement(colorElement);
      }
    }
  }

  getColorsFromColorElement(colorElement: AvatarColor): void {
    if (colorElement?.backgroundColor && colorElement.color) {
      this.bgColorClass = colorElement?.backgroundColor;
      this.colorClass = colorElement.color;
    }
  }
}
