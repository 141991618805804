import { Chart, Plugin } from 'chart.js';

export class QaroniCustomCanvasColorPlugin implements Plugin<'bar'> {
  id: 'qaroniCustomCanvasBackgroundColor';
  private backgroundColor = 'rgba(211, 226, 241, 0.35)';

  constructor(customBackgroundColor?: string) {
    if (customBackgroundColor) {
      this.backgroundColor = customBackgroundColor;
    }
  }

  beforeDraw(chart: Chart<'bar'>, args, options) {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = this.backgroundColor;
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  }
}
