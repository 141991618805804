<ng-container *ngIf="hasData; else noData">
  <div class="relative flex justify-center">
    <canvas class="w-full" #pieChart></canvas>
  </div>
</ng-container>

<ng-template #noData>
  <div class="my-2 py-2 text-center">
    <div class="icon-info-wrapper">
      <mat-icon>info</mat-icon>
    </div>
    <span class="text-sm uppercase" i18n
      >No hay suficientes datos para graficar</span
    >
  </div>
</ng-template>
