import { animate, animation, style } from '@angular/animations';

export const qaroniToggleHeightEnterConfig = animation([
  style({
    height: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    borderTop: '0px',
    borderBottom: '0px',
    marginTop: '0px',
    marginBottom: '0px',
    overflow: 'hidden',
  }),
  animate(
    '{{time}} cubic-bezier(0.4, 0.0, 0.2, 1)',
    style({
      height: '*',
      paddingTop: '*',
      paddingBottom: '*',
      borderTop: '*',
      borderBottom: '*',
      marginTop: '*',
      marginBottom: '*',
    })
  ),
]);

export const qaroniToggleHeightLeaveConfig = animation([
  style({
    height: '*',
    paddingTop: '*',
    paddingBottom: '*',
    borderTop: '*',
    borderBottom: '*',
    marginTop: '*',
    marginBottom: '*',
    overflow: 'hidden',
  }),
  animate(
    '{{time}} cubic-bezier(0.4, 0.0, 0.2, 1)',
    style({
      height: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
      borderTop: '0px',
      borderBottom: '0px',
      marginTop: '0px',
      marginBottom: '0px',
    })
  ),
]);
