import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { DayCheck } from '@qaroni-app/check/types/month-check';
import { DaySchedule } from '@qaroni-app/check/types/month-schedule';
import { Day } from '@qaroni-app/check/types/period';
import { Chart, TooltipItem, registerables } from 'chart.js';

const showZeroPlugin = {
  id: 'quadrants',
  afterDatasetsDraw: function (chart: Chart, easing) {
    // get data meta, we need the location info in _view property.
    const meta = chart.getDatasetMeta(0);
    // also you need get datasets to find which item is 0.
    const dataSet = chart.config.data.datasets[0].data;

    meta.data.forEach((d, index) => {
      // for the item which value is 0, reander a line.
      if (dataSet[index] === 0) {
        // get postion info from _view
        const view = d;

        const context = chart.ctx;
        // the view.x is the centeral point of the bar, so we need minus half width of the bar.
        const startX = view.x - d['width'] / 2;
        // common canvas API, Check it out on MDN
        context.beginPath();
        // set line color, you can do more custom settings here.
        context.strokeStyle = '#000008a';
        context.moveTo(startX, view.y);
        // draw the line!
        context.lineTo(startX + d['width'], view.y);
        // bam！ you will see the lines.
        context.stroke();
      }
    });
  },
};

@Component({
  selector: 'qaroni-hours-balance-chart-preview',
  templateUrl: './hours-balance-chart-preview.component.html',
  styleUrls: ['./hours-balance-chart-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoursBalanceChartPreviewComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  // TODO: Remove Day interface when change endpoints on checks from admin
  @Input() dayChecks: DayCheck[] | Day[];
  @Input() daySchedules: DaySchedule[];
  @Input() monthName: string;

  @ViewChild('hoursBalanceChart')
  hoursBalanceChart: ElementRef<HTMLCanvasElement>;

  private chart: Chart;
  private canvas: HTMLCanvasElement;
  private labels: string[];
  private hours: number[];
  private schedules: number[];

  constructor(private zone: NgZone) {
    Chart.register(...registerables);
  }

  ngOnChanges(): void {
    this.zone.runOutsideAngular(() => {
      this.initialize();
      if (this.chart) this.setUpChart();
    });
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.canvas = this.hoursBalanceChart.nativeElement;
      this.setUpChart();
    });
  }

  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      this.chart.destroy();
    });
  }

  private initialize = (): void => {
    this.labels = this.dayChecks?.map(
      day => `${day.dayNumber} ${this.monthName}`
    );
    this.hours = this.dayChecks?.map(day =>
      parseFloat((day.totalMinutes / 60).toFixed(2))
    );
    this.schedules = this.daySchedules?.map(schedule =>
      parseFloat((schedule.scheduleTime / 60).toFixed(2))
    );
  };

  private setUpChart = (): void => {
    if (this.chart) this.chart.destroy();

    this.chart = new Chart(this.canvas, {
      plugins: [],
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: [
          {
            label: $localize`Horas trabajadas`,
            data: this.hours,
            borderColor: '#000000',
            backgroundColor: '#8dec85',
          },
          {
            label: $localize`Horas esperadas`,
            data: this.schedules,
            borderColor: '#000000',
            backgroundColor: '#82c4f8',
          },
        ],
      },
      options: {
        responsive: true,
        layout: {
          // padding: {
          //   left: 15,
          //   right: 15,
          // },
        },
        plugins: {
          legend: {
            position: 'top',
            display: false,
          },
          // tooltip: {
          //   titleAlign: 'center',
          //   titleFont: {
          //     size: 11,
          //   },
          //   bodyFont: {
          //     size: 11,
          //   },
          //   footerFont: {
          //     size: 11,
          //   },
          //   callbacks: {
          //     footer: (tooltipItems) => {
          //       let diff: number = 0;
          //       tooltipItems.forEach((tooltipItem) => {
          //         diff =
          //           tooltipItem.parsed._stacks.y[0] -
          //           tooltipItem.parsed._stacks.y[1];
          //       });
          //       const diffText: string =
          //         diff > 0 ? `+${diff.toFixed(2)}` : `${diff.toFixed(2)}`;
          //       return (
          //         $localize`Diferencia` +
          //         `: ${diffText.replace('.', ',')} ` +
          //         $localize`horas`
          //       );
          //     },
          //   },
          // },
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            title: {
              display: true,
              text: $localize`Días del mes de ${this.monthName}`,
              font: {
                weight: 'bold',
              },
            },
            stacked: false,
            display: false,
          },
          y: {
            title: {
              display: true,
              text: $localize`Horas`,
              font: {
                weight: 'bold',
              },
            },
            stacked: false,
            display: false,
          },
        },
      },
    });
  };

  private getDiffTooltip = (tooltipItems: TooltipItem<'bar'>[]): string => {
    let diff: number = 0;
    tooltipItems.forEach(tooltipItem => {
      diff = tooltipItem.parsed._stacks.y[0] - tooltipItem.parsed._stacks.y[1];
    });
    const diffText: string =
      diff > 0 ? `+${diff.toFixed(2)}` : `${diff.toFixed(2)}`;
    return (
      $localize`Diferencia` +
      `: ${diffText.replace('.', ',')} ` +
      $localize`horas`
    );
  };
}
