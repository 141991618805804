import { Pipe, PipeTransform } from '@angular/core';
import {
  PaymentMethodEnum,
  PaymentMethodInfo,
} from '@qaroni-core/types/payments/payment-method.enum';

@Pipe({
  name: 'paymentMethodInfo',
})
export class PaymentMethodInfoPipe implements PipeTransform {
  transform(value: PaymentMethodEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(PaymentMethodInfo, value)
    ) {
      return PaymentMethodInfo[value].name;
    }

    return value;
  }
}
