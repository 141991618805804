import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum CheckEnum {
  DESKTOP = 'DESKTOP',
  IN_PERSON = 'IN_PERSON',
  REMOTE = 'REMOTE',
  WEB = 'WEB',
}

export type CheckType =
  | CheckEnum.DESKTOP
  | CheckEnum.IN_PERSON
  | CheckEnum.REMOTE
  | CheckEnum.WEB;

export const CheckArray = [
  CheckEnum.DESKTOP,
  CheckEnum.IN_PERSON,
  CheckEnum.REMOTE,
  CheckEnum.WEB,
];

export const CheckInfo: QaroniEnumInformation<CheckEnum> = {
  [CheckEnum.DESKTOP]: {
    name: $localize`Desktop`,
  },

  [CheckEnum.IN_PERSON]: {
    name: $localize`In person`,
  },
  [CheckEnum.REMOTE]: {
    name: $localize`Remote`,
  },

  [CheckEnum.WEB]: {
    name: $localize`Web`,
  },
};
