import { QaroniCache } from '@qaroni-core/types/qaroni-cache/qaroni-cache';
import { UserRole } from './user-role';

export class UserRoleCache extends QaroniCache<UserRole> {
  public canReturn(companyID: number, userID: number): boolean {
    return this.get() &&
      Number.isInteger(companyID) &&
      Number.isInteger(userID) &&
      this.get().companyId === companyID &&
      this.get().userId === userID
      ? true
      : false;
  }

  public canApi(companyID: number, userID: number): boolean {
    return !this.canReturn(companyID, userID) && !this.flying ? true : false;
  }
}
