import { Pipe, PipeTransform } from '@angular/core';
import { City } from '../types/city';

@Pipe({
  name: 'cityFilter',
})
export class CityFilterPipe implements PipeTransform {
  transform(cities: City[], cityName: string): City[] {
    if (!cityName) return cities;

    if (Number.isInteger(cityName)) {
      return cities?.filter(c =>
        c.cityId.toString().includes(cityName.toString())
      );
    }

    return cities?.filter(c =>
      c.name.toLowerCase().includes(cityName.toLowerCase())
    );
  }
}
