import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum UserRoleEnum {
  ADMIN = 'ADMIN',
  BASIC = 'BASIC',
  DEVELOPER = 'DEVELOPER',
  HR = 'HR',
  MANAGER = 'MANAGER',
  NONE = 'NONE',
  OWNER = 'OWNER',
}

export type UserRoleType =
  | UserRoleEnum.ADMIN
  | UserRoleEnum.BASIC
  | UserRoleEnum.DEVELOPER
  | UserRoleEnum.HR
  | UserRoleEnum.MANAGER
  | UserRoleEnum.NONE
  | UserRoleEnum.OWNER;

export const UserRoleArray = [
  UserRoleEnum.ADMIN,
  UserRoleEnum.BASIC,
  UserRoleEnum.DEVELOPER,
  UserRoleEnum.HR,
  UserRoleEnum.MANAGER,
  UserRoleEnum.NONE,
  UserRoleEnum.OWNER,
];

export const UserRoleInfo: QaroniEnumInformation<UserRoleEnum> = {
  [UserRoleEnum.ADMIN]: {
    name: $localize`Administrador`,
  },
  [UserRoleEnum.BASIC]: {
    name: $localize`Empleado`,
  },

  [UserRoleEnum.DEVELOPER]: {
    name: $localize`Desarrollador`,
  },
  [UserRoleEnum.HR]: {
    name: $localize`Recursos Humanos`,
  },
  [UserRoleEnum.MANAGER]: {
    name: $localize`Mánager`,
  },
  [UserRoleEnum.NONE]: {
    name: $localize`Ninguno`,
  },
  [UserRoleEnum.OWNER]: {
    name: $localize`Propietario`,
  },
};

export enum UserRoleHintEnum {
  ADMIN = 'ADMIN',
  HR = 'HR',
}

export const UserRoleHintInfo: QaroniEnumInformation<UserRoleHintEnum> = {
  [UserRoleEnum.ADMIN]: {
    name: $localize`Administrador de la Empresa`,
  },
  [UserRoleEnum.HR]: {
    name: $localize`Gestión de Recursos Humanos`,
  },
};

export const InvitationRoleSelectorArray = [
  UserRoleEnum.ADMIN,
  UserRoleEnum.HR,
];

export const FilterUsersSelectorArray = [
  UserRoleEnum.OWNER,
  UserRoleEnum.ADMIN,
  UserRoleEnum.HR,
];
