<div class="content-header" [ngClass]="{ invisible: !year }">
  <button
    mat-icon-button
    i18n-matTooltip
    matTooltip="Año anterior"
    (click)="subtractYear()"
    [disabled]="isLoading$ | async">
    <mat-icon>navigate_before</mat-icon>
  </button>
  <div class="flex flex-col">
    <button
      mat-button
      i18n-matTooltip
      matTooltip="¿Cambiar año?"
      (click)="dp.open()">
      {{ title }} <ng-container i18n>de</ng-container> {{ year }}
      <br />
      <ng-container i18n>Desde enero hasta diciembre</ng-container>
    </button>
    <input
      matInput
      [matDatepicker]="dp"
      [formControl]="yearControl"
      [min]="minDate"
      [max]="maxDate"
      class="invisible"
      style="width: 0; height: 0" />
    <mat-datepicker
      #dp
      startView="multi-year"
      (yearSelected)="setYear($event, dp)"
      panelClass="year-picker">
    </mat-datepicker>
  </div>
  <button
    mat-icon-button
    i18n-matTooltip
    matTooltip="Año siguiente"
    (click)="addYear()"
    [disabled]="isLoading$ | async">
    <mat-icon>navigate_next</mat-icon>
  </button>
</div>
