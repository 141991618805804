<form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
  <button
    mat-button
    type="button"
    class="qaroni-close-icon"
    [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
  <div class="mat-mdc-dialog-title" i18n>Recuperar contraseña</div>
  <mat-dialog-content class="text-center">
    <h3 class="mb-4 md:px-5">
      ¿<ng-container i18n
        >Deseas enviar un correo electrónico a la dirección</ng-container
      >
      <b class="mx-1">{{ data }}</b>
      <ng-container i18n>para restablecer la contraseña</ng-container>?
    </h3>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <div class="flex flex-col items-center justify-center gap-3 md:flex-row">
      <button
        mat-flat-button
        color="blue"
        class="qaroni-rounded-lg-button"
        type="submit"
        [disabled]="!validatedForm">
        <mat-icon class="me-1">email</mat-icon>
        <ng-container i18n>Enviar</ng-container>
      </button>

      <button
        mat-flat-button
        color="light-blue"
        class="qaroni-rounded-lg-button"
        type="button"
        [mat-dialog-close]="false"
        i18n>
        Cancelar
      </button>
    </div>
  </mat-dialog-actions>
</form>
