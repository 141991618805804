import { Pipe, PipeTransform } from '@angular/core';
import {
  AbsenceEmployeeStatusEnum,
  AbsenceEmployeeStatusInfo,
} from '../../../absences/types/absence-employee-status.enum';

@Pipe({
  name: 'absenceEmployeeStatus',
})
export class AbsenceEmployeeStatusPipe implements PipeTransform {
  transform(value: AbsenceEmployeeStatusEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(AbsenceEmployeeStatusInfo, value)
    ) {
      return AbsenceEmployeeStatusInfo[value].name;
    }

    return value;
  }
}
