import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaymentsService } from '@qaroni-app/payments/services/payments.service';
import { Plan } from '@qaroni-app/payments/types/plan';
import { Price } from '@qaroni-app/payments/types/price';
import { PriceConstantsEnum } from '@qaroni-app/payments/types/price-constants.enum';
import { ConfirmationDialogData } from '@qaroni-core/types/dialogs/confirmation-dialog-data';
import { QaroniDialogConfig } from '@qaroni-core/types/dialogs/qaroni-dialog-config';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: '[qaroni-payments-level]',
  templateUrl: './payments-level.component.html',
  styleUrls: ['./payments-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentsLevelComponent {
  @Input()
  get isMonthly(): boolean {
    return this._isMonthly;
  }
  set isMonthly(value: BooleanInput) {
    this._isMonthly = coerceBooleanProperty(value);
  }
  private _isMonthly: boolean = true;

  @Input() plan: Plan;

  private subs: Subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private paymentsService: PaymentsService
  ) {}

  get hasPrice(): boolean {
    return this.plan?.prices?.length ? true : false;
  }

  get monthPlan(): Price {
    if (!this.hasPrice) {
      return null;
    }

    const prices: Price[] = this.plan?.prices?.filter(
      price => price.constants === PriceConstantsEnum.MONTH
    );
    return prices[0];
  }

  get yearPlan(): Price {
    if (!this.hasPrice) {
      return null;
    }

    const prices: Price[] = this.plan?.prices?.filter(
      price => price.constants === PriceConstantsEnum.YEAR
    );
    return prices[0];
  }

  public onWeWillContactYou(): void {
    const confirmationDialogData: ConfirmationDialogData = {
      title: $localize`Te vamos a contactar`,
      message: $localize`En los siguientes días laborales una persona del equipo de Workards se pondrá en contacto contigo para conversar sobre los niveles de precios que tenemos para ofrecerte`,
      confirmText: $localize`Continuar`,
    };

    const dialogConfig = new QaroniDialogConfig<ConfirmationDialogData>();
    dialogConfig.data = confirmationDialogData;

    const dialog = this.dialog.open<
      ConfirmationDialogComponent,
      ConfirmationDialogData,
      boolean
    >(ConfirmationDialogComponent, dialogConfig);

    this.subs.add(
      dialog.afterClosed().subscribe(this.getConfirmationWeWillContactYou)
    );
  }

  private getConfirmationWeWillContactYou = (data: boolean): void => {
    if (data) {
      this.paymentsService.weWillContactYou();
    }
  };
}
