import { Pipe, PipeTransform } from '@angular/core';
import {
  ShiftStatusEnum,
  ShiftStatusInfo,
} from '@qaroni-app/employee/types/shift-status.enum';

@Pipe({
  name: 'shiftStatus',
})
export class ShiftStatusPipe implements PipeTransform {
  transform(value: ShiftStatusEnum): string {
    if (value && Object.prototype.hasOwnProperty.call(ShiftStatusInfo, value)) {
      return ShiftStatusInfo[value].name;
    }

    return value;
  }
}
