import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ProgressBarService } from '@qaroni-core/services/app/progress-bar/progress-bar.service';
import { addYears, format, isValid, parseISO, subYears } from 'date-fns';
import { Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'qaroni-year-datepicker',
  templateUrl: './year-datepicker.component.html',
  styleUrls: ['./year-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearDatepickerComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() year: string;

  @Output() dateYear: EventEmitter<Date> = new EventEmitter<Date>();

  public isLoading$: Observable<boolean> = this.progressBar.getProgressBar$();
  public yearControl: UntypedFormControl = new UntypedFormControl();
  public currentDate: Date;
  public minDate = subYears(new Date(), 100);
  public maxDate = new Date();

  private queryParamMap$: Observable<ParamMap> = this.route.queryParamMap.pipe(
    shareReplay(1)
  );
  private subs: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private progressBar: ProgressBarService
  ) {}

  ngOnInit(): void {
    this.subs.add(this.queryParamMap$.subscribe(this.getQueryParamMap));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setYear(normalizedYear: Date, datepicker: MatDatepicker<Date>) {
    this.yearControl.setValue(normalizedYear);
    this.dateYear.emit(this.yearControl.value);
    datepicker.close();
  }

  addYear() {
    this.yearControl.setValue(addYears(parseISO(this.yearControl.value), 1));
    this.dateYear.emit(this.yearControl.value);
  }

  subtractYear() {
    this.yearControl.setValue(addYears(parseISO(this.yearControl.value), -1));
    this.dateYear.emit(this.yearControl.value);
  }

  private getQueryParamMap = (queryParamMap: ParamMap): void => {
    if (queryParamMap.has('year')) {
      const date: Date = parseISO(queryParamMap.get('year'));
      this.currentDate = isValid(date) ? date : new Date();
      this.yearControl.setValue(format(this.currentDate, 'yyyy-MM-dd'));
    } else {
      this.currentDate = new Date();
      this.yearControl.setValue(format(this.currentDate, 'yyyy-MM-dd'));
    }
  };
}
