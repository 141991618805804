import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { InputValidation } from '@qaroni-core/utils/validations/input-validation';

export class CheckCommentDialogFormClass {
  public InputValidation = InputValidation;

  private fb: UntypedFormBuilder = new UntypedFormBuilder();

  private checkCommentSkeleton = {
    index: [],
    checkId: ['', Validators.required],
    comment: [''],
  };

  public checkCommentForm: UntypedFormGroup = this.fb.group(
    this.checkCommentSkeleton
  );

  get index(): AbstractControl {
    return this.checkCommentForm.get('index');
  }

  get checkId(): AbstractControl {
    return this.checkCommentForm.get('checkId');
  }

  get comment(): AbstractControl {
    return this.checkCommentForm.get('comment');
  }

  get dirtyValidForm(): boolean {
    return this.checkCommentForm.dirty && this.checkCommentForm.valid;
  }
}
