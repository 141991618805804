import { QaroniCache } from '@qaroni-core/types/qaroni-cache/qaroni-cache';
import { User } from './user';

export class UserCache extends QaroniCache<User> {
  public canReturn(userID: number): boolean {
    return this.get() &&
      Number.isInteger(userID) &&
      this.get().userId === userID
      ? true
      : false;
  }

  public canApi(userID: number): boolean {
    return !this.canReturn(userID) && !this.flying ? true : false;
  }
}
