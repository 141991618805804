import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SendInvitationDialogForm } from './send-invitation-dialog-form';

@Component({
  selector: 'qaroni-send-invitation-dialog',
  templateUrl: './send-invitation-dialog.component.html',
  styleUrls: ['./send-invitation-dialog.component.scss'],
})
export class SendInvitationDialogComponent extends SendInvitationDialogForm {
  public submitting: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    public dialogRef: MatDialogRef<SendInvitationDialogComponent>
  ) {
    super();
  }

  private enableLoading(): void {
    this.submitting = true;
  }

  getTitle() {
    return this.data;
  }

  public onCancelClick(): void {
    this.dialogRef.close(null);
  }

  get validatedForm(): boolean {
    return this.dirtyValidForm && !this.submitting;
  }

  public onSubmit(): void {
    if (this.validatedForm) {
      this.enableLoading();
      this.dialogRef.close({
        email: this.email.value,
        role: this.role.value,
      });
    }
  }
}
