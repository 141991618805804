import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum AbsenceEmployeeStatusEnum {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export type AbsenceEmployeeStatusType =
  | AbsenceEmployeeStatusEnum.PENDING
  | AbsenceEmployeeStatusEnum.REJECTED
  | AbsenceEmployeeStatusEnum.APPROVED;

export const AbsenceEmployeeStatusArray = [
  AbsenceEmployeeStatusEnum.PENDING,
  AbsenceEmployeeStatusEnum.REJECTED,
  AbsenceEmployeeStatusEnum.APPROVED,
];

export const AbsenceEmployeeStatusInfo: QaroniEnumInformation<AbsenceEmployeeStatusEnum> =
  {
    [AbsenceEmployeeStatusEnum.PENDING]: {
      name: $localize`Pendiente`,
    },

    [AbsenceEmployeeStatusEnum.REJECTED]: {
      name: $localize`Rechazado`,
    },

    [AbsenceEmployeeStatusEnum.APPROVED]: {
      name: $localize`Aprobado`,
    },
  };
