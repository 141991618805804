import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'qaroni-label-project-name-preview',
  templateUrl: './label-project-name-preview.component.html',
  styleUrls: ['./label-project-name-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelProjectNamePreviewComponent {
  @Input()
  get showLineColor(): boolean {
    return this._showLineColor;
  }
  set showLineColor(value: BooleanInput) {
    this._showLineColor = coerceBooleanProperty(value);
  }
  private _showLineColor: boolean = false;

  @Input() color: string;
  @Input() icon: string;
  @Input() name: string;
}
