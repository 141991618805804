import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum EmployeeDisabilityDegreeEnum {
  FIRST_CLASS = 'FIRST_CLASS',
  SECOND_CLASS = 'SECOND_CLASS',
  THIRD_CLASS = 'THIRD_CLASS',
  FOURTH_CLASS = 'FOURTH_CLASS',
  FIFTH_CLASS = 'FIFTH_CLASS',
}

export type EmployeeDisabilityDegreeType =
  | EmployeeDisabilityDegreeEnum.FIRST_CLASS
  | EmployeeDisabilityDegreeEnum.SECOND_CLASS
  | EmployeeDisabilityDegreeEnum.THIRD_CLASS
  | EmployeeDisabilityDegreeEnum.FOURTH_CLASS
  | EmployeeDisabilityDegreeEnum.FIFTH_CLASS;

export const EmployeeDisabilityDegreeArray = [
  EmployeeDisabilityDegreeEnum.FIRST_CLASS,
  EmployeeDisabilityDegreeEnum.SECOND_CLASS,
  EmployeeDisabilityDegreeEnum.THIRD_CLASS,
  EmployeeDisabilityDegreeEnum.FOURTH_CLASS,
  EmployeeDisabilityDegreeEnum.FIFTH_CLASS,
];

export const EmployeeDisabilityDegreeInfo: QaroniEnumInformation<EmployeeDisabilityDegreeEnum> =
  {
    [EmployeeDisabilityDegreeEnum.FIRST_CLASS]: {
      name: $localize`Discapacidad 0%`,
    },
    [EmployeeDisabilityDegreeEnum.SECOND_CLASS]: {
      name: $localize`Discapacidad del 1 al 24%`,
    },
    [EmployeeDisabilityDegreeEnum.THIRD_CLASS]: {
      name: $localize`Discapacidad del 25 al 49%`,
    },
    [EmployeeDisabilityDegreeEnum.FOURTH_CLASS]: {
      name: $localize`Discapacidad del 50 al 70%`,
    },
    [EmployeeDisabilityDegreeEnum.FIFTH_CLASS]: {
      name: $localize`Discapacidad igual o mayor al 75%`,
    },
  };
