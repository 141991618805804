import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum EmployeeEducationEnum {
  SCHOOL = 'SCHOOL',
  HIGH = 'HIGH',
  TECHNICAL = 'TECHNICAL',
  DEGREE = 'DEGREE',
  MASTER = 'MASTER',
  DOCTORATE = 'DOCTORATE',
}

export type EmployeeEducationType =
  | EmployeeEducationEnum.SCHOOL
  | EmployeeEducationEnum.HIGH
  | EmployeeEducationEnum.TECHNICAL
  | EmployeeEducationEnum.DEGREE
  | EmployeeEducationEnum.MASTER
  | EmployeeEducationEnum.DOCTORATE;

export const EmployeeEducationArray = [
  EmployeeEducationEnum.SCHOOL,
  EmployeeEducationEnum.HIGH,
  EmployeeEducationEnum.TECHNICAL,
  EmployeeEducationEnum.DEGREE,
  EmployeeEducationEnum.MASTER,
  EmployeeEducationEnum.DOCTORATE,
];

export const EmployeeEducationInfo: QaroniEnumInformation<EmployeeEducationEnum> =
  {
    [EmployeeEducationEnum.SCHOOL]: {
      name: $localize`Primaria`,
    },
    [EmployeeEducationEnum.HIGH]: {
      name: $localize`Secundaria`,
    },
    [EmployeeEducationEnum.TECHNICAL]: {
      name: $localize`Técnico`,
    },
    [EmployeeEducationEnum.DEGREE]: {
      name: $localize`Universidad`,
    },
    [EmployeeEducationEnum.MASTER]: {
      name: $localize`Maestría`,
    },
    [EmployeeEducationEnum.DOCTORATE]: {
      name: $localize`Doctorado`,
    },
  };
